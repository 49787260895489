export class OperadorModel {
	email: string;
	nombre: string;
	rol: string;
	proveedor: string;
	uid: any;
	eliminado: boolean;
	verificado: boolean;
	estado: string;

	constructor(){
	}
}
