import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { SucursalModel } from '../../../models/sucursal-model';

import { ProveedorModel } from '../../../models/proveedor-model';
import { OperadorModel } from '../../../models/operador-model';
import { GrueroModel } from '../../../models/gruero-model';

import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';


@Component({
	selector: 'app-sucursales-operador',
	templateUrl: './sucursales-operador.component.html',
	styleUrls: ['./sucursales-operador.component.scss']
})
export class SucursalesOperadorComponent implements OnInit {

	private operadorKey;

	private columnas;
	private sucursales: Array<SucursalModel> = new Array<SucursalModel>();
	private sucursales_keys: Map<string, string> = new Map<string, string>();

	private columnasLibres;
	private sucursalesLibres: Array<SucursalModel> = new Array<SucursalModel>();
	private sucursalesLibres_keys: Map<string, string> = new Map<string, string>();

	public subscripcionSucursales: any;
	public prt_suscriber: any;
	public lavado_suscriber: any;
	public taller_suscriber: any;
	public grua_suscriber: any;

	public user: any = null;

	public errores:  Message[] = [];

	constructor(private servicios: FirebaseService,
		private router: Router,
		private confirmationService: ConfirmationService,
		private messageService: MessageService) {
		this.columnas = [
		{ field: 'nombre', header: 'Nombre' },
		{ field: 'region', header: 'Región' },
		{ field: 'comuna', header: 'Comuna' },
		{ field: 'direccion', header: 'Dirección' },
		{ field: 'servicio', header: 'Servicio' },
		{ field: 'delete', header: 'Quitar' }
		];
		this.columnasLibres = [
		{ field: 'nombre', header: 'Nombre' },
		{ field: 'region', header: 'Región' },
		{ field: 'comuna', header: 'Comuna' },
		{ field: 'direccion', header: 'Dirección' },
		{ field: 'servicio', header: 'Servicio' },
		{ field: 'select', header: 'Seleccionar' }
		];
	}

	ngOnInit() {
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				}
			});
		}
		this.sucursales = [];
		this.operadorKey = sessionStorage.getItem("operador_key");
		if (this.operadorKey == undefined) {
			this.router.navigate(['']);
		} else {
			var suscriber = this.servicios.getUsuario(this.operadorKey).subscribe( result => {
				var operadorNodo = result.payload.val();
				if (operadorNodo['rol'] == RolesUser.USER_OPERATOR) {
					var operador: OperadorModel = new OperadorModel();
					operador.email = operadorNodo['email'];
					operador.nombre = operadorNodo['nombre'];
					operador.rol = operadorNodo['rol'];
					operador.nombre = operadorNodo['nombre'];
					operador.eliminado = operadorNodo['eliminado'];
					operador.uid = result.key;
					this.user = operador;
				} else {
					var gruero: GrueroModel = new GrueroModel();
					gruero.activo = operadorNodo['activo'];
					gruero.calificacion = operadorNodo['calificacion'];
					gruero.eliminado = operadorNodo['eliminado'];
					gruero.email = operadorNodo['email'];
					gruero.latitud = operadorNodo['latitud'];
					gruero.longitud = operadorNodo['longitud'];
					gruero.nombre = operadorNodo['nombre'];
					gruero.proveedor = operadorNodo['proveedor'];
					gruero.sucursal = operadorNodo['sucursal'];
					gruero.telefono = operadorNodo['telefono'];
					gruero.uid = result.key;
					this.user = gruero;
				}
				console.log(this.user);
				suscriber.unsubscribe();
			}, error => {
				console.log(error);
			});
			this.getSucursales(this.operadorKey);
			this.getSucursalesLibres();
			// this.subscripcionSucursales = this.servicios.getProveedor(this.).subscribe(proveedorNodo => {
			// 	var proveedor = proveedorNodo.payload.val();
			// 	this.proveedorModel = new ProveedorModel();
			// 	this.proveedorModel.razonSocial = proveedor['razonSocial'];
			// 	this.proveedorModel.email = proveedor['email'];
			// 	this.proveedorModel.nombre = proveedor['nombre'];
			// 	this.proveedorModel.rut = proveedor['rut'];
			// 	this.proveedorModel.telefono = proveedor['telefono'];
			// 	this.proveedorModel.key = proveedorNodo.key;
			// 	this.getSucursales(this.operadorKey);
			// 	this.getSucursalesLibres(this.proveedorModel.key);
			// });
		}
	}

	ngOnDestroy(){
		if (this.subscripcionSucursales != undefined) {
			this.subscripcionSucursales.unsubscribe();
		}
		if (this.prt_suscriber != undefined) {
			this.prt_suscriber.unsubscribe();
		}
		if (this.lavado_suscriber != undefined) {
			this.lavado_suscriber.unsubscribe();
		}
		if (this.lavado_suscriber != undefined) {
			this.lavado_suscriber.unsubscribe();
		}
		if (this.grua_suscriber != undefined) {
			this.grua_suscriber.unsubscribe();
		}
	}

	async getSucursales(operadorKey) {
		this.prt_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "PRT").subscribe(prts => {
			prts.forEach(PRT_Nodo => {
				var suscriber = this.servicios.getSucursal(PRT_Nodo.key, "PRT").subscribe(PRT => {
					if (PRT != undefined) {
						if ((this.sucursales_keys.get(PRT_Nodo.key) == null || this.sucursales_keys.get(PRT_Nodo.key) == undefined) && (this.sucursalesLibres_keys.get(PRT_Nodo.key) == null || this.sucursalesLibres_keys.get(PRT_Nodo.key) == undefined)) {
							var sucursalPRT = PRT.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = PRT.key;
							sucursal.codigoPais = sucursalPRT['codigoPais'];
							sucursal.comision = sucursalPRT['comision'];
							sucursal.comuna = sucursalPRT['comuna'];
							sucursal.direccion = sucursalPRT['direccion'];
							sucursal.estado = sucursalPRT['estado'];
							sucursal.nombre = sucursalPRT['nombre'];
							sucursal.region = sucursalPRT['region'];
							sucursal.rut = sucursalPRT['rut'];
							sucursal.telefono = sucursalPRT['telefono'];
							sucursal.servicio = "PRT";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.lavado_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "Lavado").subscribe(lavados => {
			lavados.forEach(lavadoNodo => {
				var suscriber = this.servicios.getSucursal(lavadoNodo.key, "Lavado").subscribe(lavado => {
					if (lavado != undefined) {
						if ((this.sucursales_keys.get(lavadoNodo.key) == null || this.sucursales_keys.get(lavadoNodo.key) == undefined) && (this.sucursalesLibres_keys.get(lavadoNodo.key) == null || this.sucursalesLibres_keys.get(lavadoNodo.key) == undefined)) {
							var sucursalLavado = lavado.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = lavado.key;
							sucursal.comision = sucursalLavado['comision'];
							sucursal.codigoPais = sucursalLavado['codigoPais'];
							sucursal.comuna = sucursalLavado['comuna'];
							sucursal.direccion = sucursalLavado['direccion'];
							sucursal.estado = sucursalLavado['estado'];
							sucursal.nombre = sucursalLavado['nombre'];
							sucursal.region = sucursalLavado['region'];
							sucursal.rut = sucursalLavado['rut'];
							sucursal.telefono = sucursalLavado['telefono'];
							sucursal.servicio = "Lavado";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.taller_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "Taller").subscribe(talleres => {
			talleres.forEach(tallerNodo => {
				var suscriber = this.servicios.getSucursal(tallerNodo.key, "Taller").subscribe(taller => {
					if (taller != undefined) {
						if ((this.sucursales_keys.get(tallerNodo.key) == null || this.sucursales_keys.get(tallerNodo.key) == undefined) && (this.sucursalesLibres_keys.get(tallerNodo.key) == null || this.sucursalesLibres_keys.get(tallerNodo.key) == undefined)) {
							var sucursalTaller = taller.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = taller.key;
							sucursal.comision = sucursalTaller['comision'];
							sucursal.codigoPais = sucursalTaller['codigoPais'];
							sucursal.comuna = sucursalTaller['comuna'];
							sucursal.direccion = sucursalTaller['direccion'];
							sucursal.estado = sucursalTaller['estado'];
							sucursal.nombre = sucursalTaller['nombre'];
							sucursal.region = sucursalTaller['region'];
							sucursal.rut = sucursalTaller['rut'];
							sucursal.telefono = sucursalTaller['telefono'];
							sucursal.servicio = "Taller";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.grua_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "Grua").subscribe(gruas => {
			gruas.forEach(gruaNodo => {
				var suscriber = this.servicios.getSucursal(gruaNodo.key, "Grua").subscribe(grua => {
					if (grua != undefined) {
						if ((this.sucursales_keys.get(gruaNodo.key) == null || this.sucursales_keys.get(gruaNodo.key) == undefined) && (this.sucursalesLibres_keys.get(gruaNodo.key) == null || this.sucursalesLibres_keys.get(gruaNodo.key) == undefined)) {
							var sucursalGrua = grua.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = grua.key;
							sucursal.comision = sucursalGrua['comision'];
							sucursal.codigoPais = sucursalGrua['codigoPais'];
							sucursal.comuna = sucursalGrua['comuna'];
							sucursal.direccion = sucursalGrua['direccion'];
							sucursal.estado = sucursalGrua['estado'];
							sucursal.nombre = sucursalGrua['nombre'];
							sucursal.region = sucursalGrua['region'];
							sucursal.rut = sucursalGrua['rut'];
							sucursal.telefono = sucursalGrua['telefono'];
							sucursal.servicio = "Grua";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
	}

	async getSucursalesLibres() {
		this.prt_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "PRT").subscribe(prts => {
			prts.forEach(PRT_Nodo => {
				var suscriber = this.servicios.getSucursal(PRT_Nodo.key, "PRT").subscribe(PRT => {
					if (PRT != undefined) {
						if ((this.sucursales_keys.get(PRT_Nodo.key) == null || this.sucursales_keys.get(PRT_Nodo.key) == undefined) && (this.sucursalesLibres_keys.get(PRT_Nodo.key) == null || this.sucursalesLibres_keys.get(PRT_Nodo.key) == undefined)) {
							var sucursalPRT = PRT.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = PRT.key;
							sucursal.codigoPais = sucursalPRT['codigoPais'];
							sucursal.comision = sucursalPRT['comision'];
							sucursal.comuna = sucursalPRT['comuna'];
							sucursal.direccion = sucursalPRT['direccion'];
							sucursal.estado = sucursalPRT['estado'];
							sucursal.nombre = sucursalPRT['nombre'];
							sucursal.region = sucursalPRT['region'];
							sucursal.rut = sucursalPRT['rut'];
							sucursal.telefono = sucursalPRT['telefono'];
							sucursal.servicio = "PRT";
							this.sucursalesLibres.push(sucursal);
							this.sucursalesLibres_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.lavado_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "Lavado").subscribe(lavados => {
			lavados.forEach(lavadoNodo => {
				var suscriber = this.servicios.getSucursal(lavadoNodo.key, "Lavado").subscribe(lavado => {
					if (lavado != undefined) {
						if ((this.sucursales_keys.get(lavadoNodo.key) == null || this.sucursales_keys.get(lavadoNodo.key) == undefined) && (this.sucursalesLibres_keys.get(lavadoNodo.key) == null || this.sucursalesLibres_keys.get(lavadoNodo.key) == undefined)) {
							var sucursalLavado = lavado.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = lavado.key;
							sucursal.comision = sucursalLavado['comision'];
							sucursal.codigoPais = sucursalLavado['codigoPais'];
							sucursal.comuna = sucursalLavado['comuna'];
							sucursal.direccion = sucursalLavado['direccion'];
							sucursal.estado = sucursalLavado['estado'];
							sucursal.nombre = sucursalLavado['nombre'];
							sucursal.region = sucursalLavado['region'];
							sucursal.rut = sucursalLavado['rut'];
							sucursal.telefono = sucursalLavado['telefono'];
							sucursal.servicio = "Lavado";
							this.sucursalesLibres.push(sucursal);
							this.sucursalesLibres_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.taller_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "Taller").subscribe(talleres => {
			talleres.forEach(tallerNodo => {
				var suscriber = this.servicios.getSucursal(tallerNodo.key, "Taller").subscribe(taller => {
					if (taller != undefined) {
						if ((this.sucursales_keys.get(tallerNodo.key) == null || this.sucursales_keys.get(tallerNodo.key) == undefined) && (this.sucursalesLibres_keys.get(tallerNodo.key) == null || this.sucursalesLibres_keys.get(tallerNodo.key) == undefined)) {
							var sucursalTaller = taller.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = taller.key;
							sucursal.comision = sucursalTaller['comision'];
							sucursal.codigoPais = sucursalTaller['codigoPais'];
							sucursal.comuna = sucursalTaller['comuna'];
							sucursal.direccion = sucursalTaller['direccion'];
							sucursal.estado = sucursalTaller['estado'];
							sucursal.nombre = sucursalTaller['nombre'];
							sucursal.region = sucursalTaller['region'];
							sucursal.rut = sucursalTaller['rut'];
							sucursal.telefono = sucursalTaller['telefono'];
							sucursal.servicio = "Taller";
							this.sucursalesLibres.push(sucursal);
							this.sucursalesLibres_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.grua_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "Grua").subscribe(gruas => {
			gruas.forEach(gruaNodo => {
				var suscriber = this.servicios.getSucursal(gruaNodo.key, "Grua").subscribe(grua => {
					if (grua != undefined) {
						if ((this.sucursales_keys.get(gruaNodo.key) == null || this.sucursales_keys.get(gruaNodo.key) == undefined) && (this.sucursalesLibres_keys.get(gruaNodo.key) == null || this.sucursalesLibres_keys.get(gruaNodo.key) == undefined)) {
							var sucursalGrua = grua.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = grua.key;
							sucursal.comision = sucursalGrua['comision'];
							sucursal.codigoPais = sucursalGrua['codigoPais'];
							sucursal.comuna = sucursalGrua['comuna'];
							sucursal.direccion = sucursalGrua['direccion'];
							sucursal.estado = sucursalGrua['estado'];
							sucursal.nombre = sucursalGrua['nombre'];
							sucursal.region = sucursalGrua['region'];
							sucursal.rut = sucursalGrua['rut'];
							sucursal.telefono = sucursalGrua['telefono'];
							sucursal.servicio = "Grua";
							this.sucursalesLibres.push(sucursal);
							this.sucursalesLibres_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
	}

	asociarSucursal(sucursal: SucursalModel) {
		this.errores = [];
		if (sucursal.servicio != 'Grua') {
			if (this.user.rol == RolesUser.USER_OPERATOR) {
				this.servicios.asociarSucursalProveedor(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
					this.servicios.asociarEmpleadoSucursal(this.operadorKey, sucursal.servicio, sucursal.codigo).then(result => {
						var pibot = 0, indice;
						this.sucursalesLibres.forEach(sucursalNodo => {
							if (sucursalNodo.codigo == sucursal.codigo && sucursalNodo.servicio == sucursal.servicio) {
								indice = pibot;
							}
							pibot += 1;
						});
						this.sucursalesLibres.splice(indice, 1);
						this.sucursalesLibres_keys.delete(sucursal.codigo);
						this.sucursales.push(sucursal);
						this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
						this.errores.push({severity:'success', detail: "La sucursal fue asociada al Operador con éxito", summary:'Sucursal asociada'});
					}).catch(error => {
						this.servicios.removeSucursalUsuario(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
							console.log("Rollback realizado");
						});
						this.errores.push({severity:'error', detail: "No fue posible asignar la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
					});
				}).catch(error => {
					this.errores.push({severity:'error', detail: "No fue posible asignar la sucursal, intentelo de nuevo mas tarde", summary:'Asignar Sucursal'});
				});
			} else {
				this.confirmationService.confirm({
					message: 'Actualmente el operador tiene asignada una Grúa, si desea asociarle esta nueva sucursal el operador dejará su rol de gruero. ¿Desea continuar?',
					accept: () => {
						this.quitarSucursalesOperador().then(async result => {
							// Cambiar Gruero a Operador
							await this.sucursales.forEach(sucursal => {
								this.sucursalesLibres.push(sucursal);
								this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
								this.sucursalesLibres_keys.delete(sucursal.codigo);
							});
							this.sucursales = [];
							var operador: OperadorModel = new OperadorModel();
							operador.rol = RolesUser.USER_OPERATOR;
							operador.nombre = this.user.nombre;
							operador.email = this.user.email;
							operador.verificado = true;
							operador.eliminado = false;
							operador.proveedor = this.user.proveedor;
							this.servicios.convertOperador(this.operadorKey, operador).then(result => {
								this.servicios.asociarSucursalProveedor(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
									this.servicios.asociarEmpleadoSucursal(this.operadorKey, sucursal.servicio, sucursal.codigo).then(result => {
										var pibot = 0, indice;
										this.sucursalesLibres.forEach(sucursalNodo => {
											if (sucursalNodo.codigo == sucursal.codigo && sucursalNodo.servicio == sucursal.servicio) {
												indice = pibot;
											}
											pibot += 1;
										});
										this.sucursalesLibres.splice(indice, 1);
										this.sucursalesLibres_keys.delete(sucursal.codigo);
										this.sucursales.push(sucursal);
										this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
										this.errores.push({severity:'success', detail: "La sucursal fue asociada al Operador con éxito", summary:'Sucursal asociada'});
									}).catch(error => {
										this.servicios.removeSucursalUsuario(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
											console.log("Rollback realizado");
										});
										this.errores.push({severity:'error', detail: "No fue posible asignar la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
									});
								}).catch(error => {
									this.errores.push({severity:'error', detail: "No fue posible asignar la sucursal, intentelo de nuevo mas tarde", summary:'Asignar Sucursal'});
								});
							});
						});
					}
				});
			}
		} else {
			if (this.user.rol == RolesUser.USER_OPERATOR) {
				this.confirmationService.confirm({
					message: 'Para asociar una sucursal de Grúa a este operador, se eliminaran las demás sucursales asociadas que pueda tener y no podrás asignarle alguna otra antes de quitarle la sucursal de Grúa. ¿Desea continuar?',
					accept: () => {
						this.quitarSucursalesOperador().then(async result => {
							var gruero: GrueroModel = new GrueroModel();
							this.router.navigate(['Gruero/Datos', {userUid: this.user.uid, codigoGrua: sucursal.codigo}]);
						});
					}
				});
			} else {
				this.confirmationService.confirm({
					message: 'Los Grueros solo pueden tener una sucursal de Grúa asociada, se cambiara la sucursal ' + this.user.sucursal + ' por la sucursal ' + sucursal.codigo + '. ¿Desea continuar?',
					accept: () => {
						this.quitarSucursalesOperador().then(async result => {
							await this.sucursales.forEach(sucursal => {
								this.sucursalesLibres.push(sucursal);
								this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
								this.sucursalesLibres_keys.delete(sucursal.codigo);
							});
							this.sucursales = [];
							this.servicios.asociarSucursalProveedor(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
								this.servicios.asociarEmpleadoSucursal(this.operadorKey, sucursal.servicio, sucursal.codigo).then(result => {
									var pibot = 0, indice;
									this.sucursalesLibres.forEach(sucursalNodo => {
										if (sucursalNodo.codigo == sucursal.codigo && sucursalNodo.servicio == sucursal.servicio) {
											indice = pibot;
										}
										pibot += 1;
									});
									this.sucursalesLibres.splice(indice, 1);
									this.sucursalesLibres_keys.delete(sucursal.codigo);
									this.sucursales.push(sucursal);
									this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
									this.errores.push({severity:'success', detail: "La sucursal fue asociada al Operador con éxito", summary:'Sucursal asociada'});
								}).catch(error => {
									this.servicios.removeSucursalUsuario(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
										console.log("Rollback realizado");
									});
									this.errores.push({severity:'error', detail: "No fue posible asignar la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
								});
							}).catch(error => {
								this.errores.push({severity:'error', detail: "No fue posible asignar la sucursal, intentelo de nuevo mas tarde", summary:'Asignar Sucursal'});
							});
						});
					}
				});
			}
		}
	}

	quitarSucursal(sucursal: SucursalModel) {
		this.errores = [];
		this.servicios.removeSucursalUsuario(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
			this.servicios.removeEmpleadoSucursal(this.operadorKey, sucursal.servicio, sucursal.codigo).then(result => {
				var pibot = 0, indice;
				this.sucursales.forEach(sucursalNodo => {
					if (sucursalNodo.codigo == sucursal.codigo && sucursalNodo.servicio == sucursal.servicio) {
						indice = pibot;
					}
					pibot += 1;
				});
				this.sucursales.splice(indice, 1);
				this.sucursales_keys.delete(sucursal.codigo);
				this.sucursalesLibres.push(sucursal);
				this.sucursalesLibres_keys.set(sucursal.codigo, sucursal.codigo);
				this.errores.push({severity:'success', detail: "La sucursal fue removida del Operador con éxito", summary:'Sucursal removida'});
			}).catch(error => {
				this.servicios.asociarSucursalProveedor(this.operadorKey, sucursal.servicio, sucursal.codigo).then(resultado => {
					console.log("Rollback realizado");
				});
				this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
			});
		}).catch(error => {
			this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
		});
	}

	async quitarSucursalesOperador() {
		var prt_suscriber = this.servicios.getSucursalesUsuario(this.operadorKey, "PRT").subscribe(async prts => {
			prts.forEach(PRT_Nodo => {
				var suscriber = this.servicios.getSucursal(PRT_Nodo.key, "PRT").subscribe(async PRT => {
					if (PRT != undefined) {
						this.servicios.removeSucursalUsuario(this.operadorKey, "PRT", PRT_Nodo.key).then(async resultado => {
							await this.servicios.removeEmpleadoSucursal(this.operadorKey, "PRT", PRT_Nodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(this.operadorKey, "PRT", PRT_Nodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			prt_suscriber.unsubscribe();
		});
		var lavado_suscriber = this.servicios.getSucursalesUsuario(this.operadorKey, "Lavado").subscribe(async lavados => {
			lavados.forEach(lavadoNodo => {
				var suscriber = this.servicios.getSucursal(lavadoNodo.key, "Lavado").subscribe(async lavado => {
					if (lavado != undefined) {
						this.servicios.removeSucursalUsuario(this.operadorKey, "Lavado", lavadoNodo.key).then(async resultado => {
							await this.servicios.removeEmpleadoSucursal(this.operadorKey, "Lavado", lavadoNodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(this.operadorKey, "Lavado", lavadoNodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			lavado_suscriber.unsubscribe();
		});
		var taller_suscriber = this.servicios.getSucursalesUsuario(this.operadorKey, "Taller").subscribe(async talleres => {
			talleres.forEach(tallerNodo => {
				var suscriber = this.servicios.getSucursal(tallerNodo.key, "Taller").subscribe(async taller => {
					if (taller != undefined) {
						this.servicios.removeSucursalUsuario(this.operadorKey, "Taller", tallerNodo.key).then(async resultado => {
							await this.servicios.removeEmpleadoSucursal(this.operadorKey, "Taller", tallerNodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(this.operadorKey, "Taller", tallerNodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			taller_suscriber.unsubscribe();
		});
		var grua_suscriber = this.servicios.getSucursalesUsuario(this.operadorKey, "Grua").subscribe(async gruas => {
			gruas.forEach(gruaNodo => {
				var suscriber = this.servicios.getSucursal(gruaNodo.key, "Grua").subscribe(async grua => {
					if (grua != undefined) {
						this.servicios.removeSucursalUsuario(this.operadorKey, "Grua", gruaNodo.key).then(async resultado => {
							await this.servicios.removeEmpleadoSucursal(this.operadorKey, "Grua", gruaNodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(this.operadorKey, "Grua", gruaNodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			grua_suscriber.unsubscribe();
		});
	}
}
