export class UserModel {
	email: string;
	password: string;
	rol: string;
	servicio: string;
	uid: any;

	constructor(){
	}
}
