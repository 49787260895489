export class GrueroModel {
	activo: boolean;
	verificado: boolean;
	actividad: boolean;
	nombre: string;
	email: string;
	proveedor: string;
	rol: string;
	calificacion: number;
	grua: {
		marca: string,
		modelo: string,
		placa: string
	};
	latitud: number;
	longitud: number;
	sucursal: string;
	telefono: string;
	eliminado: string;
	uid: string;
	
	constructor(){
	}
}