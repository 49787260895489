import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HomeComponent } from './home/components/home/home.component';
import { LoginComponent } from './login/login.component';
import { MarcasComponent } from './marcas/components/marcas/marcas.component';
import { ModelosComponent } from './marcas/components/modelos/modelos.component';
import { TalleresComponent } from './servicios/components/talleres/talleres.component';
import { AltaTallerComponent } from './servicios/components/alta-taller/alta-taller.component';
import { UpdateTallerComponent } from './shared/components/update-taller/update-taller.component';
import { HomeProveedorComponent } from './home-proveedor/components/home-proveedor/home-proveedor.component';
import { SeleccionarSucursalComponent } from './sucursal/components/seleccionar-sucursal/seleccionar-sucursal.component';
import { DatosSucursalComponent } from './sucursal/components/datos-sucursal/datos-sucursal.component';
import { SeleccionarTipoSucursalComponent } from './sucursal/components/seleccionar-tipo-sucursal/seleccionar-tipo-sucursal.component';
import { RegistroPreVentaComponent } from './proveedores/components/registro-pre-venta/registro-pre-venta.component';
import { RegistroProveedoresComponent } from './proveedores/components/registro-proveedores/registro-proveedores.component';
import { RegistroPromocionesComponent } from './promociones/components/registro-promociones/registro-promociones.component';
import { PromocionesComponent } from './promociones/components/promociones/promociones.component';
import { ProveedoresComponent } from './proveedores/components/proveedores/proveedores.component';
import { SucursalesProveedorComponent } from './shared/components/sucursales-proveedor/sucursales-proveedor.component';
import { ListarSucursalesLibresComponent } from './proveedores/components/listar-sucursales-libres/listar-sucursales-libres.component';
import { AltaProveedorComponent } from './proveedores/components/alta-proveedor/alta-proveedor.component';
import { ProveedoresVerificarComponent } from './proveedores/components/proveedores-verificar/proveedores-verificar.component';
import { RegistroProveedoresAbiertoComponent } from './home-proveedor/components/registro-proveedores-abierto/registro-proveedores-abierto.component';
import { PrtsComponent } from './servicios/components/prts/prts.component';
import { AltaPrtComponent } from './servicios/components/alta-prt/alta-prt.component';
import { LavadosComponent } from './servicios/components/lavados/lavados.component';
import { AltaLavadoComponent } from './servicios/components/alta-lavado/alta-lavado.component';
import { GruasComponent } from './servicios/components/gruas/gruas.component';
import { AltaGruaComponent } from './servicios/components/alta-grua/alta-grua.component';
import { OperadoresProveedorComponent } from './shared/components/operadores-proveedor/operadores-proveedor.component';
import { AltaOperadorComponent } from './operadores/components/alta-operador/alta-operador.component';
import { MisSucursalesComponent } from './shared/components/mis-sucursales/mis-sucursales.component';
import { SucursalesOperadorComponent } from './shared/components/sucursales-operador/sucursales-operador.component';
import { DetalleEstadoCuentaProveedorComponent } from './home-proveedor/components/detalle-estado-cuenta-proveedor/detalle-estado-cuenta-proveedor.component';
import { RetiroCuentaProveedorComponent } from './home-proveedor/components/retiro-cuenta-proveedor/retiro-cuenta-proveedor.component';
import { DetalleTransaccionComponent } from './home-proveedor/components/detalle-transaccion/detalle-transaccion.component';
import { ProveedorPerfilComponent } from './home-proveedor/components/proveedor-perfil/proveedor-perfil.component';
import { ConfirmarRetiroCuentaProveedorComponent } from './proveedores/components/confirmar-retiro-cuenta-proveedor/confirmar-retiro-cuenta-proveedor.component';
import { RetirosComponent } from './proveedores/components/retiros/retiros.component';
import { DetalleRetiroComponent } from './ordenes/components/detalle-retiro/detalle-retiro.component';
import { UpdatePrtComponent } from './shared/components/update-prt/update-prt.component';
import { UpdateLavadoComponent } from './shared/components/update-lavado/update-lavado.component';
import { UpdateGruaComponent } from './shared/components/update-grua/update-grua.component';
import { InvitarOperadorComponent } from './home-proveedor/components/invitar-operador/invitar-operador.component';
import { DatosGrueroComponent } from './servicios/components/datos-gruero/datos-gruero.component';
import { OrdenesComponent } from './ordenes/components/ordenes/ordenes.component';
import { RecuperarComponent } from './recuperar/recuperar.component';

const routes: Routes = [
{
		path: '',
		children: [
      {
        path: 'Proveedores/Nuevo',
        component: AltaProveedorComponent
      },
      {
        path: '',
      loadChildren: () => import('./home/home.module').then( m => m.HomeModule)  },
      {
        path: 'Login',
        component: LoginComponent
      },

      {path: 'Recuperar', component: RecuperarComponent},

      {
        path: 'Proveedor',
        loadChildren: () => import('./home-proveedor/home-proveedor.module').then( m => m.HomeProveedorModule)
      },
      {
        path: 'Sucursal',
        loadChildren: () => import('./sucursal/sucursal.module').then( m => m.SucursalModule)
      },
      {
        path: 'Proveedores',
        loadChildren: () => import('./proveedores/proveedores.module').then( m => m.ProveedoresModule)
      },
      {
        path: 'Bancos',
        loadChildren: () => import('./bancos/bancos.module').then( m => m.BancosModule)
      },
      {
        path: 'Servicios',
        loadChildren: () => import('./servicios/servicios.module').then( m => m.ServiciosModule)
      },
      {
        path: 'Marcas',
        loadChildren: () => import('./marcas/marcas.module').then( m => m.MarcasModule)
      },
      {
        path: 'Promociones',
        loadChildren: () => import('./promociones/promociones.module').then( m => m.PromocionesModule)
      },
      {
        path: 'Ordenes',
        loadChildren: () => import('./ordenes/ordenes.module').then( m => m.OrdenesModule)
      },
      {
        path: 'Operadores',
        loadChildren: () => import('./operadores/operadores.module').then( m => m.OperadoresModule)
      }
			/*{path: 'Talleres', component: TalleresComponent},
			{path: 'Talleres/Nuevo', component: AltaTallerComponent},
			{path: 'Talleres/Modificar/:id', component: UpdateTallerComponent},
			{path: 'PRTs', component: PrtsComponent},
			{path: 'PRTs/Nuevo', component: AltaPrtComponent},
			{path: 'PRTs/Modificar/:id', component: UpdatePrtComponent},
			{path: 'Lavados', component: LavadosComponent},
			{path: 'Lavados/Nuevo', component: AltaLavadoComponent},
			{path: 'Lavados/Modificar/:id', component: UpdateLavadoComponent},
			{path: 'Gruas', component: GruasComponent},
			{path: 'Gruas/Nuevo', component: AltaGruaComponent},
			{path: 'Gruas/Modificar/:id', component: UpdateGruaComponent},
			{path: 'Marcas', component: MarcasComponent},
			{path: 'Modelos/:marca', component: ModelosComponent},
			{path: 'Login/Sucursal', component: SeleccionarSucursalComponent},
			{path: 'Login/TipoSucursal', component: SeleccionarTipoSucursalComponent},
			{path: 'Sucursal/Datos', component: DatosSucursalComponent},
			{path: 'Registro/Sucursal', component: RegistroPreVentaComponent},
			{path: 'Registro/Proveedores', component: RegistroProveedoresComponent},
			{path: 'Registro/Promociones', component: RegistroPromocionesComponent},
			{path: 'Promociones', component: PromocionesComponent},
			{path: 'Proveedores', component: ProveedoresComponent},
			{path: 'Sucursales/:proveedor', component: SucursalesProveedorComponent},
			{path: 'Agregar/Sucursal', component: ListarSucursalesLibresComponent},
			{path: 'Proveedores/Nuevo', component: AltaProveedorComponent},
			{path: 'Proveedores/Verificar', component: ProveedoresVerificarComponent},
			{path: 'Sucursal/Nueva', component: RegistroProveedoresAbiertoComponent},
			{path: 'Mis-Operadores', component: OperadoresProveedorComponent},
			{path: 'Mis-Sucursales', component: MisSucursalesComponent},
			{path: 'Operadores/Nuevo/:id/:uidInvitacion', component: AltaOperadorComponent},
			{path: 'Operador/Sucursales', component: SucursalesOperadorComponent},
			{path: 'Proveedor', component: HomeProveedorComponent},
			{path: 'Proveedor/Cuenta/Detalle', component: DetalleEstadoCuentaProveedorComponent},
			{path: 'Proveedor/Cuenta/Detalle/Transaccion/:tipo/:id', component: DetalleTransaccionComponent},
			{path: 'Proveedor/Cuenta/Retiro', component: RetiroCuentaProveedorComponent},
			{path: 'Proveedor/Perfil', component: ProveedorPerfilComponent},
			{path: 'Proveedor/Cuenta/Retiro/Confirmacion/:monto', component: ConfirmarRetiroCuentaProveedorComponent},
			{path: 'Retiros', component: RetirosComponent},
			{path: 'Detalle/Retiro/:id', component: DetalleRetiroComponent},
			{path: 'Operadores/Invitar', component: InvitarOperadorComponent},
			{path: 'Gruero/Datos', component: DatosGrueroComponent},
			{path: 'Ordenes', component: OrdenesComponent},*/
		]
	}
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
