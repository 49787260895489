export class sucursalPreventaModel {
	email: string;
	nombre: string;
	rut: string;
	user: string;
	telefono: string;
	region: string;
	comuna: string;
	direccion: string;
	latitud: number;
	longitud: number;
	servicio: string;
	confirmadoPorEmail: string;
	key: string;
	constructor(){
	}
}