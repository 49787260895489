export class GruaModel {
	email: string;
	codigo: string;
	comision: string;
	activo: any;
	rut: string;
	codigoPais: string;
	comuna: string;
	direccion: string;
	eliminado: any;
	estado: string;
	horarios: {
		sabado: string;
		semana: string;
	};
	latitud: number;
	longitud: number;
	nombre: string;
	region: string;
	telefono: string;

	constructor(){
	}
}