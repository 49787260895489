export class PRTModel {
	Inmediato: {
		lugares: number;
	};
	reserva: {
		horarios: {
			jueves: string;
			lunes: string;
			martes: string;
			miercoles: string;
			sabado: string;
			viernes: string;
		};
		lugares: {
			jueves: string;
			lunes: string;
			martes: string;
			miercoles: string;
			sabado: string;
			viernes: string;
		};
	};
	email: string;
	codigo: string;
	precios: {
		B: number,
		A1: number,
		A2: number,
	};
	comision: string;
	activo: any;
	rut: string;
	codigoPais: string;
	comuna: string;
	direccion: string;
	eliminado: any;
	estado: string;
	horarios: {
		sabado: string;
		semana: string;
	};
	latitud: number;
	longitud: number;
	nombre: string;
	region: string;
	telefono: string;
	provee: string;

	constructor(){
	}
}