import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Title } from "@angular/platform-browser";

import { Router } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';

import { ProveedorModel } from '../../../models/proveedor-model';
import { sucursalPreventaModel } from '../../../models/sucursalPreventaModel';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';

declare var google;

@Component({
	selector: 'app-alta-proveedor',
	templateUrl: './alta-proveedor.component.html',
	styleUrls: ['./alta-proveedor.component.scss']
})
export class AltaProveedorComponent implements OnInit {

	public registroProveedor: FormGroup;
	public errores:  Message[] = [];

	public paises: Array<{nombre: string, codigo: string}> = new Array();
	public regiones: Array<{nombre: string}> = new Array();
	public comunas: Array<string> = new Array();
	public bancos: Array<any> = new Array();

	public sucursalCheck: any = false;

	public imagen: any = undefined;

	private map: any;
	private marker: any;
	public serviciosSucursal: Array<any> = new Array();

	private geocoder;

	constructor(private servicios: FirebaseService, private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService, public formBuilder: FormBuilder, private title: Title) {
    title.setTitle("Registro de Nuevo Proveedor FixBeep"); 

		this.registroProveedor = this.formBuilder.group({
			email: new FormControl('', [Validators.required, Validators.email]),
			nombre: new FormControl('', [Validators.required]),
			rut: new FormControl('', [Validators.required]),
			tel: new FormControl('', [Validators.required]),
			pais: new FormControl({value: '', disabled: true}, [Validators.required]),
			region: new FormControl('', [Validators.required]),
			comuna: new FormControl('', [Validators.required]),
			direccion: new FormControl('', [Validators.required]),
			razonSocial: new FormControl('', [Validators.required]),
			password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/)]),
			confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/)]),
			banco: new FormControl(''),
			tipoCuenta: new FormControl('CC'),
			nroCuenta: new FormControl(''),
			reCaptcha: new FormControl(null, [Validators.required]),
			servicio: new FormControl("PRT"),
			emailSucursal: new FormControl("")
		});
		this.serviciosSucursal = [
		{ value: 'PRT', nombre: 'PRT'},
		{ value: 'Taller', nombre: 'Taller'},
		{ value: 'Grua', nombre: 'Grúa'},
		{ value: 'Lavado', nombre: 'Lavado'},
		];
	}

	ngOnDestroy(){
		this.title.setTitle("FixBeep");
	}

	public getComunas(){
		this.servicios.getComunas(this.registroProveedor.get('region').value).subscribe(comunas => {
			this.comunas = [];
			if (comunas[0] != undefined) {
				this.registroProveedor.get('comuna').setValue(comunas[0].payload.val().toString().toUpperCase());
			}
			comunas.forEach(comunaNodo => {
				var comuna = comunaNodo.payload.val();
				this.comunas.push(comuna.toString().toUpperCase());
			});
		});
  }

  ngAfterViewInit(){
    this.geocoder = new google.maps.Geocoder();
  }

	ngOnInit() {


	//	if (this.servicios.user != null || this.servicios.user != undefined) {
		//	this.router.navigate(['']);
	//	} else {
			this.servicios.getPaises().subscribe(paises => {
				this.paises = [];
				paises.forEach(paisNodo => {
					var pais = paisNodo.payload.val();
					this.paises.push({nombre: pais['nombre'], codigo: paisNodo.key});
				});
				this.registroProveedor.get('pais').setValue('CH');
			});
			this.servicios.getRegiones().subscribe(regiones => {
				this.regiones = [];
				regiones.forEach(regionNodo => {
					var region = regionNodo.payload.val();
					this.regiones.push({nombre: region['nombre']});
				});
				this.registroProveedor.get('region').setValue(this.regiones[0].nombre);
				this.getComunas();
			});
			this.servicios.getBancos().then(bancos => {
				this.bancos = [];
				bancos.forEach(bancoNodo => {
					var banco = bancoNodo.val();
					this.bancos.push({nombre: banco.nombre});
				});
				this.registroProveedor.get('banco').setValue(this.bancos[0].nombre);
			});
	//	}
	}

	check() {
		this.sucursalCheck = !this.sucursalCheck;
		if (this.sucursalCheck)
			this.obtenerUbicacion();
		this.registroProveedor.get('emailSucursal').setValue(this.registroProveedor.get('email').value);
	}

	obtenerUbicacion(){
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.initMap(position.coords.latitude, position.coords.longitude).then(data => {
					// console.log(data);
				});
			});
		}
	}

	async initMap(lat,lng){
		this.map = new google.maps.Map(document.getElementById('map'), {
			center: {lat: lat, lng: lng},
			zoom: 12,
			disableDefaultUI: true,
		})
		var infowindow = new google.maps.InfoWindow();
		this.marker = new google.maps.Marker({
			title: 'Mi Sucursal',
			position: {lat: lat, lng: lng},
			animation: 'DROP',
			draggable: true
		});
		infowindow.setContent(this.marker.getTitle());
		infowindow.open(this.map,this.marker);
		this.marker.addListener('drag', data => {
			infowindow.setContent(this.marker.getTitle());
			this.geocoder.geocode({'location': this.marker.getPosition()}, data => {
				if (data != undefined && data != null) {
					var regionDrag = data[0].formatted_address.split(', ', 3)[2];
					var comunaDrag = data[0].formatted_address.split(', ', 3)[1];
					this.regiones.forEach( async region => {
						if (regionDrag != undefined && (region.nombre.toLowerCase().includes(regionDrag.toLowerCase()) || regionDrag.toLowerCase().includes(region.nombre.toLowerCase()))) {
							this.registroProveedor.get('region').setValue(region.nombre);
							this.servicios.getComunas(this.registroProveedor.get('region').value).subscribe(comunas => {
								this.comunas = [];
								comunas.forEach(comunaNodo => {
									var comuna = comunaNodo.payload.val();
									this.comunas.push(comuna.toString().toUpperCase());
								});
								this.comunas.forEach(comuna => {
									if (comunaDrag != undefined && (comuna.toLowerCase().includes(comunaDrag.toLowerCase()) || comunaDrag.toLowerCase().includes(comuna.toLowerCase()))) {
										this.registroProveedor.get('comuna').setValue(comuna);
									}
								});
							});
						}
					});
					this.registroProveedor.get('direccion').setValue((data[0].formatted_address.split(',', 1))[0]);
				}
			});
		});
		this.marker.setMap(this.map);
		this.geocoder.geocode({'location': this.marker.getPosition()}, data => {
			this.registroProveedor.get('direccion').setValue((data[0].formatted_address.split(',', 1))[0]);
		});
	}

	public async ingresarProveedor() {
		this.errores = [];
		if (this.sucursalCheck) {
			var nodos = await this.servicios.getSucursalesPreVenta(this.registroProveedor.get('servicio').value).subscribe(async resultado => {
				var existeSucursal = false;
				resultado.forEach(data => {
					var nodo = data.payload.val();
					if (this.registroProveedor.get('emailSucursal').value == nodo['email']) {
						existeSucursal = true;
					}
				});
				nodos.unsubscribe();
				if (!existeSucursal) {
					if (this.registroProveedor.valid) {
						var nuevoProveedor = new ProveedorModel();
						nuevoProveedor.email = this.registroProveedor.get('email').value;
						nuevoProveedor.nombre = this.registroProveedor.get('nombre').value;
						nuevoProveedor.razonSocial = this.registroProveedor.get('razonSocial').value;
						nuevoProveedor.rut = this.registroProveedor.get('rut').value;
						nuevoProveedor.codigoPais = this.registroProveedor.get('pais').value;
						nuevoProveedor.region = this.registroProveedor.get('region').value;
						nuevoProveedor.comuna = this.registroProveedor.get('comuna').value;
						nuevoProveedor.direccion = this.registroProveedor.get('direccion').value;
						nuevoProveedor.telefono = this.registroProveedor.get('tel').value;
						nuevoProveedor.banco = this.registroProveedor.get('banco').value;
						nuevoProveedor.tipoCuenta = this.registroProveedor.get('tipoCuenta').value;
						nuevoProveedor.nroCuenta = this.registroProveedor.get('nroCuenta').value;
						nuevoProveedor.tutorial = 0;
						var pass = this.registroProveedor.get('password').value;
						var confirmPass = this.registroProveedor.get('confirmPassword').value;
						if (confirmPass == pass) {
							await this.servicios.getProveedores().then(resultado => {
								var existRut = false;
								var existEmail = false;
								resultado.forEach(nodoProveedor => {
									if (nuevoProveedor.rut == nodoProveedor.val().rut || nuevoProveedor.email == nodoProveedor.val().email) {
										if (nuevoProveedor.rut == nodoProveedor.val().rut) {
											existRut = true;
										} else {
											existEmail = true;
										}
									}
								});
								if (!existRut && !existEmail) {
									this.servicios.addCuentaAuth(nuevoProveedor.email, pass).then(nodo => {
										this.servicios.insertProveedor(nuevoProveedor, nodo.user.uid, this.imagen).then(async resultadoProveedor => {
											if (this.sucursalCheck) {
												var nuevaSucursal = new sucursalPreventaModel();
												nuevaSucursal.email = this.registroProveedor.get('emailSucursal').value;
												nuevaSucursal.nombre = this.registroProveedor.get('razonSocial').value;
												nuevaSucursal.rut = this.registroProveedor.get('rut').value;
												nuevaSucursal.telefono = this.registroProveedor.get('tel').value;
												nuevaSucursal.region = this.registroProveedor.get('region').value;
												nuevaSucursal.comuna = this.registroProveedor.get('comuna').value;
												nuevaSucursal.direccion = this.registroProveedor.get('direccion').value;
												nuevaSucursal.servicio = this.registroProveedor.get('servicio').value;
												nuevaSucursal.latitud = this.marker.getPosition().lat();
												nuevaSucursal.longitud = this.marker.getPosition().lng();
												nuevaSucursal.user = nodo.user.uid;
												this.servicios.insertSucursalPreVenta(nuevaSucursal).then(resultado => {
													sessionStorage.setItem("mensaje_login_correcto", "Su cuenta ha sido creada. Nos pondremos en contacto con usted a la brevedad. ¡Muchas gracias!");
													this.servicios.logOut();
													this.router.navigate(['']);
												}).catch(error => {
													this.errores.push({severity:'error', detail: error, summary:'Error'});
												});
											} else {
												sessionStorage.setItem("mensaje_login_correcto", "Su cuenta ha sido creada. Nos pondremos en contacto con usted a la brevedad. ¡Muchas gracias!");
												this.servicios.logOut();
												this.router.navigate(['']);
											}
										}).catch(error => {
											this.errores.push({severity:'error', detail: error, summary:'Error'});
										});
									}).catch(error => {
										if(error.code=='auth/email-already-in-use') {
											this.errores.push({severity:'error', detail:'¡Email ya registrado!', summary:'Error en el alta'});
										}else{
											if(error.code=='auth/wrong-password') {
												this.errores.push({severity:'error', detail:'¡Contraseña incorrecta!', summary:'Error en el alta'});
											}else{
												if(error.code=='auth/account-exists-with-different-credential') {
													this.errores.push({severity:'error', detail:'¡Su cuenta ya esta vinculada a la plataforma!', summary:'Error en el alta'});
												}else{
													if(error.code=='auth/network-request-failed') {
														this.errores.push({severity:'error', detail:'¡Verifiqué su conexión a internet!', summary:'Error en el alta'});
													}else{
														this.errores.push({severity:'error', detail:'Ha ocurrido un error inesperado. Por favor intente nuevamente o comuniquese con nosotros.', summary:'Error en el alta'});
													}
												}
											}
										}
									});
								} else {
									if (existEmail) {
										this.errores.push({severity:'error', detail:'¡Ya existe un Proveedor con ese Email!', summary:'Proveedor duplicado'});
									} else {
										this.errores.push({severity:'error', detail:'¡Ya existe un Proveedor con ese RUT!', summary:'Proveedor duplicado'});
									}
								}
							});
						} else {
							this.errores.push({severity:'error', detail:'¡Las contraseñas deben coincidir!', summary:'Error'});
						}
					} else {
						this.errores.push({severity:'error', detail:'Faltan datos', summary:'Error'});
					}
				} else {
					this.errores.push({severity:'error', detail:'Ya existe una sucursal de ' + this.registroProveedor.get('servicio').value + ' con ese email', summary:'Error en el alta'});
				}
			});
		} else {
			if (this.registroProveedor.valid) {
				var nuevoProveedor = new ProveedorModel();
				nuevoProveedor.email = this.registroProveedor.get('email').value;
				nuevoProveedor.nombre = this.registroProveedor.get('nombre').value;
				nuevoProveedor.razonSocial = this.registroProveedor.get('razonSocial').value;
				nuevoProveedor.rut = this.registroProveedor.get('rut').value;
				nuevoProveedor.codigoPais = this.registroProveedor.get('pais').value;
				nuevoProveedor.region = this.registroProveedor.get('region').value;
				nuevoProveedor.comuna = this.registroProveedor.get('comuna').value;
				nuevoProveedor.direccion = this.registroProveedor.get('direccion').value;
				nuevoProveedor.telefono = this.registroProveedor.get('tel').value;
				nuevoProveedor.banco = this.registroProveedor.get('banco').value;
				nuevoProveedor.tipoCuenta = this.registroProveedor.get('tipoCuenta').value;
				nuevoProveedor.nroCuenta = this.registroProveedor.get('nroCuenta').value;
				nuevoProveedor.tutorial = 0;
				var pass = this.registroProveedor.get('password').value;
				var confirmPass = this.registroProveedor.get('confirmPassword').value;
				if (confirmPass == pass) {
					await this.servicios.getProveedores().then(resultado => {
						var existRut = false;
						var existEmail = false;
						resultado.forEach(nodoProveedor => {
							if (nuevoProveedor.rut == nodoProveedor.val().rut || nuevoProveedor.email == nodoProveedor.val().email) {
								if (nuevoProveedor.rut == nodoProveedor.val().rut) {
									existRut = true;
								} else {
									existEmail = true;
								}
							}
						});
						if (!existRut && !existEmail) {
							this.servicios.addCuentaAuth(nuevoProveedor.email, pass).then(nodo => {
								this.servicios.insertProveedor(nuevoProveedor, nodo.user.uid, this.imagen).then(async resultadoProveedor => {
									sessionStorage.setItem("mensaje_login_correcto", "Su cuenta ha sido creada. Nos pondremos en contacto con usted a la brevedad. ¡Muchas gracias!");
									this.servicios.logOut();
									this.router.navigate(['']);
								}).catch(error => {
									this.errores.push({severity:'error', detail: error, summary:'Error'});
								});
							}).catch(error => {
								if(error.code=='auth/email-already-in-use') {
									this.errores.push({severity:'error', detail:'¡Email ya registrado!', summary:'Error en el alta'});
								}else{
									if(error.code=='auth/wrong-password') {
										this.errores.push({severity:'error', detail:'¡Contraseña incorrecta!', summary:'Error en el alta'});
									}else{
										if(error.code=='auth/account-exists-with-different-credential') {
											this.errores.push({severity:'error', detail:'¡Su cuenta ya esta vinculada a la plataforma!', summary:'Error en el alta'});
										}else{
											if(error.code=='auth/network-request-failed') {
												this.errores.push({severity:'error', detail:'¡Verifiqué su conexión a internet!', summary:'Error en el alta'});
											}else{
												this.errores.push({severity:'error', detail:'Ha ocurrido un error inesperado. Por favor intente nuevamente o comuniquese con nosotros.', summary:'Error en el alta'});
											}
										}
									}
								}
							});
						} else {
							if (existEmail) {
								this.errores.push({severity:'error', detail:'¡Ya existe un Proveedor con ese Email!', summary:'Proveedor duplicado'});
							} else {
								this.errores.push({severity:'error', detail:'¡Ya existe un Proveedor con ese RUT!', summary:'Proveedor duplicado'});
							}
						}
					});
				} else {
					this.errores.push({severity:'error', detail:'¡Las contraseñas deben coincidir!', summary:'Error'});
				}
			} else {
				this.errores.push({severity:'error', detail:'Faltan datos', summary:'Error'});
			}
		}
	}

	setImagen(event){
		this.imagen = event.target.files[0];
	}

	public getError(controlName: string): string {
		let error = '';
		const control = this.registroProveedor.get(controlName);
		if (control.touched && control.errors != null) {
			error = JSON.stringify(control.errors);
		}
		if (error.indexOf('requiredPattern')>-1) {
			error = "La contraseña debe tener al menos 8 caracteres, al menos una mayúscula, un número y un caracter extraño"
		}
		if (error.indexOf('minlength')>-1) {
			error = "Contraseña débil";
		}
		if (error.indexOf('"required":true')>-1) {
			error = "Campo requerido";
		}
		if (error.indexOf('"email":true')>-1) {
			error = "Formato de dirección email incorrecto";
		}
		if (controlName == "confirmPassword") {
			var pass = this.registroProveedor.get('password').value, pass2 = this.registroProveedor.get('confirmPassword').value;
			if (pass != pass2 && (pass2 != '' && pass != '')) {
				error = "La confirmación de contraseña debe coincidir con la contraseña";
			}
		}
		return error;
	}
}
