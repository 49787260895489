import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';

import { LavadoModel } from '../../../models/lavado-model';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';

@Component({
	selector: 'app-update-lavado',
	templateUrl: './update-lavado.component.html',
	styleUrls: ['./update-lavado.component.scss']
})
export class UpdateLavadoComponent implements OnInit {

	public registroLavado: FormGroup;
	public erroresLavado:  Message[] = [];

	public imagePath;
	public imagenUrl;
	public paises: Array<{nombre: string, codigo: string}> = new Array();
	public regiones: Array<{nombre: string}> = new Array();
	public comunas: Array<string> = new Array();
	public serviciosLavado: Array<SelectItem> = new Array();
	public proveeServicios: Array<SelectItem> = new Array();

	private item1: SelectItem = {label: 'Portal', value: 'portal'};
	private item2: SelectItem = {label: 'Autolavado Tradicional', value: 'tradicional'};
	private item3: SelectItem = {label: 'Jet Wash', value: 'jet'};
	private item4: SelectItem = {label: 'Arco', value: 'arco'};

	public codigoLavado: string = '';

	public isProveedor = "0";

	public activo = false;
	public imagen = undefined;
	public tieneImagen = false;
	public serviciosSeleccionados: Array<string> = new Array();

	constructor(private servicios: FirebaseService,
		private router: Router,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		public formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit() {
		var isProveedor = sessionStorage.getItem("is_proveedor");
		if (isProveedor != null && isProveedor != undefined) {
			this.isProveedor = isProveedor;
			sessionStorage.removeItem("is_proveedor");
		}
		this.codigoLavado = this.activatedRoute.snapshot.paramMap.get("id");
		if (this.codigoLavado == undefined || this.codigoLavado == null) {
			this.router.navigate(['']);
		}
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				}
			});
		}
		this.registroLavado = this.formBuilder.group({
			email: new FormControl('', [Validators.required, Validators.email]),
			nombre: new FormControl('', [Validators.required]),
			rut: new FormControl('', [Validators.required]),
			key: new FormControl('', [Validators.required]),
			tel: new FormControl('', [Validators.required]),
			latitud: new FormControl('-', [Validators.required]),
			longitud: new FormControl('-', [Validators.required]),
			pais: new FormControl('UY', [Validators.required]),
			region: new FormControl('', [Validators.required]),
			comuna: new FormControl('', [Validators.required]),
			direccion: new FormControl('', [Validators.required]),
			semana1: new FormControl('', [Validators.required]),
			semana12: new FormControl('', [Validators.required]),
			semana2: new FormControl(''),
			semana22: new FormControl(''),
			sabado1: new FormControl('', [Validators.required]),
			sabado12: new FormControl('', [Validators.required]),
			sabado2: new FormControl(''),
			sabado22: new FormControl(''),
			lugaresInmediato: new FormControl('', [Validators.required]),
			lugaresReservaLunes1: new FormControl('', [Validators.required]),
			reservaLunes1: new FormControl('', [Validators.required]),
			reservaLunes12: new FormControl('', [Validators.required]),
			lugaresReservaMartes1: new FormControl('', [Validators.required]),
			reservaMartes1: new FormControl('', [Validators.required]),
			reservaMartes12: new FormControl('', [Validators.required]),
			lugaresReservaMiercoles1: new FormControl('', [Validators.required]),
			reservaMiercoles1: new FormControl('', [Validators.required]),
			reservaMiercoles12: new FormControl('', [Validators.required]),
			lugaresReservaJueves1: new FormControl('', [Validators.required]),
			reservaJueves1: new FormControl('', [Validators.required]),
			reservaJueves12: new FormControl('', [Validators.required]),
			lugaresReservaViernes1: new FormControl('', [Validators.required]),
			reservaViernes1: new FormControl('', [Validators.required]),
			reservaViernes12: new FormControl('', [Validators.required]),
			lugaresReservaSabado1: new FormControl('', [Validators.required]),
			reservaSabado1: new FormControl('', [Validators.required]),
			reservaSabado12: new FormControl('', [Validators.required]),
			lugaresReservaLunes2: new FormControl(''),
			reservaLunes2: new FormControl(''),
			reservaLunes22: new FormControl(''),
			lugaresReservaMartes2: new FormControl(''),
			reservaMartes2: new FormControl(''),
			reservaMartes22: new FormControl(''),
			lugaresReservaMiercoles2: new FormControl(''),
			reservaMiercoles2: new FormControl(''),
			reservaMiercoles22: new FormControl(''),
			lugaresReservaJueves2: new FormControl(''),
			reservaJueves2: new FormControl(''),
			reservaJueves22: new FormControl(''),
			lugaresReservaViernes2: new FormControl(''),
			reservaViernes2: new FormControl(''),
			reservaViernes22: new FormControl(''),
			lugaresReservaSabado2: new FormControl(''),
			reservaSabado2: new FormControl(''),
			reservaSabado22: new FormControl(''),
			estado: new FormControl(''),
			activo: new FormControl(''),
			comision: new FormControl('', [Validators.required]),
			jet: new FormControl(null),
			portal: new FormControl(null),
			arco: new FormControl(null),
			tradicional: new FormControl(null),
		});
		this.servicios.getPaises().subscribe(paises => {
			this.paises = [];
			paises.forEach(paisNodo => {
				this.paises.push({nombre: paisNodo.payload.val()['nombre'], codigo: paisNodo.key});
			});
		});
		this.servicios.getRegiones().subscribe(regiones => {
			this.regiones = [];
			regiones.forEach(regionNodo => {
				var region = regionNodo.payload.val();
				this.regiones.push({nombre: region['nombre']});
			});
		});
		this.serviciosLavado.push(this.item1);
		this.serviciosLavado.push(this.item2);
		this.serviciosLavado.push(this.item3);
		this.serviciosLavado.push(this.item4);
		var subscriptor = this.servicios.getLavado(this.codigoLavado).subscribe(resultado => {
			subscriptor.unsubscribe();
			var nodoLavado = resultado.payload.val();
			this.registroLavado.get('email').setValue(nodoLavado['email']);
			this.registroLavado.get('nombre').setValue(nodoLavado['nombre']);
			this.registroLavado.get('rut').setValue(nodoLavado['rut']);
			this.registroLavado.get('key').setValue(nodoLavado['codigo']);
			this.registroLavado.get('tel').setValue(nodoLavado['telefono']);
			this.registroLavado.get('latitud').setValue(nodoLavado['latitud']);
			this.registroLavado.get('longitud').setValue(nodoLavado['longitud']);
			this.registroLavado.get('pais').setValue(nodoLavado['codigoPais']);
			this.registroLavado.get('estado').setValue(nodoLavado['estado']);
			this.registroLavado.get('comision').setValue(nodoLavado['comision']);
			if (nodoLavado['precios']['jet'] != undefined)
				this.registroLavado.get('jet').setValue(nodoLavado['precios']['jet']);
			if (nodoLavado['precios']['arco'] != undefined)
				this.registroLavado.get('arco').setValue(nodoLavado['precios']['arco']);
			if (nodoLavado['precios']['portal'] != undefined)
				this.registroLavado.get('portal').setValue(nodoLavado['precios']['portal']);
			if (nodoLavado['precios']['tradicional'] != undefined)
				this.registroLavado.get('tradicional').setValue(nodoLavado['precios']['tradicional']);
			this.activo = nodoLavado['activo'];
			this.registroLavado.get('region').setValue(nodoLavado['region']);
			this.registroLavado.get('comuna').setValue(nodoLavado['comuna']);
			this.registroLavado.get('direccion').setValue(nodoLavado['direccion']);
			this.registroLavado.get('lugaresInmediato').setValue(nodoLavado['Inmediato']['lugares']);
			var horarios_semana = nodoLavado['horarios']['semana'].split("/");
			var semana1 = (horarios_semana[0].split("-"))[0];
			var semana12 = (horarios_semana[0].split("-"))[1];
			this.registroLavado.get('semana1').setValue(semana1);
			this.registroLavado.get('semana12').setValue(semana12);
			if (horarios_semana[1] != undefined) {
				var semana2 = (horarios_semana[1].split("-"))[0];
				var semana22 = (horarios_semana[1].split("-"))[1];
				this.registroLavado.get('semana2').setValue(semana2);
				this.registroLavado.get('semana22').setValue(semana22);
			}
			var horarios_sabado = nodoLavado['horarios']['sabado'].split("/");
			var sabado1 = (horarios_sabado[0].split("-"))[0];
			var sabado12 = (horarios_sabado[0].split("-"))[1];
			this.registroLavado.get('sabado1').setValue(sabado1);
			this.registroLavado.get('sabado12').setValue(sabado12);
			if (horarios_sabado[1] != undefined) {
				var sabado2 = (horarios_sabado[1].split("-"))[0];
				var sabado22 = (horarios_sabado[1].split("-"))[1];
				this.registroLavado.get('sabado2').setValue(sabado2);
				this.registroLavado.get('sabado22').setValue(sabado22);
			}
			if (nodoLavado['reserva']['lugares']['lunes'] != undefined) {
				var lugares_lunes = nodoLavado['reserva']['lugares']['lunes'].split("/");
				this.registroLavado.get('lugaresReservaLunes1').setValue(lugares_lunes[0]);
				this.registroLavado.get('lugaresReservaLunes2').setValue(lugares_lunes[1]);
			}
			if (nodoLavado['reserva']['lugares']['martes'] != undefined) {
				var lugares_martes = nodoLavado['reserva']['lugares']['martes'].split("/");
				this.registroLavado.get('lugaresReservaMartes1').setValue(lugares_martes[0]);
				this.registroLavado.get('lugaresReservaMartes2').setValue(lugares_martes[1]);
			}
			if (nodoLavado['reserva']['lugares']['miercoles'] != undefined) {
				var lugares_miercoles = nodoLavado['reserva']['lugares']['miercoles'].split("/");
				this.registroLavado.get('lugaresReservaMiercoles1').setValue(lugares_miercoles[0]);
				this.registroLavado.get('lugaresReservaMiercoles2').setValue(lugares_miercoles[1]);
			}
			if (nodoLavado['reserva']['lugares']['jueves'] != undefined) {
				var lugares_jueves = nodoLavado['reserva']['lugares']['jueves'].split("/");
				this.registroLavado.get('lugaresReservaJueves1').setValue(lugares_jueves[0]);
				this.registroLavado.get('lugaresReservaJueves2').setValue(lugares_jueves[1]);
			}
			if (nodoLavado['reserva']['lugares']['viernes'] != undefined) {
				var lugares_viernes = nodoLavado['reserva']['lugares']['viernes'].split("/");
				this.registroLavado.get('lugaresReservaViernes1').setValue(lugares_viernes[0]);
				this.registroLavado.get('lugaresReservaViernes2').setValue(lugares_viernes[1]);
			}
			if (nodoLavado['reserva']['lugares']['sabado'] != undefined) {
				var lugares_sabado = nodoLavado['reserva']['lugares']['sabado'].split("/");
				this.registroLavado.get('lugaresReservaSabado1').setValue(lugares_sabado[0]);
				this.registroLavado.get('lugaresReservaSabado2').setValue(lugares_sabado[1]);
			}
			if (nodoLavado['reserva']['horarios']['lunes'] != undefined) {
				var horarios_reserva_lunes = nodoLavado['reserva']['horarios']['lunes'].split("/");
				var reservaLunes1 = (horarios_reserva_lunes[0].split("-"))[0];
				var reservaLunes12 = (horarios_reserva_lunes[0].split("-"))[1];
				this.registroLavado.get('reservaLunes1').setValue(reservaLunes1);
				this.registroLavado.get('reservaLunes12').setValue(reservaLunes12);
				if (horarios_reserva_lunes[1] != undefined) {
					var reservaLunes2 = (horarios_reserva_lunes[1].split("-"))[0];
					var reservaLunes22 = (horarios_reserva_lunes[1].split("-"))[1];
					this.registroLavado.get('reservaLunes2').setValue(reservaLunes2);
					this.registroLavado.get('reservaLunes22').setValue(reservaLunes22);
				}
			}
			if (nodoLavado['reserva']['horarios']['martes'] != undefined) {
				var horarios_reserva_martes = nodoLavado['reserva']['horarios']['martes'].split("/");
				var reservaMartes1 = (horarios_reserva_martes[0].split("-"))[0];
				var reservaMartes12 = (horarios_reserva_martes[0].split("-"))[1];
				this.registroLavado.get('reservaMartes1').setValue(reservaMartes1);
				this.registroLavado.get('reservaMartes12').setValue(reservaMartes12);
				if (horarios_reserva_martes[1] != undefined) {
					var reservaMartes2 = (horarios_reserva_martes[1].split("-"))[0];
					var reservaMartes22 = (horarios_reserva_martes[1].split("-"))[1];
					this.registroLavado.get('reservaMartes2').setValue(reservaMartes2);
					this.registroLavado.get('reservaMartes22').setValue(reservaMartes22);
				}
			}
			if (nodoLavado['reserva']['horarios']['miercoles'] != undefined) {
				var horarios_reserva_miercoles = nodoLavado['reserva']['horarios']['miercoles'].split("/");
				var reservaMiercoles1 = (horarios_reserva_miercoles[0].split("-"))[0];
				var reservaMiercoles12 = (horarios_reserva_miercoles[0].split("-"))[1];
				this.registroLavado.get('reservaMiercoles1').setValue(reservaMiercoles1);
				this.registroLavado.get('reservaMiercoles12').setValue(reservaMiercoles12);
				if (horarios_reserva_miercoles[1] != undefined) {
					var reservaMiercoles2 = (horarios_reserva_miercoles[1].split("-"))[0];
					var reservaMiercoles22 = (horarios_reserva_miercoles[1].split("-"))[1];
					this.registroLavado.get('reservaMiercoles2').setValue(reservaMiercoles2);
					this.registroLavado.get('reservaMiercoles22').setValue(reservaMiercoles22);
				}
			}
			if (nodoLavado['reserva']['horarios']['jueves'] != undefined) {
				var horarios_reserva_jueves = nodoLavado['reserva']['horarios']['jueves'].split("/");
				var reservaJueves1 = (horarios_reserva_jueves[0].split("-"))[0];
				var reservaJueves12 = (horarios_reserva_jueves[0].split("-"))[1];
				this.registroLavado.get('reservaJueves1').setValue(reservaJueves1);
				this.registroLavado.get('reservaJueves12').setValue(reservaJueves12);
				if (horarios_reserva_jueves[1] != undefined) {
					var reservaJueves2 = (horarios_reserva_jueves[1].split("-"))[0];
					var reservaJueves22 = (horarios_reserva_jueves[1].split("-"))[1];
					this.registroLavado.get('reservaJueves2').setValue(reservaJueves2);
					this.registroLavado.get('reservaJueves22').setValue(reservaJueves22);
				}
			}
			if (nodoLavado['reserva']['horarios']['viernes'] != undefined) {
				var horarios_reserva_viernes = nodoLavado['reserva']['horarios']['viernes'].split("/");
				var reservaViernes1 = (horarios_reserva_viernes[0].split("-"))[0];
				var reservaViernes12 = (horarios_reserva_viernes[0].split("-"))[1];
				this.registroLavado.get('reservaViernes1').setValue(reservaViernes1);
				this.registroLavado.get('reservaViernes12').setValue(reservaViernes12);
				if (horarios_reserva_viernes[1] != undefined) {
					var reservaViernes2 = (horarios_reserva_viernes[1].split("-"))[0];
					var reservaViernes22 = (horarios_reserva_viernes[1].split("-"))[1];
					this.registroLavado.get('reservaViernes2').setValue(reservaViernes2);
					this.registroLavado.get('reservaViernes22').setValue(reservaViernes22);
				}
			}
			if (nodoLavado['reserva']['horarios']['sabado'] != undefined) {
				var horarios_reserva_sabado = nodoLavado['reserva']['horarios']['sabado'].split("/");
				var reservaSabado1 = (horarios_reserva_sabado[0].split("-"))[0];
				var reservaSabado12 = (horarios_reserva_sabado[0].split("-"))[1];
				this.registroLavado.get('reservaSabado1').setValue(reservaSabado1);
				this.registroLavado.get('reservaSabado12').setValue(reservaSabado12);
				if (horarios_reserva_sabado[1] != undefined) {
					var reservaSabado2 = (horarios_reserva_sabado[1].split("-"))[0];
					var reservaSabado22 = (horarios_reserva_sabado[1].split("-"))[1];
					this.registroLavado.get('reservaSabado2').setValue(reservaSabado2);
					this.registroLavado.get('reservaSabado22').setValue(reservaSabado22);
				}
			}
			if (nodoLavado['provee'] != undefined) {
				var servicios: Array<string> = nodoLavado['provee'].split(',');
				servicios.forEach(servicio => {
					this.serviciosSeleccionados.push(servicio);
				});
			}
			this.servicios.getImage("Lavado", nodoLavado['codigo']).subscribe(urlImagen => {
				this.imagen = urlImagen;
				this.tieneImagen = true;
			}, error => {
				if (error.code == 'storage/object-not-found') {
					this.erroresLavado.push({severity:'info', detail: "El lavado aún no tiene logo", summary:'Información'});
				}
			});
			this.servicios.getComunas(this.registroLavado.get('region').value).subscribe(comunas => {
				this.comunas = [];
				comunas.forEach(comunaNodo => {
					var comuna = comunaNodo.payload.val();
					this.comunas.push(comuna.toString().toUpperCase());
				});
			});
		});
	}

	public async modificarLavado(){
		this.erroresLavado = [];
		if (this.registroLavado.valid) {
			if (this.serviciosSeleccionados.length != 0) {
				var nuevoLavado = new LavadoModel();
				nuevoLavado.Inmediato = {lugares: this.registroLavado.get('lugaresInmediato').value};
				nuevoLavado.codigo = this.registroLavado.get('key').value;
				nuevoLavado.email = this.registroLavado.get('email').value;
				nuevoLavado.comision = this.registroLavado.get('comision').value;
				nuevoLavado.precios = {arco: this.registroLavado.get('arco').value,jet: this.registroLavado.get('jet').value,portal: this.registroLavado.get('portal').value,tradicional: this.registroLavado.get('tradicional').value};
				nuevoLavado.activo = this.activo;
				nuevoLavado.rut = this.registroLavado.get('rut').value;
				nuevoLavado.codigoPais = this.registroLavado.get('pais').value;
				nuevoLavado.comuna = this.registroLavado.get('comuna').value;
				nuevoLavado.direccion = this.registroLavado.get('direccion').value;
				nuevoLavado.eliminado = false;
				nuevoLavado.estado = this.registroLavado.get('estado').value;
				nuevoLavado.horarios = {semana: this.registroLavado.get('semana1').value + "-" + this.registroLavado.get('semana12').value, sabado: this.registroLavado.get('sabado1').value + "-" + this.registroLavado.get('sabado12').value};
				if (this.registroLavado.get('semana2').value != '') {
					nuevoLavado.horarios.semana += "/" + this.registroLavado.get('semana2').value + "-" + this.registroLavado.get('semana22').value;
				}
				if (this.registroLavado.get('sabado2').value != '') {
					nuevoLavado.horarios.sabado += "/" + this.registroLavado.get('sabado2').value + "-" + this.registroLavado.get('sabado22').value;
				}
				nuevoLavado.latitud = this.registroLavado.get('latitud').value;
				nuevoLavado.longitud = this.registroLavado.get('longitud').value;
				nuevoLavado.nombre = this.registroLavado.get('nombre').value;
				nuevoLavado.region = this.registroLavado.get('region').value;
				for (const servicio of this.serviciosSeleccionados) {
					if (nuevoLavado.provee == undefined) {
						nuevoLavado.provee = servicio.toString();
					} else {
						nuevoLavado.provee += "," + servicio;
					}
				}
				nuevoLavado.telefono = this.registroLavado.get('tel').value;
				nuevoLavado.reserva = {
					horarios: {
						jueves: this.registroLavado.get('reservaJueves1').value + "-" + this.registroLavado.get('reservaJueves12').value,
						lunes: this.registroLavado.get('reservaLunes1').value + "-" + this.registroLavado.get('reservaLunes12').value,
						martes: this.registroLavado.get('reservaMartes1').value + "-" + this.registroLavado.get('reservaMartes12').value,
						miercoles: this.registroLavado.get('reservaMiercoles1').value + "-" + this.registroLavado.get('reservaMiercoles12').value,
						sabado: this.registroLavado.get('reservaSabado1').value + "-" + this.registroLavado.get('reservaSabado12').value,
						viernes: this.registroLavado.get('reservaViernes1').value + "-" + this.registroLavado.get('reservaViernes12').value
					},
					lugares: {
						jueves: this.registroLavado.get('lugaresReservaJueves1').value,
						lunes: this.registroLavado.get('lugaresReservaLunes1').value,
						martes: this.registroLavado.get('lugaresReservaMartes1').value,
						miercoles: this.registroLavado.get('lugaresReservaMiercoles1').value,
						sabado: this.registroLavado.get('lugaresReservaSabado1').value,
						viernes: this.registroLavado.get('lugaresReservaViernes1').value
					}
				};
				if (this.registroLavado.get('reservaLunes2').value != undefined && this.registroLavado.get('reservaLunes22').value != undefined && this.registroLavado.get('lugaresReservaLunes2').value != undefined && this.registroLavado.get('reservaLunes2').value != '' && this.registroLavado.get('reservaLunes22').value != '' && this.registroLavado.get('lugaresReservaLunes2').value != '') {
					nuevoLavado.reserva.horarios.lunes += "/" + this.registroLavado.get('reservaLunes2').value + "-" + this.registroLavado.get('reservaLunes22').value;
					nuevoLavado.reserva.lugares.lunes += "/" + this.registroLavado.get('lugaresReservaLunes2').value;
				}
				if (this.registroLavado.get('reservaMartes2').value != undefined && this.registroLavado.get('reservaMartes22').value != undefined && this.registroLavado.get('lugaresReservaMartes2').value != undefined && this.registroLavado.get('reservaMartes2').value != '' && this.registroLavado.get('reservaMartes22').value != '' && this.registroLavado.get('lugaresReservaMartes2').value != '') {
					nuevoLavado.reserva.horarios.martes += "/" + this.registroLavado.get('reservaMartes2').value + "-" + this.registroLavado.get('reservaMartes22').value;
					nuevoLavado.reserva.lugares.martes += "/" + this.registroLavado.get('lugaresReservaMartes2').value;
				}
				if (this.registroLavado.get('reservaMiercoles2').value != undefined && this.registroLavado.get('reservaMiercoles22').value != undefined && this.registroLavado.get('lugaresReservaMiercoles2').value != undefined && this.registroLavado.get('reservaMiercoles2').value != '' && this.registroLavado.get('reservaMiercoles22').value != '' && this.registroLavado.get('lugaresReservaMiercoles2').value != '') {
					nuevoLavado.reserva.horarios.miercoles += "/" + this.registroLavado.get('reservaMiercoles2').value + "-" + this.registroLavado.get('reservaMiercoles22').value;
					nuevoLavado.reserva.lugares.miercoles += "/" + this.registroLavado.get('lugaresReservaMiercoles2').value;
				}
				if (this.registroLavado.get('reservaJueves2').value != undefined && this.registroLavado.get('reservaJueves22').value != undefined && this.registroLavado.get('lugaresReservaJueves2').value != undefined && this.registroLavado.get('reservaJueves2').value != '' && this.registroLavado.get('reservaJueves22').value != '' && this.registroLavado.get('lugaresReservaJueves2').value != '') {
					nuevoLavado.reserva.horarios.jueves += "/" + this.registroLavado.get('reservaJueves2').value + "-" + this.registroLavado.get('reservaJueves22').value;
					nuevoLavado.reserva.lugares.jueves += "/" + this.registroLavado.get('lugaresReservaJueves2').value;
				}
				if (this.registroLavado.get('reservaViernes2').value != undefined && this.registroLavado.get('reservaViernes22').value != undefined && this.registroLavado.get('lugaresReservaViernes2').value != undefined && this.registroLavado.get('reservaViernes2').value != '' && this.registroLavado.get('reservaViernes22').value != '' && this.registroLavado.get('lugaresReservaViernes2').value != '') {
					nuevoLavado.reserva.horarios.viernes += "/" + this.registroLavado.get('reservaViernes2').value + "-" + this.registroLavado.get('reservaViernes22').value;
					nuevoLavado.reserva.lugares.viernes += "/" + this.registroLavado.get('lugaresReservaViernes2').value;
				}
				if (this.registroLavado.get('reservaSabado2').value != undefined && this.registroLavado.get('reservaSabado22').value != undefined && this.registroLavado.get('lugaresReservaSabado2').value != undefined && this.registroLavado.get('reservaSabado2').value != '' && this.registroLavado.get('reservaSabado22').value != '' && this.registroLavado.get('lugaresReservaSabado2').value != '') {
					nuevoLavado.reserva.horarios.sabado += "/" + this.registroLavado.get('reservaSabado2').value + "-" + this.registroLavado.get('reservaSabado22').value;
					nuevoLavado.reserva.lugares.sabado += "/" + this.registroLavado.get('lugaresReservaSabado2').value;
				}
				if (this.tieneImagen) {
					var nodos = await this.servicios.updateLavado(nuevoLavado).then(resultado => {
						this.erroresLavado.push({severity:'success', detail: "Lavado " + nuevoLavado.codigo + " modificado con éxito", summary:'Lavado modificado'});
					}).catch(error => {
					});
				} else {
					var nodos = await this.servicios.updateLavado(nuevoLavado, this.imagen).then(resultado => {
						this.erroresLavado.push({severity:'success', detail: "Lavado " + nuevoLavado.codigo + " modificado con éxito", summary:'Lavado modificado'});
					}).catch(error => {
					});
				}
			} else {
				this.erroresLavado.push({severity:'error', detail:'¡Seleccione los servicios que atiende el Lavado!', summary:'Error'});
			}
		} else {
			this.erroresLavado.push({severity:'error', detail:'¡Rellene todos los campos requeridos!', summary:'Error'});
		}
	}

	public cambioActivo(){
		this.erroresLavado = [];
		this.servicios.updateActivoLavado(this.registroLavado.get('key').value, this.activo).then(resultado => {
			if (this.activo) {
				this.erroresLavado.push({severity:'success', detail: "Activaste el Lavado", summary:'Lavado activado'});
			} else {
				this.erroresLavado.push({severity:'success', detail: "Desactivaste el Lavado", summary:'Lavado desactivado'});
			}
		}).catch(error => {
			this.erroresLavado.push({severity:'error', detail: "Error de servidor", summary:'Error interno'});
		});
	}

	public getComunas(){
		this.servicios.getComunas(this.registroLavado.get('region').value).subscribe(comunas => {
			this.comunas = [];
			if (comunas[0] != undefined) {
				this.registroLavado.get('comuna').setValue(comunas[0].payload.val().toString().toUpperCase());
			}
			comunas.forEach(comunaNodo => {
				var comuna = comunaNodo.payload.val();
				this.comunas.push(comuna.toString().toUpperCase());
			});
		});
	}

	public getError(controlName: string): string {
		let error = '';
		const control = this.registroLavado.get(controlName);
		if (control.touched && control.errors != null) {
			error = JSON.stringify(control.errors);
		}
		if (error.indexOf('requiredPattern')>-1) {
			error = "Campo incorrecto"
		}
		if (error.indexOf('minlength')>-1) {
			error = "Contraseña débil";
		}
		if (error.indexOf('"required":true')>-1) {
			error = "Campo requerido";
		}
		if (error.indexOf('"email":true')>-1) {
			error = "Formato de dirección email incorrecto";
		}
		return error;
	}

	setImagen(event){
		this.imagenUrl = event.target.files[0];

		var reader = new FileReader();
		this.imagePath = this.imagenUrl;
		reader.readAsDataURL(this.imagePath); 
		reader.onload = (_event) => { 
		
		  this.imagen = reader.result; 
		}

		this.servicios.uploadImageLavado(this.codigoLavado, this.imagenUrl);

	}
}
