export class SucursalModel {
	codigo: string;
	rut: string;
	codigoPais: string;
	comuna: string;
	direccion: string;
	estado: string;
	nombre: string;
	region: string;
	telefono: string;
	imagen: any = undefined;
	comision: any;
	servicio: any;
	calificacion: number;

	constructor(){
	}
}
