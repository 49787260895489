import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';

import { PRTModel } from '../../../models/prt-model';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';

@Component({
	selector: 'app-update-prt',
	templateUrl: './update-prt.component.html',
	styleUrls: ['./update-prt.component.scss']
})
export class UpdatePrtComponent implements OnInit {

	public registroPRT: FormGroup;
	public erroresPRT:  Message[] = [];

	public imagePath;
	public paises: Array<{nombre: string, codigo: string}> = new Array();
	public regiones: Array<{nombre: string}> = new Array();
	public comunas: Array<string> = new Array();
	public certificados: Array<SelectItem> = new Array();
	public proveeCertificados: Array<SelectItem> = new Array();

	public codigoPRT: string = '';

	private subscripcion: any;
	private prtOFF: any = '';

	private item1: SelectItem = {label: 'A1', value: 'A1'};
	private item2: SelectItem = {label: 'A2', value: 'A2'};
	private item3: SelectItem = {label: 'B', value: 'B'};

	public activo = false;
	public imagen = undefined;
	public imagenUrl = "";
	public tieneImagen = false;

	public isProveedor = "0";

	public certificadosSeleccionados: Array<string> = new Array();

	constructor(private servicios: FirebaseService, private router: Router,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		public formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit() {
		var isProveedor = sessionStorage.getItem("is_proveedor");
		if (isProveedor != null && isProveedor != undefined) {
			this.isProveedor = isProveedor;
			sessionStorage.removeItem("is_proveedor");
		}
		this.codigoPRT = this.activatedRoute.snapshot.paramMap.get("id");
		if (this.codigoPRT == undefined || this.codigoPRT == null) {
			this.router.navigate(['']);
		}
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				}
			});
		}
		this.registroPRT = this.formBuilder.group({
			email: new FormControl('', [Validators.required, Validators.email]),
			nombre: new FormControl('', [Validators.required]),
			rut: new FormControl('', [Validators.required]),
			key: new FormControl('', [Validators.required]),
			tel: new FormControl('', [Validators.required]),
			latitud: new FormControl('', [Validators.required]),
			longitud: new FormControl('', [Validators.required]),
			pais: new FormControl('', [Validators.required]),
			region: new FormControl('', [Validators.required]),
			comuna: new FormControl('', [Validators.required]),
			comision: new FormControl('', [Validators.required]),
			direccion: new FormControl('', [Validators.required]),
			semana1: new FormControl('', [Validators.required]),
			semana12: new FormControl('', [Validators.required]),
			semana2: new FormControl(''),
			semana22: new FormControl(''),
			sabado1: new FormControl('', [Validators.required]),
			sabado12: new FormControl('', [Validators.required]),
			sabado2: new FormControl(''),
			sabado22: new FormControl(''),
			lugaresInmediato: new FormControl('', [Validators.required]),
			lugaresReservaLunes1: new FormControl(''),
			reservaLunes1: new FormControl(''),
			reservaLunes12: new FormControl(''),
			lugaresReservaMartes1: new FormControl(''),
			reservaMartes1: new FormControl(''),
			reservaMartes12: new FormControl(''),
			lugaresReservaMiercoles1: new FormControl(''),
			reservaMiercoles1: new FormControl(''),
			reservaMiercoles12: new FormControl(''),
			lugaresReservaJueves1: new FormControl(''),
			reservaJueves1: new FormControl(''),
			reservaJueves12: new FormControl(''),
			lugaresReservaViernes1: new FormControl(''),
			reservaViernes1: new FormControl(''),
			reservaViernes12: new FormControl(''),
			lugaresReservaSabado1: new FormControl(''),
			reservaSabado1: new FormControl(''),
			reservaSabado12: new FormControl(''),
			lugaresReservaLunes2: new FormControl(''),
			reservaLunes2: new FormControl(''),
			reservaLunes22: new FormControl(''),
			lugaresReservaMartes2: new FormControl(''),
			reservaMartes2: new FormControl(''),
			reservaMartes22: new FormControl(''),
			lugaresReservaMiercoles2: new FormControl(''),
			reservaMiercoles2: new FormControl(''),
			reservaMiercoles22: new FormControl(''),
			lugaresReservaJueves2: new FormControl(''),
			reservaJueves2: new FormControl(''),
			reservaJueves22: new FormControl(''),
			lugaresReservaViernes2: new FormControl(''),
			reservaViernes2: new FormControl(''),
			reservaViernes22: new FormControl(''),
			lugaresReservaSabado2: new FormControl(''),
			reservaSabado2: new FormControl(''),
			reservaSabado22: new FormControl(''),
			estado: new FormControl(''),
			activo: new FormControl(''),
			A1: new FormControl(''),
			A2: new FormControl(''),
			B: new FormControl(''),
		});
		this.servicios.getPaises().subscribe(paises => {
			this.paises = [];
			paises.forEach(paisNodo => {
				this.paises.push({nombre: paisNodo.payload.val()['nombre'], codigo: paisNodo.key});
			});
		});
		this.servicios.getRegiones().subscribe(regiones => {
			this.regiones = [];
			regiones.forEach(regionNodo => {
				var region = regionNodo.payload.val();
				this.regiones.push({nombre: region['nombre']});
			});
		});
		this.certificados.push(this.item1);
		this.certificados.push(this.item2);
		this.certificados.push(this.item3);
		var subscriptor = this.servicios.getPRT(this.codigoPRT).subscribe(resultado => {
			subscriptor.unsubscribe();
			var nodoPRT = resultado.payload.val();
			this.registroPRT.get('email').setValue(nodoPRT['email']);
			this.registroPRT.get('nombre').setValue(nodoPRT['nombre']);
			this.registroPRT.get('rut').setValue(nodoPRT['rut']);
			this.registroPRT.get('key').setValue(nodoPRT['codigo']);
			this.registroPRT.get('tel').setValue(nodoPRT['telefono']);
			this.registroPRT.get('latitud').setValue(nodoPRT['latitud']);
			this.registroPRT.get('longitud').setValue(nodoPRT['longitud']);
			this.registroPRT.get('pais').setValue(nodoPRT['codigoPais']);
			this.registroPRT.get('estado').setValue(nodoPRT['estado']);
			this.registroPRT.get('comision').setValue(nodoPRT['comision']);
			this.activo = nodoPRT['activo'];
			this.registroPRT.get('region').setValue(nodoPRT['region']);
			this.registroPRT.get('comuna').setValue(nodoPRT['comuna']);
			this.registroPRT.get('direccion').setValue(nodoPRT['direccion']);
			if (nodoPRT['precios']['A1'] != undefined)
				this.registroPRT.get('A1').setValue(nodoPRT['precios']['A1']);
			if (nodoPRT['precios']['A2'] != undefined)
				this.registroPRT.get('A2').setValue(nodoPRT['precios']['A2']);
			if (nodoPRT['precios']['B'] != undefined)
				this.registroPRT.get('B').setValue(nodoPRT['precios']['B']);
			this.registroPRT.get('lugaresInmediato').setValue(nodoPRT['Inmediato']['lugares']);
			var horarios_semana = nodoPRT['horarios']['semana'].split("/");
			var semana1 = (horarios_semana[0].split("-"))[0];
			var semana12 = (horarios_semana[0].split("-"))[1];
			this.registroPRT.get('semana1').setValue(semana1);
			this.registroPRT.get('semana12').setValue(semana12);
			if (horarios_semana[1] != undefined) {
				var semana2 = (horarios_semana[1].split("-"))[0];
				var semana22 = (horarios_semana[1].split("-"))[1];
				this.registroPRT.get('semana2').setValue(semana2);
				this.registroPRT.get('semana22').setValue(semana22);
			}
			var horarios_sabado = nodoPRT['horarios']['sabado'].split("/");
			var sabado1 = (horarios_sabado[0].split("-"))[0];
			var sabado12 = (horarios_sabado[0].split("-"))[1];
			this.registroPRT.get('sabado1').setValue(sabado1);
			this.registroPRT.get('sabado12').setValue(sabado12);
			if (horarios_sabado[1] != undefined) {
				var sabado2 = (horarios_sabado[1].split("-"))[0];
				var sabado22 = (horarios_sabado[1].split("-"))[1];
				this.registroPRT.get('sabado2').setValue(sabado2);
				this.registroPRT.get('sabado22').setValue(sabado22);
			}
			if (nodoPRT['reserva']['lugares']['lunes'] != undefined) {
				if (nodoPRT['reserva']['lugares']['lunes'].toString().indexOf("/") > -1 ) {
					var lugares_lunes = nodoPRT['reserva']['lugares']['lunes'].split("/");
					this.registroPRT.get('lugaresReservaLunes1').setValue(lugares_lunes[0]);
					this.registroPRT.get('lugaresReservaLunes2').setValue(lugares_lunes[1]);
				} else
					this.registroPRT.get('lugaresReservaLunes1').setValue(nodoPRT['reserva']['lugares']['lunes']);
			}
			if (nodoPRT['reserva']['lugares']['martes'] != undefined) {
				if (nodoPRT['reserva']['lugares']['martes'].toString().indexOf("/") > -1 ) {
					var lugares_martes = nodoPRT['reserva']['lugares']['martes'].split("/");
					this.registroPRT.get('lugaresReservaMartes1').setValue(lugares_martes[0]);
					this.registroPRT.get('lugaresReservaMartes2').setValue(lugares_martes[1]);
				} else
					this.registroPRT.get('lugaresReservaMartes1').setValue(nodoPRT['reserva']['lugares']['martes']);
			}
			if (nodoPRT['reserva']['lugares']['miercoles'] != undefined) {
				if (nodoPRT['reserva']['lugares']['miercoles'].toString().indexOf("/") > -1 ) {
					var lugares_miercoles = nodoPRT['reserva']['lugares']['miercoles'].split("/");
					this.registroPRT.get('lugaresReservaMiercoles1').setValue(lugares_miercoles[0]);
					this.registroPRT.get('lugaresReservaMiercoles2').setValue(lugares_miercoles[1]);
				} else
					this.registroPRT.get('lugaresReservaMiercoles1').setValue(nodoPRT['reserva']['lugares']['miercoles']);
			}
			if (nodoPRT['reserva']['lugares']['jueves'] != undefined) {
				if (nodoPRT['reserva']['lugares']['jueves'].toString().indexOf("/") > -1 ) {
					var lugares_jueves = nodoPRT['reserva']['lugares']['jueves'].split("/");
					this.registroPRT.get('lugaresReservaJueves1').setValue(lugares_jueves[0]);
					this.registroPRT.get('lugaresReservaJueves2').setValue(lugares_jueves[1]);
				} else
					this.registroPRT.get('lugaresReservaJueves1').setValue(nodoPRT['reserva']['lugares']['jueves']);
			}
			if (nodoPRT['reserva']['lugares']['viernes'] != undefined) {
				if (nodoPRT['reserva']['lugares']['viernes'].toString().indexOf("/") > -1 ) {
				var lugares_viernes = nodoPRT['reserva']['lugares']['viernes'].split("/");
				this.registroPRT.get('lugaresReservaViernes1').setValue(lugares_viernes[0]);
				this.registroPRT.get('lugaresReservaViernes2').setValue(lugares_viernes[1]);
				} else
					this.registroPRT.get('lugaresReservaViernes1').setValue(nodoPRT['reserva']['lugares']['viernes']);
			}
			if (nodoPRT['reserva']['lugares']['sabado'] != undefined) {
				if (nodoPRT['reserva']['lugares']['sabado'].toString().indexOf("/") > -1 ) {
					var lugares_sabado = nodoPRT['reserva']['lugares']['sabado'].split("/");
					this.registroPRT.get('lugaresReservaSabado1').setValue(lugares_sabado[0]);
					this.registroPRT.get('lugaresReservaSabado2').setValue(lugares_sabado[1]);
				} else
					this.registroPRT.get('lugaresReservaSabado1').setValue(nodoPRT['reserva']['lugares']['sabado']);
			}
			if (nodoPRT['reserva']['horarios']['lunes'] != undefined) {
				var horarios_reserva_lunes = nodoPRT['reserva']['horarios']['lunes'].split("/");
				var reservaLunes1 = (horarios_reserva_lunes[0].split("-"))[0];
				var reservaLunes12 = (horarios_reserva_lunes[0].split("-"))[1];
				this.registroPRT.get('reservaLunes1').setValue(reservaLunes1);
				this.registroPRT.get('reservaLunes12').setValue(reservaLunes12);
				if (horarios_reserva_lunes[1] != undefined) {
					var reservaLunes2 = (horarios_reserva_lunes[1].split("-"))[0];
					var reservaLunes22 = (horarios_reserva_lunes[1].split("-"))[1];
					this.registroPRT.get('reservaLunes2').setValue(reservaLunes2);
					this.registroPRT.get('reservaLunes22').setValue(reservaLunes22);
				}
			}
			if (nodoPRT['reserva']['horarios']['martes'] != undefined) {
				var horarios_reserva_martes = nodoPRT['reserva']['horarios']['martes'].split("/");
				var reservaMartes1 = (horarios_reserva_martes[0].split("-"))[0];
				var reservaMartes12 = (horarios_reserva_martes[0].split("-"))[1];
				this.registroPRT.get('reservaMartes1').setValue(reservaMartes1);
				this.registroPRT.get('reservaMartes12').setValue(reservaMartes12);
				if (horarios_reserva_martes[1] != undefined) {
					var reservaMartes2 = (horarios_reserva_martes[1].split("-"))[0];
					var reservaMartes22 = (horarios_reserva_martes[1].split("-"))[1];
					this.registroPRT.get('reservaMartes2').setValue(reservaMartes2);
					this.registroPRT.get('reservaMartes22').setValue(reservaMartes22);
				}
			}
			if (nodoPRT['reserva']['horarios']['miercoles'] != undefined) {
				var horarios_reserva_miercoles = nodoPRT['reserva']['horarios']['miercoles'].split("/");
				var reservaMiercoles1 = (horarios_reserva_miercoles[0].split("-"))[0];
				var reservaMiercoles12 = (horarios_reserva_miercoles[0].split("-"))[1];
				this.registroPRT.get('reservaMiercoles1').setValue(reservaMiercoles1);
				this.registroPRT.get('reservaMiercoles12').setValue(reservaMiercoles12);
				if (horarios_reserva_miercoles[1] != undefined) {
					var reservaMiercoles2 = (horarios_reserva_miercoles[1].split("-"))[0];
					var reservaMiercoles22 = (horarios_reserva_miercoles[1].split("-"))[1];
					this.registroPRT.get('reservaMiercoles2').setValue(reservaMiercoles2);
					this.registroPRT.get('reservaMiercoles22').setValue(reservaMiercoles22);
				}
			}
			if (nodoPRT['reserva']['horarios']['jueves'] != undefined) {
				var horarios_reserva_jueves = nodoPRT['reserva']['horarios']['jueves'].split("/");
				var reservaJueves1 = (horarios_reserva_jueves[0].split("-"))[0];
				var reservaJueves12 = (horarios_reserva_jueves[0].split("-"))[1];
				this.registroPRT.get('reservaJueves1').setValue(reservaJueves1);
				this.registroPRT.get('reservaJueves12').setValue(reservaJueves12);
				if (horarios_reserva_jueves[1] != undefined) {
					var reservaJueves2 = (horarios_reserva_jueves[1].split("-"))[0];
					var reservaJueves22 = (horarios_reserva_jueves[1].split("-"))[1];
					this.registroPRT.get('reservaJueves2').setValue(reservaJueves2);
					this.registroPRT.get('reservaJueves22').setValue(reservaJueves22);
				}
			}
			if (nodoPRT['reserva']['horarios']['viernes'] != undefined) {
				var horarios_reserva_viernes = nodoPRT['reserva']['horarios']['viernes'].split("/");
				var reservaViernes1 = (horarios_reserva_viernes[0].split("-"))[0];
				var reservaViernes12 = (horarios_reserva_viernes[0].split("-"))[1];
				this.registroPRT.get('reservaViernes1').setValue(reservaViernes1);
				this.registroPRT.get('reservaViernes12').setValue(reservaViernes12);
				if (horarios_reserva_viernes[1] != undefined) {
					var reservaViernes2 = (horarios_reserva_viernes[1].split("-"))[0];
					var reservaViernes22 = (horarios_reserva_viernes[1].split("-"))[1];
					this.registroPRT.get('reservaViernes2').setValue(reservaViernes2);
					this.registroPRT.get('reservaViernes22').setValue(reservaViernes22);
				}
			}
			if (nodoPRT['reserva']['horarios']['sabado'] != undefined) {
				var horarios_reserva_sabado = nodoPRT['reserva']['horarios']['sabado'].split("/");
				var reservaSabado1 = (horarios_reserva_sabado[0].split("-"))[0];
				var reservaSabado12 = (horarios_reserva_sabado[0].split("-"))[1];
				this.registroPRT.get('reservaSabado1').setValue(reservaSabado1);
				this.registroPRT.get('reservaSabado12').setValue(reservaSabado12);
				if (horarios_reserva_sabado[1] != undefined) {
					var reservaSabado2 = (horarios_reserva_sabado[1].split("-"))[0];
					var reservaSabado22 = (horarios_reserva_sabado[1].split("-"))[1];
					this.registroPRT.get('reservaSabado2').setValue(reservaSabado2);
					this.registroPRT.get('reservaSabado22').setValue(reservaSabado22);
				}
			}
			if (nodoPRT['provee'] != undefined) {
				var servicios: Array<string> = nodoPRT['provee'].split(',');
				servicios.forEach(servicio => {
					this.certificadosSeleccionados.push(servicio);
				});
			}
			this.servicios.getImage("PRT", nodoPRT['codigo']).subscribe(urlImagen => {
				this.imagen = urlImagen;
				this.tieneImagen = true;
			}, error => {
				if (error.code == 'storage/object-not-found') {
					this.erroresPRT.push({severity:'info', detail: "La PRT aún no tiene logo", summary:'Información'});
				}
			});
			this.servicios.getComunas(this.registroPRT.get('region').value).subscribe(comunas => {
				this.comunas = [];
				comunas.forEach(comunaNodo => {
					var comuna = comunaNodo.payload.val();
					this.comunas.push(comuna.toString().toUpperCase());
				});
			});
		});
	}

	public async modificarPRT(){
		this.erroresPRT = [];
		if (this.registroPRT.valid) {
			if (this.certificadosSeleccionados.length != 0) {
				var nuevaPRT = new PRTModel();
				nuevaPRT.Inmediato = {lugares: this.registroPRT.get('lugaresInmediato').value};
				nuevaPRT.codigo = this.registroPRT.get('key').value;
				nuevaPRT.email = this.registroPRT.get('email').value;
				nuevaPRT.comision = this.registroPRT.get('comision').value;
				nuevaPRT.precios = {A1: this.registroPRT.get('A1').value, B: this.registroPRT.get('B').value, A2: this.registroPRT.get('A2').value};
				nuevaPRT.activo = this.activo;
				nuevaPRT.rut = this.registroPRT.get('rut').value;
				nuevaPRT.codigoPais = this.registroPRT.get('pais').value;
				nuevaPRT.comuna = this.registroPRT.get('comuna').value;
				nuevaPRT.direccion = this.registroPRT.get('direccion').value;
				nuevaPRT.eliminado = false;
				nuevaPRT.estado = this.registroPRT.get('estado').value;
				nuevaPRT.horarios = {semana: this.registroPRT.get('semana1').value + "-" + this.registroPRT.get('semana12').value, sabado: this.registroPRT.get('sabado1').value + "-" + this.registroPRT.get('sabado12').value};
				if (this.registroPRT.get('semana2').value != '') {
					nuevaPRT.horarios.semana += "/" + this.registroPRT.get('semana2').value + "-" + this.registroPRT.get('semana22').value;
				}
				if (this.registroPRT.get('sabado2').value != '') {
					nuevaPRT.horarios.sabado += "/" + this.registroPRT.get('sabado2').value + "-" + this.registroPRT.get('sabado22').value;
				}
				nuevaPRT.latitud = this.registroPRT.get('latitud').value;
				nuevaPRT.longitud = this.registroPRT.get('longitud').value;
				nuevaPRT.nombre = this.registroPRT.get('nombre').value;
				nuevaPRT.region = this.registroPRT.get('region').value;
				for (const certificado of this.certificadosSeleccionados) {
					if (nuevaPRT.provee == undefined) {
						nuevaPRT.provee = certificado.toString();
					} else {
						nuevaPRT.provee += "," + certificado;
					}
				}
				nuevaPRT.telefono = this.registroPRT.get('tel').value;
				nuevaPRT.reserva = {
					horarios: {
						jueves: this.registroPRT.get('reservaJueves1').value + "-" + this.registroPRT.get('reservaJueves12').value,
						lunes: this.registroPRT.get('reservaLunes1').value + "-" + this.registroPRT.get('reservaLunes12').value,
						martes: this.registroPRT.get('reservaMartes1').value + "-" + this.registroPRT.get('reservaMartes12').value,
						miercoles: this.registroPRT.get('reservaMiercoles1').value + "-" + this.registroPRT.get('reservaMiercoles12').value,
						sabado: this.registroPRT.get('reservaSabado1').value + "-" + this.registroPRT.get('reservaSabado12').value,
						viernes: this.registroPRT.get('reservaViernes1').value + "-" + this.registroPRT.get('reservaViernes12').value
					},
					lugares: {
						jueves: this.registroPRT.get('lugaresReservaJueves1').value,
						lunes: this.registroPRT.get('lugaresReservaLunes1').value,
						martes: this.registroPRT.get('lugaresReservaMartes1').value,
						miercoles: this.registroPRT.get('lugaresReservaMiercoles1').value,
						sabado: this.registroPRT.get('lugaresReservaSabado1').value,
						viernes: this.registroPRT.get('lugaresReservaViernes1').value
					}
				};
				if (this.registroPRT.get('reservaLunes2').value != undefined && this.registroPRT.get('reservaLunes22').value != undefined && this.registroPRT.get('lugaresReservaLunes2').value != undefined && this.registroPRT.get('reservaLunes2').value != '' && this.registroPRT.get('reservaLunes22').value != '' && this.registroPRT.get('lugaresReservaLunes2').value != '') {
					nuevaPRT.reserva.horarios.lunes += "/" + this.registroPRT.get('reservaLunes2').value + "-" + this.registroPRT.get('reservaLunes22').value;
					nuevaPRT.reserva.lugares.lunes += "/" + this.registroPRT.get('lugaresReservaLunes2').value;
				}
				if (this.registroPRT.get('reservaMartes2').value != undefined && this.registroPRT.get('reservaMartes22').value != undefined && this.registroPRT.get('lugaresReservaMartes2').value != undefined && this.registroPRT.get('reservaMartes2').value != '' && this.registroPRT.get('reservaMartes22').value != '' && this.registroPRT.get('lugaresReservaMartes2').value != '') {
					nuevaPRT.reserva.horarios.martes += "/" + this.registroPRT.get('reservaMartes2').value + "-" + this.registroPRT.get('reservaMartes22').value;
					nuevaPRT.reserva.lugares.martes += "/" + this.registroPRT.get('lugaresReservaMartes2').value;
				}
				if (this.registroPRT.get('reservaMiercoles2').value != undefined && this.registroPRT.get('reservaMiercoles22').value != undefined && this.registroPRT.get('lugaresReservaMiercoles2').value != undefined && this.registroPRT.get('reservaMiercoles2').value != '' && this.registroPRT.get('reservaMiercoles22').value != '' && this.registroPRT.get('lugaresReservaMiercoles2').value != '') {
					nuevaPRT.reserva.horarios.miercoles += "/" + this.registroPRT.get('reservaMiercoles2').value + "-" + this.registroPRT.get('reservaMiercoles22').value;
					nuevaPRT.reserva.lugares.miercoles += "/" + this.registroPRT.get('lugaresReservaMiercoles2').value;
				}
				if (this.registroPRT.get('reservaJueves2').value != undefined && this.registroPRT.get('reservaJueves22').value != undefined && this.registroPRT.get('lugaresReservaJueves2').value != undefined && this.registroPRT.get('reservaJueves2').value != '' && this.registroPRT.get('reservaJueves22').value != '' && this.registroPRT.get('lugaresReservaJueves2').value != '') {
					nuevaPRT.reserva.horarios.jueves += "/" + this.registroPRT.get('reservaJueves2').value + "-" + this.registroPRT.get('reservaJueves22').value;
					nuevaPRT.reserva.lugares.jueves += "/" + this.registroPRT.get('lugaresReservaJueves2').value;
				}
				if (this.registroPRT.get('reservaViernes2').value != undefined && this.registroPRT.get('reservaViernes22').value != undefined && this.registroPRT.get('lugaresReservaViernes2').value != undefined && this.registroPRT.get('reservaViernes2').value != '' && this.registroPRT.get('reservaViernes22').value != '' && this.registroPRT.get('lugaresReservaViernes2').value != '') {
					nuevaPRT.reserva.horarios.viernes += "/" + this.registroPRT.get('reservaViernes2').value + "-" + this.registroPRT.get('reservaViernes22').value;
					nuevaPRT.reserva.lugares.viernes += "/" + this.registroPRT.get('lugaresReservaViernes2').value;
				}
				if (this.registroPRT.get('reservaSabado2').value != undefined && this.registroPRT.get('reservaSabado22').value != undefined && this.registroPRT.get('lugaresReservaSabado2').value != undefined && this.registroPRT.get('reservaSabado2').value != '' && this.registroPRT.get('reservaSabado22').value != '' && this.registroPRT.get('lugaresReservaSabado2').value != '') {
					nuevaPRT.reserva.horarios.sabado += "/" + this.registroPRT.get('reservaSabado2').value + "-" + this.registroPRT.get('reservaSabado22').value;
					nuevaPRT.reserva.lugares.sabado += "/" + this.registroPRT.get('lugaresReservaSabado2').value;
				}
				if (this.tieneImagen) {
					var nodos = await this.servicios.updatePRT(nuevaPRT).then(resultado => {
						this.erroresPRT.push({severity:'success', detail: "PRT " + nuevaPRT.codigo + " modificada con éxito", summary:'PRT modificada'});
					}).catch(error => {
					});
				} else {
					var nodos = await this.servicios.updatePRT(nuevaPRT, this.imagen).then(resultado => {
						this.erroresPRT.push({severity:'success', detail: "PRT " + nuevaPRT.codigo + " modificada con éxito", summary:'PRT modificada'});
					}).catch(error => {
					});
				}
			} else {
				this.erroresPRT.push({severity:'error', detail:'¡Seleccione los certificados que atiende la PRT!', summary:'Error'});
			}
		} else {
			this.erroresPRT.push({severity:'error', detail:'¡Rellene todos los campos requeridos!', summary:'Error'});
		}
	}

	public cambioActivo(){
		this.erroresPRT = [];
		this.servicios.updateActivoPRT(this.registroPRT.get('key').value, this.activo).then(resultado => {
			if (this.activo) {
				this.erroresPRT.push({severity:'success', detail: "Activaste la PRT", summary:'PRT activada'});
			} else {
				this.erroresPRT.push({severity:'success', detail: "Desactivaste la PRT", summary:'PRT desactivada'});
			}
		}).catch(error => {
			this.erroresPRT.push({severity:'error', detail: "Error de servidor", summary:'Error interno'});
		});
	}

	public getComunas(){
		this.servicios.getComunas(this.registroPRT.get('region').value).subscribe(comunas => {
			this.comunas = [];
			if (comunas[0] != undefined) {
				this.registroPRT.get('comuna').setValue(comunas[0].payload.val().toString().toUpperCase());
			}
			comunas.forEach(comunaNodo => {
				var comuna = comunaNodo.payload.val();
				this.comunas.push(comuna.toString().toUpperCase());
			});
		});
	}

	public getError(controlName: string): string {
		let error = '';
		const control = this.registroPRT.get(controlName);
		if (control.touched && control.errors != null) {
			error = JSON.stringify(control.errors);
		}
		if (error.indexOf('requiredPattern')>-1) {
			error = "Campo incorrecto"
		}
		if (error.indexOf('minlength')>-1) {
			error = "Contraseña débil";
		}
		if (error.indexOf('"required":true')>-1) {
			error = "Campo requerido";
		}
		if (error.indexOf('"email":true')>-1) {
			error = "Formato de dirección email incorrecto";
		}
		return error;
	}

	setImagen(event){
		
		this.imagenUrl =event.target.files[0];

		var reader = new FileReader();
		this.imagePath = this.imagenUrl;
		reader.readAsDataURL(this.imagePath); 
		reader.onload = (_event) => { 
		
		  this.imagen = reader.result; 
		}

		this.servicios.uploadImagePRT(this.codigoPRT, this.imagenUrl);
	}
}
