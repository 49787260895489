import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { User } from 'firebase/app';

import { RolesUser } from '../../enumerados/roles-user-enum';

import { UserModel } from '../../models/user-model';
import { MarcaModel } from '../../models/marca-model';
import { ModeloModel } from '../../models/modelo-model';
import { TallerModel } from '../../models/taller-model';
import { PRTModel } from '../../models/prt-model';
import { LavadoModel } from '../../models/lavado-model';
import { GruaModel } from '../../models/grua-model';
import { SucursalModel } from '../../models/sucursal-model';
import { ProveedorModel } from '../../models/proveedor-model';
import { GrueroModel } from '../../models/gruero-model';
import { Observable } from 'rxjs';

const PATH_NEGOCIO = '/Negocio/';
const PATH_USUARIOS = '/Usuarios/';
const PATH_ORDENES = '/Ordenes/';
const PATH_RETIROS = '/Retiros/';
const PATH_INVITACIONES_OPERADORES = '/Invitacion/Operador/';

const PATH_PROMOCIONES = '/Promociones/';

const PATH_PAISES = '/Configuracion/Pais/';
const PATH_REGIONES = '/Configuracion/Region/';
const PATH_COMUNAS = '/Configuracion/Comuna/';
const PATH_BANCOS = '/Configuracion/Banco/';
const PATH_DIAS_HABILES = '/Configuracion/diasHabiles/';

const PATH_MARCAS = '/Configuracion/Marcas/';
const PATH_MODELOS = '/Configuracion/Modelos/';
const PATH_TIPO_CUIDADO = PATH_NEGOCIO + 'Grua/CostosIniciales/';

const PATH_TALLER_SUCURSALES = PATH_NEGOCIO + 'Taller/Sucursales/';
const PATH_SERVICIOS_TALLER = PATH_NEGOCIO + 'Taller/Servicios/';
const PATH_IMAGEN_TALLER = PATH_NEGOCIO + 'Taller/Patrocinio/';
const PATH_IMAGEN_PROVEEDOR = PATH_NEGOCIO + 'Proveedor/Patrocinio/';

const PATH_PRT_SUCURSALES = PATH_NEGOCIO + 'PRT/Sucursales/';
const PATH_IMAGEN_PRT = PATH_NEGOCIO + 'PRT/Patrocinio/';
const PATH_LAVADOS_SUCURSALES = PATH_NEGOCIO + 'Lavado/Sucursales/';
const PATH_IMAGEN_LAVADO = PATH_NEGOCIO + 'Lavado/Patrocinio/';
const PATH_GRUA_SUCURSALES = PATH_NEGOCIO + 'Grua/Sucursales/';
const PATH_IMAGEN_GRUA = PATH_NEGOCIO + 'Grua/Patrocinio/';

const PATH_TALLER_SUCURSALESOFF = PATH_NEGOCIO + 'Taller/SucursalesOFF/';
const PATH_PRT_SUCURSALESOFF = PATH_NEGOCIO + 'PRT/SucursalesOFF/';
const PATH_LAVADO_SUCURSALESOFF = PATH_NEGOCIO + 'Lavado/SucursalesOFF/';
const PATH_GRUA_SUCURSALESOFF = PATH_NEGOCIO + 'Grua/SucursalesOFF/';
// const PATH_PRT_SUCURSALES = '/Negocio/PRT/Sucursales';
// const PATH_LAVADO_SUCURSALES = '/Negocio/Lavado/Sucursales';



@Injectable({
	providedIn: 'root'
})
export class FirebaseService {

	public user: UserModel = null;
	public sucursal: SucursalModel = null;

	constructor(private angularFireAuth: AngularFireAuth,
		private angularFireDatabase: AngularFireDatabase,
		private angularFireStorage: AngularFireStorage) {}

	login(user: UserModel){
		return this.angularFireAuth.auth.signInWithEmailAndPassword(user.email, user.password);
	}

    recuperarContrasena(email:string){		
        return this.angularFireAuth.auth.sendPasswordResetEmail(email);
    }

	logOut(){
		this.user = null;
		this.sucursal = null;
		return this.angularFireAuth.auth.signOut();
	}

	async registro(user){
		await this.angularFireAuth.auth.createUserWithEmailAndPassword(user.email, user.password);
		var usuarios: AngularFireList<any> = this.angularFireDatabase.list("/Usuarios");
		usuarios.push(user);
	}

	verificarSesion(){
		return this.angularFireAuth.authState;
	}

	getCuenta(uid){
		return this.angularFireDatabase.object(PATH_USUARIOS + uid).valueChanges();
	}

	async verificarCuenta(user: User){
		var usuario: AngularFireObject<any> = this.angularFireDatabase.object(PATH_USUARIOS + user.uid);
		if (usuario == undefined) {
			throw "No existe cuenta";
		} else {
			return usuario;
		}
	}

	async crearCuentaFirebase(): Promise<any> {
	    /* Función que crea una cuenta de usuario en Firebase con solo
	    ingresar Email y password  */
	    var usuario: AngularFireObject<any> = this.angularFireDatabase.object(PATH_USUARIOS + this.user.uid);
	    usuario.set(this.user);
	}

	public getPaises(){
		return this.angularFireDatabase.list(PATH_PAISES).snapshotChanges();
	}

	public getTipoCuidado(){
		return this.angularFireDatabase.list(PATH_TIPO_CUIDADO).snapshotChanges();
	}

	public getRegiones(){
		return this.angularFireDatabase.list(PATH_REGIONES).snapshotChanges();
	}

	public getComunas(region){
		return this.angularFireDatabase.list(PATH_COMUNAS + region).snapshotChanges();
	}
	
	public getBancos(){
		return this.angularFireDatabase.database.ref(PATH_BANCOS).orderByChild("nombre").once("value");
	}

	public agregarBanco(nombre: string) {
		return this.angularFireDatabase.database.ref(PATH_BANCOS).push({ nombre:nombre });
	  }
	 
	  public actualizarBanco(id: string, nombre: string) {
		return this.angularFireDatabase.database.ref(PATH_BANCOS + id).update({ nombre: nombre });
	  }
 
	  public deleteBanco(id: string) {
		return this.angularFireDatabase.database.ref(PATH_BANCOS + id).remove();
		//return this.angularFireDatabase.list("/Configuracion/Banco/" + id).remove();
	  }
	  
	  
	 /* public deleteBanco(key: string){
		 this.angularFireDatabase.list(PATH_BANCOS + key).remove();
		 return this.angularFireDatabase.list("/Configuracion/Bancos/" + key).remove();
		//this.angularFireDatabase.list(PATH_MODELOS + marca.nombre).remove();
		//return this.angularFireDatabase.list("/Configuracion/Marcas/" + marca.key).remove();
	}*/
	  
	public getServiciosTaller(){
		return this.angularFireDatabase.list(PATH_SERVICIOS_TALLER).snapshotChanges();
	}

	public insertMarca(marca){
		return this.angularFireDatabase.list(PATH_MARCAS).push({marca: marca});
	}

	public deleteMarca(marca: MarcaModel){
		this.angularFireDatabase.list(PATH_MODELOS + marca.nombre).remove();
		return this.angularFireDatabase.list("/Configuracion/Marcas/" + marca.key).remove();
	}

	public getModelosMarca(marca){
		return this.angularFireDatabase.list(PATH_MODELOS + marca).snapshotChanges();
	}

	public insertModelo(marca, modelo){
		return this.angularFireDatabase.list(PATH_MODELOS + marca).push(modelo);
	}

	public updateModelo(marca, modelo: ModeloModel){
		return this.angularFireDatabase.object(PATH_MODELOS + marca + "/" + modelo.key).update(modelo);
	}

	public deleteModelo(nombreMarca, modelo){
		return this.angularFireDatabase.list(PATH_MODELOS + nombreMarca + "/" + modelo.key).remove();
	}

  /*  talleres */
	public  getTalleres(){
		return this.angularFireDatabase.list(PATH_TALLER_SUCURSALES).snapshotChanges();
  }

  public getTalleresList(offset: any, startKey?: any){
    return this.angularFireDatabase.list(PATH_TALLER_SUCURSALES, ref => ref.limitToFirst(offset+1).startAt(startKey).orderByChild('codigo')).snapshotChanges();
  }

  public searchTaller(type: any, code:any){
    return this.angularFireDatabase.list(PATH_TALLER_SUCURSALES, ref => ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }

  public getTaller(codigo){
		return this.angularFireDatabase.object(PATH_TALLER_SUCURSALES + codigo).snapshotChanges();
	}
  /* end talleres */


  /* PRTs */

  public getPRTs(){
		return this.angularFireDatabase.list(PATH_PRT_SUCURSALES).snapshotChanges();
  }

  public getPRTsList(offset: any, startKey?: any){
    return this.angularFireDatabase.list(PATH_PRT_SUCURSALES, ref => ref.limitToFirst(offset+1).startAt(startKey).orderByChild('codigo')).snapshotChanges();
  }

  public searchPRTs(type: any, code:any){
    return this.angularFireDatabase.list(PATH_PRT_SUCURSALES, ref => ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }

  /* End Prts */

  /* Lavados */

  public getLavados(){
		return this.angularFireDatabase.list(PATH_LAVADOS_SUCURSALES).snapshotChanges();
  }

  public getLavadosList(offset: any, startKey?: any){
    return this.angularFireDatabase.list(PATH_LAVADOS_SUCURSALES, ref => ref.limitToFirst(offset+1).startAt(startKey).orderByChild('codigo')).snapshotChanges();
  }

  public searchLavados(type: any, code:any){
    return this.angularFireDatabase.list(PATH_LAVADOS_SUCURSALES, ref => ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }

  /* end lavados */


  /* Gruas */

  public getGruas(){
		return this.angularFireDatabase.list(PATH_GRUA_SUCURSALES).snapshotChanges();
  }

  public getGruasList(offset: any, startKey?: any){
    return this.angularFireDatabase.list(PATH_GRUA_SUCURSALES, ref => ref.limitToFirst(offset+1).startAt(startKey).orderByChild('codigo')).snapshotChanges();
  }

  public searchGruas(type: any, code:any){
    return this.angularFireDatabase.list(PATH_GRUA_SUCURSALES, ref => ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }

  /*End Gruas */

  /* Marcas */

  public getMarcas(){
		return this.angularFireDatabase.list(PATH_MARCAS).snapshotChanges();
	}

  public getMarcasList(offset: any, startKey?: any){
    return this.angularFireDatabase.list(PATH_MARCAS, ref => ref.limitToFirst(offset+1).startAt(startKey).orderByChild('marca')).snapshotChanges();
  }

  public searchMarcas(type: any, code:any){
    return this.angularFireDatabase.list(PATH_MARCAS, ref => ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }


  /* end Marcas */


  	/* Funcion que toma todas las ordenes de una sucursal */
	public getOrdenesSucursal(servicio, codigo){
		return this.angularFireDatabase.list(PATH_NEGOCIO + "/" + servicio + "/Sucursales/" + this.sucursal.codigo + "/ordenes").snapshotChanges();
	}


	public getOrdenesSucList(startKey?: any){
		//let x = startKey == undefined ? "" : startKey;
		//return this.angularFireDatabase.list(PATH_ORDENES, ref => ref.limitToFirst(offset+1).orderByChild('proveedor').equalTo(startKey)).snapshotChanges();
		return this.angularFireDatabase.list(PATH_ORDENES, ref => ref.orderByChild('proveedor').equalTo(startKey)).snapshotChanges();
	  }



  public getOrdenesSucursalList(servicio:any, codigo:any, offset: any, startKey?: any){
    let x = startKey == undefined ? "" : startKey;
    console.log(" es un "+x)
		return this.angularFireDatabase.list(PATH_NEGOCIO + "/" + servicio + "/Sucursales/" + codigo + "/ordenes", ref =>  ref.limitToFirst(offset+1).startAt(x).orderByKey()).snapshotChanges();
	}

	public getOrdenesSuc(servicio:any, codigo:any){

			return this.angularFireDatabase.list(PATH_NEGOCIO + "/" + servicio + "/Sucursales/" + codigo + "/ordenes").snapshotChanges();
		}


  public searchOrdenesSucursal(servicio:any, codigo:any, type: any, code:any){
		return this.angularFireDatabase.list(PATH_NEGOCIO + "/" + servicio + "/Sucursales/" + codigo + "/ordenes", ref =>  ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
	}


  /* Operadores */

  	/* Toma todos los Operadores ligados al Proveedor logueado */
	public getOperadoresByProveedor(){
		return this.angularFireDatabase.database.ref(PATH_USUARIOS).orderByChild("proveedor").equalTo(this.user.uid).once("value");
	}





/* Ordenes */
  	/* Funcion muy ineficiente, version primaria para pantalla del listado de ordenes, se debe optimizar mediante consultas Firebase, tablas manuales y paginado mediante consultas limitadas */
	public getOrdenes(){
		return this.angularFireDatabase.list(PATH_ORDENES).snapshotChanges();
  }

  public getOrdenesList(offset: any, startKey?: any){
    return this.angularFireDatabase.list(PATH_ORDENES, ref => ref.limitToFirst(offset+1).startAt(startKey).orderByChild('fechaOrden')).snapshotChanges();
  }

  public searchOrdenes(type: any, code:any){
    return this.angularFireDatabase.list(PATH_ORDENES, ref => ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }

  public searchOrdenesCode(code:any){
    return this.angularFireDatabase.object(PATH_ORDENES + code).snapshotChanges();
   // return this.angularFireDatabase.list(PATH_ORDENES, ref => ref.orderByChild('').startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }

  public searchOrdenesDate(date1:any, date2:any){
    return this.angularFireDatabase.list(PATH_ORDENES, ref => ref.orderByChild('fechaOrden').startAt(date1).endAt(date2)).snapshotChanges();
  }


  /* Promociones */
  	/* Toma todas las promociones registradas */
	public getPromociones(){
		return this.angularFireDatabase.list(PATH_PROMOCIONES).snapshotChanges();
  }

  public getPromocionesList(offset: any, startKey?: any){
		return this.angularFireDatabase.list(PATH_PROMOCIONES, ref => ref.limitToFirst(offset+1).startAt(startKey).orderByChild('servicio')).snapshotChanges();
  }

  public searchPromociones(type: any, code:any){
    return this.angularFireDatabase.list(PATH_PROMOCIONES, ref => ref.orderByChild(type).startAt(code).endAt(code+"\uf8ff")).snapshotChanges();
  }


  public searchPromocionesPrecio(precio1:any){
    return this.angularFireDatabase.list(PATH_PROMOCIONES, ref => ref.orderByChild('precio').startAt(precio1).endAt(precio1+"\uf8ff")).snapshotChanges();
  }

  /* End pormociones */

	public deleteTaller(taller: TallerModel){
		taller.activo = false;
		taller.eliminado = true;
		return this.angularFireDatabase.list(PATH_TALLER_SUCURSALES).update(taller.codigo, taller);
	}

	public deleteTallerAdmin(taller: TallerModel){
		taller.activo = false;
		taller.eliminado = true;
		return this.angularFireDatabase.list(PATH_TALLER_SUCURSALES + taller.codigo).remove();
	}

	public insertTaller(taller: TallerModel, imagen?){
		if (imagen != undefined) {
			this.uploadImageTaller(taller.codigo, imagen);
		}
		return this.angularFireDatabase.list(PATH_TALLER_SUCURSALES).set(taller.codigo, taller);
	}



	public updateTaller(taller: TallerModel, imagen?){
		console.log(imagen);
		if (imagen != undefined) {
			this.uploadImageTaller(taller.codigo, imagen).then(resultado => {
				console.log("resultado ", resultado)
			}).catch(error => {
				console.log("error ", error)
			});
		}
		return this.angularFireDatabase.object(PATH_TALLER_SUCURSALES + taller.codigo).update(taller);
	}

	public updateActivoTaller(codigoTaller: string, valorActivo){
		return this.angularFireDatabase.object(PATH_TALLER_SUCURSALES + codigoTaller).update({activo: valorActivo});
	}
	public updateActivoPRT(codigoPRT: string, valorActivo){
		return this.angularFireDatabase.object(PATH_PRT_SUCURSALES + codigoPRT).update({activo: valorActivo});
	}
	public updateActivoLavado(codigoLavado: string, valorActivo){
		return this.angularFireDatabase.object(PATH_LAVADOS_SUCURSALES + codigoLavado).update({activo: valorActivo});
	}
	public updateActivoGrua(codigoGrua: string, valorActivo){
		return this.angularFireDatabase.object(PATH_GRUA_SUCURSALES + codigoGrua).update({activo: valorActivo});
	}

	public recuperarTallerAdmin(codigoTaller: string){
		return this.angularFireDatabase.object(PATH_TALLER_SUCURSALES + codigoTaller).update({eliminado: false});
	}
	/* Funcion que sube una imagen de un Taller */
	public uploadImageTaller(tallerCodigo, imagen){
		return this.angularFireStorage.upload(PATH_IMAGEN_TALLER + tallerCodigo, imagen);
	}
	/* Funcion que sube una imagen de un Proveedor */
	public uploadImageProveedor(uid, imagen){
		return this.angularFireStorage.upload(PATH_IMAGEN_PROVEEDOR + uid, imagen);
	}
	/* Funcion que tomando un codigo y un servicio, toma la URL de una imagen */
	public getImage(servicio, codigo){
		return this.angularFireStorage.ref(PATH_NEGOCIO + '/' + servicio + '/Patrocinio/' + codigo).getDownloadURL();
	}
	/* Funcion que toma una sucursal en particular, sino se le pasa un servicio se toma el del usuario en login */
	public getSucursal(codigo, servicio?){
		if (servicio != undefined) {
			return this.angularFireDatabase.object(PATH_NEGOCIO + servicio + '/Sucursales/' + codigo).snapshotChanges();
		} else {
			return this.angularFireDatabase.object(PATH_NEGOCIO + this.user.servicio + '/Sucursales/' + codigo).snapshotChanges();
		}
	}
	/* Funcion que toma todas las sucursales donde el usuario (actualmente con sesion iniciada) esta registrado */
	public getSucursalesEmpleado(){
		return this.angularFireDatabase.list(PATH_USUARIOS + this.user.uid + '/sucursales').snapshotChanges();
	}

	/* Funcion que toma una orden en particular */
	public getOrden(codigo){
		return this.angularFireDatabase.object('/Ordenes/' + codigo).snapshotChanges();
	}




	public insertPRT(prt: PRTModel, imagen?){
		if (imagen != undefined) {
			this.uploadImagePRT(prt.codigo, imagen);
		}
		return this.angularFireDatabase.list(PATH_PRT_SUCURSALES).set(prt.codigo, prt);
	}

	public getPRT(codigo){
		return this.angularFireDatabase.object(PATH_PRT_SUCURSALES + codigo).snapshotChanges();
	}

	public uploadImagePRT(prtCodigo, imagen){
		return this.angularFireStorage.upload(PATH_IMAGEN_PRT + prtCodigo, imagen);
	}

	public deletePRT(prt: PRTModel){
		prt.activo = false;
		prt.eliminado = true;
		return this.angularFireDatabase.list(PATH_PRT_SUCURSALES).update(prt.codigo, prt);
	}

	public deletePRTAdmin(prt: PRTModel){
		prt.activo = false;;
		prt.eliminado = true;
		return this.angularFireDatabase.list(PATH_PRT_SUCURSALES + prt.codigo).remove();
	}

	public recuperarPRTAdmin(codigoPRT: string){
		return this.angularFireDatabase.object(PATH_PRT_SUCURSALES + codigoPRT).update({eliminado: false});
	}

	public updatePRT(prt: PRTModel, imagen?){
		if (imagen != undefined) {
			this.uploadImagePRT(prt.codigo, imagen).then(resultado => {
			}).catch(error => {

			});
		}
		return this.angularFireDatabase.object(PATH_PRT_SUCURSALES + prt.codigo).update(prt);
	}



	public insertLavado(lavado: LavadoModel, imagen?){
		if (imagen != undefined) {
			this.uploadImageLavado(lavado.codigo, imagen);
		}
		return this.angularFireDatabase.list(PATH_LAVADOS_SUCURSALES).set(lavado.codigo, lavado);
	}

	public getLavado(codigo){
		return this.angularFireDatabase.object(PATH_LAVADOS_SUCURSALES + codigo).snapshotChanges();
	}

	public uploadImageLavado(lavadoCodigo, imagen){
		return this.angularFireStorage.upload(PATH_IMAGEN_LAVADO + lavadoCodigo, imagen);
	}

	public deleteLavado(lavado: LavadoModel){
		lavado.activo = false;
		lavado.eliminado = true;
		return this.angularFireDatabase.list(PATH_LAVADOS_SUCURSALES).update(lavado.codigo, lavado);
	}

	public deleteLavadoAdmin(lavado: LavadoModel){
		lavado.activo = false;
		lavado.eliminado = true;
		return this.angularFireDatabase.list(PATH_LAVADOS_SUCURSALES + lavado.codigo).remove();
	}

	public recuperarLavadoAdmin(codigoLavado: string){
		return this.angularFireDatabase.object(PATH_LAVADOS_SUCURSALES + codigoLavado).update({eliminado: false});
	}

	public updateLavado(lavado: LavadoModel, imagen?){
		if (imagen != undefined) {
			this.uploadImageLavado(lavado.codigo, imagen).then(resultado => {
				// console.log("resultado ", resultado)
			}).catch(error => {
				// console.log("error ", error)
			});
		}
		return this.angularFireDatabase.object(PATH_LAVADOS_SUCURSALES + lavado.codigo).update(lavado);
	}


	public insertGrua(grua: GruaModel, imagen?){
		if(imagen != undefined){
			this.uploadImageGrua(grua.codigo, imagen);
		}
		return this.angularFireDatabase.list(PATH_GRUA_SUCURSALES).set(grua.codigo, grua);
	}

	public getGrua(codigo){
		return this.angularFireDatabase.object(PATH_GRUA_SUCURSALES + codigo).snapshotChanges();
	}

	public uploadImageGrua(gruaCodigo, imagen){
		return this.angularFireStorage.upload(PATH_IMAGEN_GRUA + gruaCodigo, imagen);
	}

	public deleteGrua(grua: GruaModel){
		grua.activo = false;
		grua.eliminado = true;
		return this.angularFireDatabase.list(PATH_GRUA_SUCURSALES).update(grua.codigo, grua);
	}

	public deleteGruaAdmin(grua: GruaModel){
		grua.activo = false;
		grua.eliminado = true;
		return this.angularFireDatabase.list(PATH_GRUA_SUCURSALES + grua.codigo).remove();
	}

	public recuperarGruaAdmin(codigoGrua: string){
		return this.angularFireDatabase.object(PATH_GRUA_SUCURSALES + codigoGrua).update({eliminado: false});
	}

	public updateGrua(grua: GruaModel, imagen?){
		if (imagen != undefined) {
			this.uploadImageGrua(grua.codigo, imagen).then(resultado => {
				 console.log("resultado ", resultado)
			}).catch(error => {
				console.log("error ", error)
			});
		}
		return this.angularFireDatabase.object(PATH_GRUA_SUCURSALES + grua.codigo).update(grua);
	}

	/* En el momento que un proveedor acepta una orden, se le setea el estado "Aceptada Por Proveedor" */
	aceptarOrdenProveedor(codigoOrden){
		this.angularFireDatabase.object('Ordenes/' + codigoOrden).update({estadoOrden: 'Aceptada Por Proveedor', respuestaProveedor: this.darHora()});
	}
	/* En el momento que un proveedor rechaza una orden, se le setea el estado "Rechazada Por Proveedor" */
	rechazarOrdenProveedor(codigoOrden){
		this.angularFireDatabase.object('Ordenes/' + codigoOrden).update({estadoOrden: 'Rechazada Por Proveedor', respuestaProveedor: this.darHora()});
	}
	/* Funcion que da por comenzada una orden, setea estado "En Proceso" */
	comenzarOrdenProveedor(codigoOrden){
		this.angularFireDatabase.object('Ordenes/' + codigoOrden).update({estadoOrden: 'En Proceso', fechaInicio: this.darHora()});
	}
	/* Funcion que da por finalizada una orden, setea estado "Finalizada" */
	finalizarOrdenProveedor(codigoOrden){
		this.angularFireDatabase.object('Ordenes/' + codigoOrden).update({estadoOrden: 'Finalizada', fechaFinalizada: this.darHora()});
	}
	/* Ingresa una sucursal OFF */
	public insertSucursalPreVenta(sucursal){
		if(sucursal.servicio == 'PRT'){
			return this.angularFireDatabase.list(PATH_PRT_SUCURSALESOFF).push(sucursal);
		} else {
			if(sucursal.servicio == 'Taller'){
				return this.angularFireDatabase.list(PATH_TALLER_SUCURSALESOFF).push(sucursal);
			} else {
				if(sucursal.servicio == 'Lavado'){
					return this.angularFireDatabase.list(PATH_LAVADO_SUCURSALESOFF).push(sucursal);
				} else{
					return this.angularFireDatabase.list(PATH_GRUA_SUCURSALESOFF).push(sucursal);
				}
			}
		}
	}

	public deleteSucursalPreVenta(servicio,key){
		if(servicio == 'PRT'){
			return this.angularFireDatabase.list(PATH_PRT_SUCURSALESOFF + key).remove();
		} else {
			if(servicio == 'Taller'){
				return this.angularFireDatabase.list(PATH_TALLER_SUCURSALESOFF + key).remove();
			} else {
				if(servicio == 'Lavado'){
					return this.angularFireDatabase.list(PATH_LAVADO_SUCURSALESOFF + key).remove();
				} else{
					return this.angularFireDatabase.list(PATH_GRUA_SUCURSALESOFF + key).remove();
				}
			}
		}
	}
	/* Toma las sucursales que aun estan en estado OFF (aun no validadas) */
	public getSucursalesPreVenta(servicio){
		return this.angularFireDatabase.list(PATH_NEGOCIO + servicio + '/SucursalesOFF/').snapshotChanges();
	}
	/* Inserta una nueva promocion */
	public insertPromocion(promocion){
		return this.angularFireDatabase.list(PATH_PROMOCIONES).push(promocion);
	}

	/* Edita una promocion existente */
	public editPromocion(promocion,key){
		return this.angularFireDatabase.object(PATH_PROMOCIONES + key).update(promocion);
	}
	/* Toma todas las sucursales de un proveedor */
	public getSucursalesServicio(servicio){
		return this.angularFireDatabase.list(PATH_NEGOCIO + servicio + '/Sucursales/').snapshotChanges();
	}
	/* Toma todos los proveedores registrados */
	public getProveedores(){
		return this.angularFireDatabase.database.ref(PATH_USUARIOS).orderByChild("rol").equalTo(RolesUser.USER_PROVEEDOR).once("value");
	}
	/* Toma un usuario en particular */
	public getUsuario(uid){
		return this.angularFireDatabase.object(PATH_USUARIOS + uid).snapshotChanges();
	}
	/* Elimina el proveedor del nodo de la sucursal y la sucursal del nodo del proveedor*/
	public removeSucursalProveedor(sucursalKey, proveedorKey, servicio){
		this.angularFireDatabase.object(PATH_NEGOCIO + servicio + "/Sucursales/" + sucursalKey).update({pertenece: null});
		return this.angularFireDatabase.object(PATH_USUARIOS + proveedorKey + "/sucursales/" + servicio).update({[sucursalKey]: null});
	}
	/* Toma todas las sucursales */
	public getAllSucursales(servicio){
		return this.angularFireDatabase.database.ref(PATH_NEGOCIO + servicio + "/Sucursales/").orderByChild("pertenece").equalTo(null).once("value");
	}
	/* Asocia una sucursal a un proveedor */
	public asociarSucursal(sucursalKey, servicio, proveedorKey){
		this.angularFireDatabase.object(PATH_NEGOCIO + servicio + "/Sucursales/" + sucursalKey).update({pertenece: proveedorKey});
		return this.angularFireDatabase.object(PATH_USUARIOS + proveedorKey + "/sucursales/" + servicio).update({[sucursalKey]: true});
	}
	/* Funcion que crea la cuenta por la autenticacion de Firebase, esto se debe a que no puede crear una cuenta con un email en uso */
	public addCuentaAuth(email, pass){
		return this.angularFireAuth.auth.createUserWithEmailAndPassword(email, pass);
	}
	/* Inserta un nuevo proveedor en el nodo de Usuarios */
	public async insertProveedor(proveedor: ProveedorModel, uid, imagen?){
		if (imagen != undefined) {
			this.uploadImageProveedor(uid, imagen);
		}
	    return this.angularFireDatabase.list(PATH_USUARIOS).set(uid, {
	    	email: proveedor.email,
			nombre: proveedor.nombre,
			telefono: proveedor.telefono,
			razonSocial: proveedor.razonSocial,
			rut: proveedor.rut,
			codigoPais: proveedor.codigoPais,
			region: proveedor.region,
			comuna: proveedor.comuna,
			direccion: proveedor.direccion,
			rol: RolesUser.USER_PROVEEDOR,
			banco: proveedor.banco,
			tipoCuenta: proveedor.tipoCuenta,
			nroCuenta: proveedor.nroCuenta,
			saldoCuenta: 0,
			saldoDisponible: 0,
			eliminado: false,
			verificado: false
	    });
	}
	/* Realiza una bajada logica del proveedor con la key proveedorKey */
	public deleteProveedor(proveedorKey){
		return this.angularFireDatabase.object(PATH_USUARIOS + proveedorKey).update({eliminado: true});
	}
	/* Verifica al usuario con la key uid */
	public verificarUsuario(uid){
		return this.angularFireDatabase.object(PATH_USUARIOS + uid).update({verificado: true});
	}
	/* Bloquea el operador con la key uid */
	public bloquearOperador(uid){
		return this.angularFireDatabase.object(PATH_USUARIOS + uid).update({verificado: false});
	}

	/* Toma todos los Operadores ligados al Proveedor logueado */
	public insertOperador(uid, operador){
		return this.angularFireDatabase.list(PATH_USUARIOS).set(uid, operador);
	}
	/* Toma todos los Operadores ligados al Proveedor logueado */
	public deleteOperador(operadorKey){
		return this.angularFireDatabase.object(PATH_USUARIOS + operadorKey).update({eliminado: true});
	}
	/* Toma las sucursales asociadas a un proveedor */
	public getSucursalesUsuario(usuarioKey, servicio){
		return this.angularFireDatabase.list(PATH_USUARIOS + usuarioKey + "/sucursales/" + servicio).snapshotChanges();
	}
	/* Asocia una sucursal a un usuario */
	public asociarSucursalProveedor(usuarioKey, servicio, sucursalKey){
		return this.angularFireDatabase.object(PATH_USUARIOS + usuarioKey + "/sucursales/" + servicio).update({[sucursalKey]: true});
	}
	/* Remueve una sucursal de un usuario */
	public removeSucursalUsuario(usuarioKey, servicio, sucursalKey){
		return this.angularFireDatabase.object(PATH_USUARIOS + usuarioKey + "/sucursales/" + servicio).update({[sucursalKey]: null});
	}
	/* Asocia un empleado a una sucursal */
	public asociarEmpleadoSucursal(usuarioKey, servicio, sucursalKey){
		return this.angularFireDatabase.object(PATH_NEGOCIO + servicio + "/Sucursales/" + sucursalKey + "/personal").update({[usuarioKey]: true});
	}
	/* Remueve un empleado de una sucursal */
	public removeEmpleadoSucursal(usuarioKey, servicio, sucursalKey){
		return this.angularFireDatabase.object(PATH_NEGOCIO + servicio + "/Sucursales/" + sucursalKey + "/personal").update({[usuarioKey]: null});
	}
	/* Toma todas las Ordenes ligadas a la sucursal con codigo "codigo" */
	public async getOrdenesSucursalByProveedor(codigo){
		return this.angularFireDatabase.database.ref(PATH_ORDENES).orderByChild("proveedor").equalTo(codigo).once("value");
	}
	/* Toma todas las Ordenes que su fecha contenga a "fecha" */
	public async getOrdenesSucursalByFecha(fecha){
		return this.angularFireDatabase.database.ref(PATH_ORDENES).orderByChild("fechaOrden").startAt(fecha).endAt(fecha + "\uf8ff").once("value");
	}
	/* Toma todos los Operadores ligados al Proveedor logueado */
	public async getRetirosByProveedor(proveedorKey){
		return this.angularFireDatabase.database.ref(PATH_RETIROS).orderByChild("proveedor").equalTo(proveedorKey).once("value");
	}
	/* Toma todos los Operadores ligados al Proveedor logueado */
	public getRetiro(retiroKey){
		return this.angularFireDatabase.object(PATH_RETIROS + retiroKey).snapshotChanges();
	}
	/* Elimina por completo el nodo de retiro en caso de error */
	public removeRetiro(retiroKey){
		return this.angularFireDatabase.object(PATH_RETIROS + retiroKey).remove();
	}
	/* Modifica el nodo del usuario */
	public updateProveedor(userUid, proveedor: ProveedorModel, imagen?){
		if (imagen != undefined) {
			this.angularFireStorage.ref(PATH_IMAGEN_PROVEEDOR + this.user.uid).delete();
			this.uploadImageProveedor(userUid, imagen).then(resultado => {
				// console.log("resultado ", resultado)
			}).catch(error => {
				// console.log("error ", error)
			});
		}
		return this.angularFireDatabase.object(PATH_USUARIOS + userUid).update({
			nombre: proveedor.nombre,
			telefono: proveedor.telefono,
			razonSocial: proveedor.razonSocial,
			region: proveedor.region,
			comuna: proveedor.comuna,
			direccion: proveedor.direccion,
			banco: proveedor.banco,
			tipoCuenta: proveedor.tipoCuenta,
			nroCuenta: proveedor.nroCuenta
		});
	}
	/* Setea un nuevo retiro a ser realizado por un administrador FixBeep */
	public insertRetiro(retiro){
		return this.angularFireDatabase.list(PATH_RETIROS).push(retiro);
	}
	/* Modifica el saldo disponible de la cuenta de un usuario proveedor */
	public updateSaldoDisponible(uid, saldo){
		return this.angularFireDatabase.object(PATH_USUARIOS + uid).update({saldoDisponible: saldo});
	}
	/* Modifica el saldo total de la cuenta de un usuario proveedor */
	public updateSaldoCuenta(uid, saldo){
		return this.angularFireDatabase.object(PATH_USUARIOS + uid).update({saldoCuenta: saldo});
	}
	/* Toma todos los retiros realizados */
	public getRetiros(){
		return this.angularFireDatabase.list(PATH_RETIROS).snapshotChanges();
	}
	/* Toma valor en base sobre los dias habiles para las ordenes */
	public getDiasHabiles(){
		return this.angularFireDatabase.object(PATH_DIAS_HABILES).snapshotChanges();
	}
	/* Toma valor en base sobre los dias habiles para las ordenes */
	public realizarRetiro(retiroUid){
		return this.angularFireDatabase.object(PATH_RETIROS + retiroUid).update({admin: this.user.uid, realizado: true});
	}
	/* Actualiza el tutorial de un usuario */
	public updateTutorial(nroTutorial, userUid?){
		if (userUid != undefined) {
			return this.angularFireDatabase.object(PATH_USUARIOS + userUid).update({tutorial: nroTutorial});
		}
		return this.angularFireDatabase.object(PATH_USUARIOS + this.user.uid).update({tutorial: nroTutorial});
	}
	/* Busca el usuario con el email "email" */
	public getUsuarioByEmail(email){
		return this.angularFireDatabase.database.ref(PATH_USUARIOS).orderByChild("email").equalTo(email).once("value");
	}
	/* Busca la invitacion con el id "id" */
	public getInvitacion(id){
		return this.angularFireDatabase.object(PATH_INVITACIONES_OPERADORES + id).snapshotChanges();
	}
	/* Remueve la invitacion con el id "id" */
	public removeInvitacion(id){
		return this.angularFireDatabase.object(PATH_INVITACIONES_OPERADORES + id).remove();
	}
	/* Busca la invitacion que sea enviada al email "email" */
	public getInvitacionOperadorByEmail(email){
		return this.angularFireDatabase.database.ref(PATH_INVITACIONES_OPERADORES).orderByChild("email").equalTo(email).once("value");
	}
	/* Graba una invitacion a un operador */
	public setInvitacionOperador(email){
		return this.angularFireDatabase.list(PATH_INVITACIONES_OPERADORES).push({email: email, proveedor: this.user.uid, fecha: this.darHora()});
	}
	/* Toma todos los Operadores ligados al Proveedor logueado */
	public getInvitacionOperadoresByProveedor(){
		return this.angularFireDatabase.database.ref(PATH_INVITACIONES_OPERADORES).orderByChild("proveedor").equalTo(this.user.uid).once("value");
	}
	public convertOperador(uid, nuevo) {
	    /* Función que convierte un operador en gruero  */
	    return this.angularFireDatabase.object(PATH_USUARIOS + uid).set(nuevo);
	}

	/* Funcion que retornara la hora en ese momento casteada a un formato apropiado para el usuario. */
	darHora(): string {
		return moment(new Date()).format("DD-MM-YYYY HH:mm").toString();
	}
}
