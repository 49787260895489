import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/components/button/button';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import {CalendarModule} from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { RatingModule } from 'primeng/rating';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { MisSucursalesComponent } from './components/mis-sucursales/mis-sucursales.component';
import { OperadoresProveedorComponent } from './components/operadores-proveedor/operadores-proveedor.component';
import { SucursalesOperadorComponent } from './components/sucursales-operador/sucursales-operador.component';
import { UpdateLavadoComponent } from './components/update-lavado/update-lavado.component';
import { UpdateGruaComponent } from './components/update-grua/update-grua.component';
import { UpdateTallerComponent } from './components/update-taller/update-taller.component';
import { UpdatePrtComponent } from './components/update-prt/update-prt.component';

@NgModule({
  declarations: [
    MisSucursalesComponent,
    OperadoresProveedorComponent,
    SucursalesOperadorComponent,
    OperadoresProveedorComponent,
    UpdateTallerComponent,
    UpdatePrtComponent,
    UpdateLavadoComponent,
    UpdateGruaComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    ToastModule,
    CalendarModule,
    MenubarModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    CardModule,
    ConfirmDialogModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ToggleButtonModule,
    DialogModule,
    TooltipModule,
    TabViewModule,
    RatingModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule.forRoot()
  ],
  exports: [
    ButtonModule,
    ToastModule,
    MenubarModule,
    InputTextModule,
    MessagesModule,
    CalendarModule,
    MessageModule,
    CardModule,
    ConfirmDialogModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ToggleButtonModule,
    DialogModule,
    TooltipModule,
    TabViewModule,
    RatingModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule.forRoot()
  ]
})
export class SharedModule { }
