import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';

import { OperadorModel } from '../../../models/operador-model';
import { ProveedorModel } from '../../../models/proveedor-model';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message, SelectItem } from 'primeng/components/common/api';
import * as _ from "lodash";

@Component({
	selector: 'app-operadores-proveedor',
	templateUrl: './operadores-proveedor.component.html',
	styleUrls: ['./operadores-proveedor.component.scss']
})
export class OperadoresProveedorComponent implements OnInit {

	public columnas: any[];
	public errores:  Message[] = [];
	public operadores: OperadorModel[] = [];
	public nuevoOperadorModal: boolean = false;
	private proveedor: ProveedorModel = new ProveedorModel();

  private proveedor_suscriber: any;

  filters: SelectItem[];

  selectedFilter: any;
  buscarText:any;

  buscarFecha1:any;
  buscarFecha2:any;

  banFecha1= false;
  banFecha2= false;
  banBusqueda = false;
  offset  = 10;
  nextKey: any; // for next button
  prevKeys: any[] = []; // for prev button
  page: any;

  ordensitas: any[]=[];



	constructor(private servicios: FirebaseService, private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) {
		this.columnas = [
		{ field: 'email', header: 'Email' },
		{ field: 'estado', header: 'Estado' },
		{ field: 'editar', header: 'Asignar Sucursales' },
		{ field: 'delete', header: 'Bloquear' }
    ];

    this.filters = [
      {label:'Seleccione', value:{id:0, name: 'codigo'}},
      {label:'Email', value:{id:1, name: 'email'}}
   ];
  }

  onChange(newValue) {
    if(newValue.id == 0){
      this.getOperadores();
       this.buscarText = "";
       this.banBusqueda = false;
       this.banFecha1= false;
       this.banFecha2= false;
     }else if(newValue.id==5){
      this.banBusqueda = false;
      this.banFecha1 = true;
      this.banFecha2 = true;
     }
     else{
       this.banBusqueda = true;
       this.banFecha1= false;
       this.banFecha2= false;
     }
    }


    nextPage() {
     this.prevKeys.push(_.first(this.ordensitas)['fechaOrden']) // set current key as pointer for previous page
     this.page++;
     //this.getOrdenes(this.nextKey);
   }

   prevPage() {
     const prevKey = _.last(this.prevKeys) // use last key in array
     this.prevKeys = _.dropRight(this.prevKeys) // then remove the last key in the array
     this.page--;
     //this.getOrdenes(prevKey)
   }

	ngOnDestroy(){
		if (this.proveedor_suscriber != undefined) {
			this.proveedor_suscriber.unsubscribe();
		}
	}

	ngOnInit() {
    this.page = 0;
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				}
			});
		}
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				}
			});
		}

		// TUTORIAL
		// this.proveedor_suscriber = this.servicios.getProveedor(this.servicios.user.uid).subscribe(proveedorNodo => {
		// 	var proveedor = proveedorNodo.payload.val();
		// 	this.proveedor.key = proveedorNodo.key;
		// 	if (proveedor['tutorial'] == undefined) {
		// 		this.proveedor.tutorial = 0;
		// 	} else {
		// 		this.proveedor.tutorial = proveedor['tutorial'];
		// 	}
    // });

    this.getOperadores();
  }

  getOperadores(key?){

    this.operadores = [];
		this.servicios.getOperadoresByProveedor().then(operadores => {
			operadores.forEach(nodo => {
				var operadorNodo = nodo.val();
				if (!operadorNodo.eliminado && operadorNodo.verificado) {
					var nuevoOperador = new OperadorModel();
					nuevoOperador.uid = nodo.key.toString();
					nuevoOperador.email = operadorNodo.email;
					nuevoOperador.estado = "Habilitado";
					nuevoOperador.nombre = operadorNodo.nombre;
					nuevoOperador.proveedor = operadorNodo.proveedor;
					nuevoOperador.rol = operadorNodo.rol;
					nuevoOperador.eliminado = false;
					this.operadores.push(nuevoOperador);
				}
			});
		});
		this.servicios.getInvitacionOperadoresByProveedor().then(operadores => {
			operadores.forEach(nodo => {
				var operadorNodo = nodo.val();
				var nuevoOperador = new OperadorModel();
				nuevoOperador.uid = nodo.key.toString();
				nuevoOperador.email = operadorNodo.email;
				nuevoOperador.estado = "Pendiente";
				this.operadores.push(nuevoOperador);
			});
    });


  }

	modificarOperador(operador: OperadorModel){
		sessionStorage.setItem("operador_key", operador.uid);
		this.router.navigate(['Proveedor/Operadores/Sucursales']);
	}

	bloquearOperador(operador: OperadorModel){
		this.errores = [];
		this.confirmationService.confirm({
			message: '¿Seguro que quieres bloquear el operador ' + operador.nombre + '?',
			accept: () => {
				this.servicios.bloquearOperador(operador.uid).then(resultado => {
					var pibot = 0, indice;
					this.operadores.forEach(operadorNodo => {
						if (operadorNodo.uid == operador.uid) {
							indice = pibot;
						}
						pibot += 1;
					});
					this.operadores.splice(indice, 1);
					this.quitarSucursalesOperador(operador.uid).then(result => {
						console.log(result);
					});
					this.errores.push({severity:'success', detail: "El Operador fue bloqueado con éxito", summary:'Operador Bloqueado'});
				}).catch(error => {
					this.errores.push({severity:'error', detail: "No fue posible bloquear al Operador, intentelo de nuevo mas tarde", summary:'Bloqueaar Operador'});
				});
			}
		});
	}

	async quitarSucursalesOperador(operadorKey) {
		var prt_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "PRT").subscribe(prts => {
			prts.forEach(PRT_Nodo => {
				var suscriber = this.servicios.getSucursal(PRT_Nodo.key, "PRT").subscribe(PRT => {
					if (PRT != undefined) {
						this.servicios.removeSucursalUsuario(operadorKey, "PRT", PRT_Nodo.key).then(resultado => {
							this.servicios.removeEmpleadoSucursal(operadorKey, "PRT", PRT_Nodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(operadorKey, "PRT", PRT_Nodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			prt_suscriber.unsubscribe();
		});
		var lavado_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "Lavado").subscribe(lavados => {
			lavados.forEach(lavadoNodo => {
				var suscriber = this.servicios.getSucursal(lavadoNodo.key, "Lavado").subscribe(lavado => {
					if (lavado != undefined) {
						this.servicios.removeSucursalUsuario(operadorKey, "Lavado", lavadoNodo.key).then(resultado => {
							this.servicios.removeEmpleadoSucursal(operadorKey, "Lavado", lavadoNodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(operadorKey, "Lavado", lavadoNodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			lavado_suscriber.unsubscribe();
		});
		var taller_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "Taller").subscribe(talleres => {
			talleres.forEach(tallerNodo => {
				var suscriber = this.servicios.getSucursal(tallerNodo.key, "Taller").subscribe(taller => {
					if (taller != undefined) {
						this.servicios.removeSucursalUsuario(operadorKey, "Taller", tallerNodo.key).then(resultado => {
							this.servicios.removeEmpleadoSucursal(operadorKey, "Taller", tallerNodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(operadorKey, "Taller", tallerNodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			taller_suscriber.unsubscribe();
		});
		var grua_suscriber = this.servicios.getSucursalesUsuario(operadorKey, "Grua").subscribe(gruas => {
			gruas.forEach(gruaNodo => {
				var suscriber = this.servicios.getSucursal(gruaNodo.key, "Grua").subscribe(grua => {
					if (grua != undefined) {
						this.servicios.removeSucursalUsuario(operadorKey, "Grua", gruaNodo.key).then(resultado => {
							this.servicios.removeEmpleadoSucursal(operadorKey, "Grua", gruaNodo.key).then(result => {
								// Sucursal eliminada
							}).catch(error => {
								this.servicios.asociarSucursalProveedor(operadorKey, "Grua", gruaNodo.key).then(resultado => {
									console.log("Rollback realizado");
								});
								this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
							});
						}).catch(error => {
							this.errores.push({severity:'error', detail: "No fue posible remover la sucursal, intentelo de nuevo mas tarde", summary:'Remover Sucursal'});
						});
					}
					suscriber.unsubscribe();
				});
			});
			grua_suscriber.unsubscribe();
		});
	}

	invitar(){
		this.router.navigate(['Proveedor/Operadores/Invitar']);
	}

	culminarTutorial() {
		this.servicios.updateTutorial(5).then(resultado => {});
	}
}
