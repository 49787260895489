import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';

import { GruaModel } from '../../../models/grua-model';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';

@Component({
	selector: 'app-update-grua',
	templateUrl: './update-grua.component.html',
	styleUrls: ['./update-grua.component.scss']
})
export class UpdateGruaComponent implements OnInit {

	public registroGrua: FormGroup;
	public erroresGrua:  Message[] = [];

	public imagePath;
	public imagenUrl;
	public paises: Array<{nombre: string, codigo: string}> = new Array();
	public regiones: Array<{nombre: string}> = new Array();
	public comunas: Array<string> = new Array();

	public codigoGrua: string = '';

	public isProveedor = "0";

	public activo = false;
	public imagen = undefined;
	public tieneImagen = false;

	constructor(private servicios: FirebaseService, private router: Router,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		public formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit() {
		var isProveedor = sessionStorage.getItem("is_proveedor");
		if (isProveedor != null && isProveedor != undefined) {
			this.isProveedor = isProveedor;
			sessionStorage.removeItem("is_proveedor");
		}
		this.codigoGrua = this.activatedRoute.snapshot.paramMap.get("id");
		if (this.codigoGrua == undefined || this.codigoGrua == null) {
			this.router.navigate(['']);
		}
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				}
			});
		}
		this.registroGrua = this.formBuilder.group({
			email: new FormControl('', [Validators.required, Validators.email]),
			nombre: new FormControl('', [Validators.required]),
			rut: new FormControl('', [Validators.required]),
			key: new FormControl('', [Validators.required]),
			tel: new FormControl('', [Validators.required]),
			latitud: new FormControl('', [Validators.required]),
			longitud: new FormControl('', [Validators.required]),
			pais: new FormControl('', [Validators.required]),
			region: new FormControl('', [Validators.required]),
			comuna: new FormControl('', [Validators.required]),
			comision: new FormControl('', [Validators.required]),
			direccion: new FormControl('', [Validators.required]),
			semana1: new FormControl('', [Validators.required]),
			semana12: new FormControl('', [Validators.required]),
			semana2: new FormControl(''),
			semana22: new FormControl(''),
			sabado1: new FormControl('', [Validators.required]),
			sabado12: new FormControl('', [Validators.required]),
			sabado2: new FormControl(''),
			sabado22: new FormControl(''),
		});
		this.servicios.getPaises().subscribe(paises => {
			this.paises = [];
			paises.forEach(paisNodo => {
				this.paises.push({nombre: paisNodo.payload.val()['nombre'], codigo: paisNodo.key});
			});
		});
		this.servicios.getRegiones().subscribe(regiones => {
			this.regiones = [];
			regiones.forEach(regionNodo => {
				var region = regionNodo.payload.val();
				this.regiones.push({nombre: region['nombre']});
			});
		});
		var subscriptor = this.servicios.getGrua(this.codigoGrua).subscribe(resultado => {
			subscriptor.unsubscribe();
			var nodoGrua = resultado.payload.val();
			this.registroGrua.get('email').setValue(nodoGrua['email']);
			this.registroGrua.get('nombre').setValue(nodoGrua['nombre']);
			this.registroGrua.get('rut').setValue(nodoGrua['rut']);
			this.registroGrua.get('key').setValue(nodoGrua['codigo']);
			this.registroGrua.get('tel').setValue(nodoGrua['telefono']);
			this.registroGrua.get('latitud').setValue(nodoGrua['latitud']);
			this.registroGrua.get('longitud').setValue(nodoGrua['longitud']);
			this.registroGrua.get('pais').setValue(nodoGrua['codigoPais']);
			this.registroGrua.get('comision').setValue(nodoGrua['comision']);
			this.activo = nodoGrua['activo'];
			this.registroGrua.get('region').setValue(nodoGrua['region']);
			this.registroGrua.get('comuna').setValue(nodoGrua['comuna']);
			this.registroGrua.get('direccion').setValue(nodoGrua['direccion']);
			var horarios_semana = nodoGrua['horarios']['semana'].split("/");
			var semana1 = (horarios_semana[0].split("-"))[0];
			var semana12 = (horarios_semana[0].split("-"))[1];
			this.registroGrua.get('semana1').setValue(semana1);
			this.registroGrua.get('semana12').setValue(semana12);
			if (horarios_semana[1] != undefined) {
				var semana2 = (horarios_semana[1].split("-"))[0];
				var semana22 = (horarios_semana[1].split("-"))[1];
				this.registroGrua.get('semana2').setValue(semana2);
				this.registroGrua.get('semana22').setValue(semana22);
			}
			var horarios_sabado = nodoGrua['horarios']['sabado'].split("/");
			var sabado1 = (horarios_sabado[0].split("-"))[0];
			var sabado12 = (horarios_sabado[0].split("-"))[1];
			this.registroGrua.get('sabado1').setValue(sabado1);
			this.registroGrua.get('sabado12').setValue(sabado12);
			if (horarios_sabado[1] != undefined) {
				var sabado2 = (horarios_sabado[1].split("-"))[0];
				var sabado22 = (horarios_sabado[1].split("-"))[1];
				this.registroGrua.get('sabado2').setValue(sabado2);
				this.registroGrua.get('sabado22').setValue(sabado22);
			}
			this.servicios.getImage("Grua", nodoGrua['codigo']).subscribe(urlImagen => {
				this.imagen = urlImagen;
				this.tieneImagen = true;
			}, error => {
				if (error.code == 'storage/object-not-found') {
					this.erroresGrua.push({severity:'info', detail: "La Grúa aún no tiene logo", summary:'Información'});
				}
			});
			this.servicios.getComunas(this.registroGrua.get('region').value).subscribe(comunas => {
				this.comunas = [];
				comunas.forEach(comunaNodo => {
					var comuna = comunaNodo.payload.val();
					this.comunas.push(comuna.toString().toUpperCase());
				});
			});
		});
	}

	public async modificarGrua(){
		this.erroresGrua = [];
		if (this.registroGrua.valid) {
			var nuevaGrua = new GruaModel();
			nuevaGrua.codigo = this.registroGrua.get('key').value;
			nuevaGrua.email = this.registroGrua.get('email').value;
			nuevaGrua.comision = this.registroGrua.get('comision').value;
			nuevaGrua.activo = this.activo;
			nuevaGrua.rut = this.registroGrua.get('rut').value;
			nuevaGrua.codigoPais = this.registroGrua.get('pais').value;
			nuevaGrua.comuna = this.registroGrua.get('comuna').value;
			nuevaGrua.direccion = this.registroGrua.get('direccion').value;
			nuevaGrua.eliminado = false;
			nuevaGrua.horarios = {semana: this.registroGrua.get('semana1').value + "-" + this.registroGrua.get('semana12').value, sabado: this.registroGrua.get('sabado1').value + "-" + this.registroGrua.get('sabado12').value};
			if (this.registroGrua.get('semana2').value != '') {
				nuevaGrua.horarios.semana += "/" + this.registroGrua.get('semana2').value + "-" + this.registroGrua.get('semana22').value;
			}
			if (this.registroGrua.get('sabado2').value != '') {
				nuevaGrua.horarios.sabado += "/" + this.registroGrua.get('sabado2').value + "-" + this.registroGrua.get('sabado22').value;
			}
			nuevaGrua.latitud = this.registroGrua.get('latitud').value;
			nuevaGrua.longitud = this.registroGrua.get('longitud').value;
			nuevaGrua.nombre = this.registroGrua.get('nombre').value;
			nuevaGrua.region = this.registroGrua.get('region').value;
			nuevaGrua.telefono = this.registroGrua.get('tel').value;



			if (this.tieneImagen) {
				var nodos = await this.servicios.updateGrua(nuevaGrua).then(resultado => {
					this.erroresGrua.push({severity:'success', detail: "Grúa " + nuevaGrua.codigo + " modificada con éxito", summary:'Grúa modificada'});
				}).catch(error => {
				});
			} else {
				var nodos = await this.servicios.updateGrua(nuevaGrua, this.imagen).then(resultado => {
					this.erroresGrua.push({severity:'success', detail: "Grúa " + nuevaGrua.codigo + " modificada con éxito", summary:'Grúa modificada'});
				}).catch(error => {
				});
			}
		} else {
			this.erroresGrua.push({severity:'error', detail:'¡Rellene todos los campos requeridos!', summary:'Error'});
		}
	}

	public cambioActivo(){
		this.erroresGrua = [];
		this.servicios.updateActivoGrua(this.registroGrua.get('key').value, this.activo).then(resultado => {
			if (this.activo) {
				this.erroresGrua.push({severity:'success', detail: "Activaste la Grúa", summary:'Grúa activada'});
			} else {
				this.erroresGrua.push({severity:'success', detail: "Desactivaste la Grúa", summary:'Grúa desactivada'});
			}
		}).catch(error => {
			this.erroresGrua.push({severity:'error', detail: "Error de servidor", summary:'Error interno'});
		});
	}


	public getComunas(){
		this.servicios.getComunas(this.registroGrua.get('region').value).subscribe(comunas => {
			this.comunas = [];
			if (comunas[0] != undefined) {
				this.registroGrua.get('comuna').setValue(comunas[0].payload.val().toString().toUpperCase());
			}
			comunas.forEach(comunaNodo => {
				var comuna = comunaNodo.payload.val();
				this.comunas.push(comuna.toString().toUpperCase());
			});
		});
	}

	public getError(controlName: string): string {
	let error = '';
	const control = this.registroGrua.get(controlName);
	if (control.touched && control.errors != null) {
		error = JSON.stringify(control.errors);
	}
	if (error.indexOf('requiredPattern')>-1) {
		error = "Campo incorrecto"
	}
	if (error.indexOf('minlength')>-1) {
		error = "Contraseña débil";
	}
	if (error.indexOf('"required":true')>-1) {
		error = "Campo requerido";
	}
	if (error.indexOf('"email":true')>-1) {
		error = "Formato de dirección email incorrecto";
	}
	return error;
}

setImagen(event){
	this.imagenUrl = event.target.files[0];


	var reader = new FileReader();
	this.imagePath = this.imagenUrl;
	reader.readAsDataURL(this.imagePath);
	reader.onload = (_event) => {

	  this.imagen = reader.result;
	}


	this.servicios.uploadImageGrua(this.codigoGrua, this.imagenUrl);
}

}
