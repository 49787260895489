import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';

import { ProveedorModel } from '../../../models/proveedor-model';
import { SucursalModel } from '../../../models/sucursal-model';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';

@Component({
	selector: 'app-mis-sucursales',
	templateUrl: './mis-sucursales.component.html',
	styleUrls: ['./mis-sucursales.component.scss']
})
export class MisSucursalesComponent implements OnInit {

	public columnas: any[];
	public mensajesTaller:  Message[] = [];
	public nuevoOperadorModal: boolean = false;
	private sucursales: Array<SucursalModel> = [];
	private sucursales_keys: Map<string, string> = new Map<string, string>();
	private proveedor: ProveedorModel = new ProveedorModel();

	private prt_suscriber: any;
	private lavado_suscriber: any;
	private taller_suscriber: any;
	private grua_suscriber: any;
	private proveedor_suscriber: any;

	constructor(private servicios: FirebaseService, private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) {
		this.columnas = [
		{ field: 'nombre', header: 'Nombre' },
		{ field: 'servicio', header: 'Servicio' },
		{ field: 'region', header: 'Región' },
		{ field: 'comuna', header: 'Comuna' },
		{ field: 'calificacion', header: 'Calificación' },
		{ field: 'ver', header: 'Ver' }
		];
	}

	ngOnDestroy(){
		if (this.prt_suscriber != undefined) {
			this.prt_suscriber.unsubscribe();
		}
		if (this.lavado_suscriber != undefined) {
			this.lavado_suscriber.unsubscribe();
		}
		if (this.lavado_suscriber != undefined) {
			this.lavado_suscriber.unsubscribe();
		}
		if (this.grua_suscriber != undefined) {
			this.grua_suscriber.unsubscribe();
		}
		if (this.proveedor_suscriber != undefined) {
			this.proveedor_suscriber.unsubscribe();
		}
	}

	ngOnInit() {
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			var subscriber = this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				} else {
					if (this.servicios.user.rol != RolesUser.USER_PROVEEDOR){
						this.router.navigate([""]);
					}
				}
				subscriber.unsubscribe();
			});
		}
		// TUTORIAL
		// this.proveedor_suscriber = this.servicios.getProveedor(this.servicios.user.uid).subscribe(proveedorNodo => {
		// 	var proveedor = proveedorNodo.payload.val();
		// 	this.proveedor.key = proveedorNodo.key;
		// 	if (proveedor['tutorial'] == undefined) {
		// 		this.proveedor.tutorial = 0;
		// 	} else {
		// 		this.proveedor.tutorial = proveedor['tutorial'];
		// 	}
		// });
		this.prt_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "PRT").subscribe(prts => {
			prts.forEach(PRT_Nodo => {
				var suscriber = this.servicios.getSucursal(PRT_Nodo.key, "PRT").subscribe(PRT => {
					if (PRT != undefined) {
						if (this.sucursales_keys.get(PRT_Nodo.key) == null || this.sucursales_keys.get(PRT_Nodo.key) == undefined) {
							var sucursalPRT = PRT.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = PRT.key;
							sucursal.codigoPais = sucursalPRT['codigoPais'];
							sucursal.comision = sucursalPRT['comision'];
							sucursal.comuna = sucursalPRT['comuna'];
							sucursal.direccion = sucursalPRT['direccion'];
							sucursal.estado = sucursalPRT['estado'];
							sucursal.nombre = sucursalPRT['nombre'];
							sucursal.region = sucursalPRT['region'];
							sucursal.rut = sucursalPRT['rut'];
							sucursal.telefono = sucursalPRT['telefono'];
							sucursal.estado = sucursalPRT['estado'];
							sucursal.calificacion = sucursalPRT['calificacion'];
							sucursal.servicio = "PRT";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.lavado_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "Lavado").subscribe(lavados => {
			lavados.forEach(lavadoNodo => {
				var suscriber = this.servicios.getSucursal(lavadoNodo.key, "Lavado").subscribe(lavado => {
					if (lavado != undefined) {
						if (this.sucursales_keys.get(lavadoNodo.key) == null || this.sucursales_keys.get(lavadoNodo.key) == undefined) {
							var sucursalLavado = lavado.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = lavado.key;
							sucursal.comision = sucursalLavado['comision'];
							sucursal.codigoPais = sucursalLavado['codigoPais'];
							sucursal.comuna = sucursalLavado['comuna'];
							sucursal.direccion = sucursalLavado['direccion'];
							sucursal.estado = sucursalLavado['estado'];
							sucursal.nombre = sucursalLavado['nombre'];
							sucursal.region = sucursalLavado['region'];
							sucursal.rut = sucursalLavado['rut'];
							sucursal.telefono = sucursalLavado['telefono'];
							sucursal.estado = sucursalLavado['estado'];
							sucursal.calificacion = sucursalLavado['calificacion'];
							sucursal.servicio = "Lavado";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.taller_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "Taller").subscribe(talleres => {
			talleres.forEach(tallerNodo => {
				var suscriber = this.servicios.getSucursal(tallerNodo.key, "Taller").subscribe(taller => {
					if (taller != undefined) {
						if (this.sucursales_keys.get(tallerNodo.key) == null || this.sucursales_keys.get(tallerNodo.key) == undefined) {
							var sucursalTaller = taller.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = taller.key;
							sucursal.comision = sucursalTaller['comision'];
							sucursal.codigoPais = sucursalTaller['codigoPais'];
							sucursal.comuna = sucursalTaller['comuna'];
							sucursal.direccion = sucursalTaller['direccion'];
							sucursal.estado = sucursalTaller['estado'];
							sucursal.nombre = sucursalTaller['nombre'];
							sucursal.region = sucursalTaller['region'];
							sucursal.rut = sucursalTaller['rut'];
							sucursal.telefono = sucursalTaller['telefono'];
							sucursal.estado = sucursalTaller['estado'];
							sucursal.calificacion = sucursalTaller['calificacion'];
							sucursal.servicio = "Taller";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
		this.grua_suscriber = this.servicios.getSucursalesUsuario(this.servicios.user.uid, "Grua").subscribe(gruas => {
			gruas.forEach(gruaNodo => {
				var suscriber = this.servicios.getSucursal(gruaNodo.key, "Grua").subscribe(grua => {
					if (grua != undefined) {
						if (this.sucursales_keys.get(gruaNodo.key) == null || this.sucursales_keys.get(gruaNodo.key) == undefined) {
							var sucursalGrua = grua.payload.val();
							var sucursal = new SucursalModel();
							sucursal.codigo = grua.key;
							sucursal.codigoPais = sucursalGrua['codigoPais'];
							sucursal.comision = sucursalGrua['comision'];
							sucursal.comuna = sucursalGrua['comuna'];
							sucursal.direccion = sucursalGrua['direccion'];
							sucursal.estado = sucursalGrua['estado'];
							sucursal.nombre = sucursalGrua['nombre'];
							sucursal.region = sucursalGrua['region'];
							sucursal.rut = sucursalGrua['rut'];
							sucursal.telefono = sucursalGrua['telefono'];
							sucursal.estado = sucursalGrua['estado'];
							sucursal.calificacion = sucursalGrua['calificacion'];
							sucursal.servicio = "Grua";
							this.sucursales.push(sucursal);
							this.sucursales_keys.set(sucursal.codigo, sucursal.codigo);
							suscriber.unsubscribe();
						}
					}
				});
			});
		});
	}

	modificarSucursal(sucursal: SucursalModel){
		if(sucursal.servicio == "Taller"){
			sessionStorage.setItem("is_proveedor", "1");
			this.router.navigate(['Proveedor/Talleres/Modificar/' + sucursal.codigo]);
		}
		if(sucursal.servicio == "PRT"){
			sessionStorage.setItem("is_proveedor", "1");
			this.router.navigate(['Proveedor/PRTs/Modificar/' + sucursal.codigo]);
		}
		if(sucursal.servicio == "Lavado"){
			sessionStorage.setItem("is_proveedor", "1");
			this.router.navigate(['Proveedor/Lavados/Modificar/' + sucursal.codigo]);
		}
		if(sucursal.servicio == "Grua"){
			sessionStorage.setItem("is_proveedor", "1");
			this.router.navigate(['Proveedor/Gruas/Modificar/' + sucursal.codigo]);
		}
	}

	nuevaSucursal(){
		// routerLink="/"
		this.router.navigate(['Proveedor/Sucursal/Nueva']);
	}

	culminarTutorial() {
		this.servicios.updateTutorial(5).then(resultado => {});
	}
}
