import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ButtonModule } from 'primeng/components/button/button';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatePipe } from '@angular/common';
// Re Captcha
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
// Firebase imports
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
// PrimeNG imports
import { ConfirmationService, MessageService } from 'primeng/api';

// Components imports
import { LoginComponent } from './login/login.component';

import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AltaProveedorComponent } from './proveedores/components/alta-proveedor/alta-proveedor.component';
import { RecuperarComponent } from './recuperar/recuperar.component';

@NgModule({
  declarations: [
  	AppComponent,
    LoginComponent,
    AltaProveedorComponent,
    RecuperarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
