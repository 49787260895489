import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { FirebaseService } from '../core/services/firebase.service';

@Component({
  selector: 'app-recuperar',
  templateUrl: './recuperar.component.html',
  styleUrls: ['./recuperar.component.scss']
})
export class RecuperarComponent implements OnInit {

  public erroresRecuperar: Message[] = [];
  public loading = false;
  email: string;
  erroresLogin: any;

  constructor(private servicios: FirebaseService, private messageService: MessageService,
     private router: Router, private title: Title) { 
    title.setTitle("Password Recovery");
     }

     ngOnDestroy() {
      this.title.setTitle("FixBeep");
    }

  ngOnInit() {
  }
 
  
  recuperarContrasena() {
    this.erroresLogin = [];
    if (!this.email) {
      this.loading = false;
			this.erroresLogin.push({severity:'error', detail:'Rellene todos los campos', summary:'Error'});

      alert('Debe ingresar un correo electrónico para recuperar tu contraseña.');
      return;
    }

      this.loading = true;
      this.servicios.recuperarContrasena(this.email)
        .then(() => {
          this.loading = false;
          alert('Se ha enviado un correo para recuperar tu contraseña.');
          this.router.navigate(['Login'])
        })
  
  
        .catch((error) => {
          this.loading = false;
          this.erroresRecuperar.push(error.message);
          alert('Correo incorrecto.');
          this.loading = false;
			
        });
    }
    
} 
