export class ProveedorModel {
	nombre: string;
	key: string;
	email: string;
	razonSocial: string;
	rut: string;
	telefono: string;
	codigoPais: string;
	region: string;
	comuna: string;
	direccion: string;
	password: string;
	nroCuenta: string;
	tipoCuenta: string;
	banco: string;
	saldoCuenta: string;
	saldoDisponible: string;
	tutorial: any;

	constructor(){
	}
}


/*
	-- Tutorial --
		Es una forma de informar a los usuarios que les hace falta para estar en total funcionamiento en la plataforma

	-- Tutorial para Proveedores

		0 - Cuenta recien verificada por nuestros administradores, orientar para agregar una pre inscripcion de sucursal.
		1 - Ingreso una pre inscripcion de sucursal, aguardar a que un administrador la verifique y asigne.
		2 - Le asignaron una sucursal o mas, orientar al usuario a ingresar nuevos operadores.
		3 - Ingreso el primer operador, orientar al usuario a verificarlo.
		4 - Verifico el operador, boton para darlo como terminado..
		5 - Culmino el tutorial.
	

*/