import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FirebaseService } from './core/services/firebase.service';

import { Router } from '@angular/router';

import { UserModel } from './models/user-model';
import { RolesUser } from './enumerados/roles-user-enum';

import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    @Input() data: any;
    title = 'FixbeepCrud';
 
    items: MenuItem[];

    constructor(public servicios: FirebaseService, private router: Router){}

    ngOnInit() {
        var subscripcion = this.servicios.verificarSesion().subscribe(user => {
            if (user != null) {
                this.servicios.verificarCuenta(user).then(resultado => {
                    var suscripcion = resultado.valueChanges().subscribe(usuario => {
                        if (usuario.rol == RolesUser.SUPER_ADMIN || usuario.rol == RolesUser.USER_ADMIN || usuario.rol == RolesUser.USER_PROVEEDOR || usuario.rol == RolesUser.USER_OPERATOR || usuario.rol == RolesUser.USER_VENDEDOR) {
                            this.servicios.user = new UserModel();
                            this.servicios.user = usuario;
                            this.servicios.user.uid = user.uid;
                            if (usuario.rol == RolesUser.SUPER_ADMIN || usuario.rol == RolesUser.USER_ADMIN) {
                                this.items = [
                                {
                                    label: 'Proveedores',
                                    routerLink: 'Proveedores',
                                    icon: 'fas fa-building',
                                    items: [{
                                        label: 'Proveedores',
                                        routerLink: 'Proveedores',
                                        icon: 'fas fa-user-tie',
                                    },
                                    {
                                        label: 'Registro',
                                        icon: 'fas fa-clipboard-list',
                                        routerLink: 'Proveedores/Registro/Sucursal'
                                    },
                                    {
                                        label: 'Verificar',
                                        routerLink: 'Proveedores/Verificar',
                                        icon: 'fas fa-check'
                                    },
                                    {
                                        label: 'Retiros',
                                        routerLink: 'Proveedores/Retiros',
                                        icon: 'fas fa-piggy-bank'
                                    }
                                    ],
                                },
                                {
                                    label: 'Servicios',
                                    icon: 'fas fa-concierge-bell',
                                    items: [
                                    {
                                        label: 'Talleres',
                                        icon: 'fas fa-wrench',
                                        routerLink: 'Servicios/Talleres'
                                    },
                                    {
                                        label: 'PRTs',
                                        icon: 'fas fa-screwdriver',
                                        routerLink: 'Servicios/PRTs'
                                    },
                                    {
                                        label: 'Lavados',
                                        icon: 'fas fa-shower',
                                        routerLink: 'Servicios/Lavados'
                                    },
                                    {
                                        label: 'Grúas',
                                        icon: 'fas fa-car-crash',
                                        routerLink: 'Servicios/Gruas'
                                    }],
                                },
                                {
                                    label: 'Archivos Auxiliares',
                                    icon: 'fa fa-archive',
                                    routerLink: 'Auxiliares/Marcas',
                                    items: [ {
                                        label: 'Marcas',
                                        icon: 'fas fa-car',
                                        routerLink: 'Marcas'
                                    },
                                    {
                                        label: 'Bancos',
                                        icon: 'fas fa-university',
                                        routerLink: 'Bancos'
                                    },
                                    ],
                                },
                               
                               
                                {
                                    label: 'Promociones',
                                    icon: 'fas fa-star',
                                    routerLink: 'Promociones'
                                },
                                {
                                    label: 'Órdenes',
                                    icon: 'fas fa-clipboard-check',
                                    routerLink: 'Ordenes'
                                }
                                ];
                            } else {
                                if (usuario.rol == RolesUser.USER_PROVEEDOR) {
                                    if (usuario.verificado) {
                                        this.items = [
                                        {
                                            label: 'Mis sucursales',
                                            icon: 'fas fa-clipboard-check',
                                            routerLink: 'Proveedor/Mis-Sucursales'
                                        },
                                        {
                                            label: 'Operadores',
                                            icon: 'fas fa-headset',
                                            routerLink: 'Proveedor/Mis-Operadores'
                                        }];
                                    } else {
                                        if (sessionStorage.getItem("mensaje_login_correcto") == undefined) {
                                            sessionStorage.setItem("mensaje_login_error", "Al parecer su cuenta aún no se encuentra verificada por nuestros funcionarios.");
                                            this.servicios.logOut();
                                        }
                                    }
                                } else {
                                    if(usuario.rol == RolesUser.USER_OPERATOR){
                                        if (usuario.verificado && usuario.sucursales != undefined) {
                                            this.items = [
                                            {
                                                label: 'Administración',
                                                icon: 'fas fa-clipboard-check',
                                                routerLink: 'Sucursal/Datos'
                                            }];
                                        } else {
                                            if (sessionStorage.getItem("mensaje_login_correcto") == undefined) {
                                                if (!usuario.verificado)
                                                    sessionStorage.setItem("mensaje_login_error", "Al parecer su cuenta aún no se encuentra verificada por nuestros funcionarios.");
                                                else
                                                    sessionStorage.setItem("mensaje_login_error", "Aún no se te han asignado sucursales, pidele a tu proveedor que te asocie alguna para poder ingresar.");
                                                this.servicios.logOut();
                                            }
                                        }
                                    } else{
                                        this.items = [];
                                    }
                                }
                            }
                            this.router.navigate([""]);
                        }
                        suscripcion.unsubscribe();
                    });
                }).catch( result => {
                    this.servicios.logOut();
                });
            } else {
                if(this.router.url != '/Proveedores/Nuevo' && this.router.url.indexOf('Operadores/Nuevo') == -1){
                    this.items = [
                    {
                        label: 'Inicio',
                        icon: 'fas fa-home',
                        routerLink: '/'
                    },
                    {
                        label: 'Talleres',
                        icon: 'fas fa-wrench',
                        routerLink: 'Talleres'
                    },
                    {
                        label: 'PRTs',
                        icon: 'fas fa-wrench',
                        routerLink: 'PRTs'
                    },
                    {
                        label: 'Lavados',
                        icon: 'fas fa-wrench',
                        routerLink: 'Lavados'
                    },
                    {
                        label: 'Grúas',
                        icon: 'fas fa-wrench',
                        routerLink: 'Gruas'
                    },
                    {
                        label: 'Marcas',
                        icon: 'fas fa-car',
                        routerLink: 'Marcas'
                    }
                    ];
                    this.router.navigate(["Login"]);
                }
            }
        });
    }

    logout(){
        this.servicios.logOut();
    }
}
/*
SUB ITEMS PARA UN MENU
items: [{
    label: 'New',
    icon: 'pi pi-fw pi-plus',
    items: [
    {label: 'Project'},
    {label: 'Other'},
    ]
},
{label: 'Open'},
{label: 'Quit'}
]

*/
