import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../../core/services/firebase.service';

import { TallerModel } from '../../../models/taller-model';
import { RolesUser } from '../../../enumerados/roles-user-enum';

import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';

@Component({
	selector: 'app-update-taller',
	templateUrl: './update-taller.component.html',
	styleUrls: ['./update-taller.component.scss']
})
export class UpdateTallerComponent implements OnInit {

	public registroTaller: FormGroup;
	public erroresTaller:  Message[] = [];

	public imagePath;
	public message: string;

	public serviciosTaller: Array<SelectItem> = new Array();
	public marcasTaller: Array<SelectItem> = new Array();
	public paises: Array<{nombre: string, codigo: string}> = new Array();
	public regiones: Array<{nombre: string}> = new Array();
	public comunas: Array<string> = new Array();

	public codigoTaller: string = '';

	public activo = false;
	public imagen = undefined;
	public imagenUrl = "";
	public tieneImagen = false;

	public isProveedor = "0";

	public serviciosSeleccionados: Array<string> = new Array();
	public marcasSeleccionadas: Array<string> = new Array();

	constructor(private servicios: FirebaseService, private router: Router, private activatedRoute: ActivatedRoute, private confirmationService: ConfirmationService, private messageService: MessageService, public formBuilder: FormBuilder) {

	}

	ngOnInit() {
		var isProveedor = sessionStorage.getItem("is_proveedor");
		if (isProveedor != null && isProveedor != undefined) {
			this.isProveedor = isProveedor;
			sessionStorage.removeItem("is_proveedor");
		}
		this.codigoTaller = this.activatedRoute.snapshot.paramMap.get("id");
		if (this.codigoTaller == undefined || this.codigoTaller == null) {
			this.router.navigate(['']);
		}
		if (this.servicios.user == null || this.servicios.user == undefined) {
			this.router.navigate(['Login']);
		} else {
			this.servicios.verificarSesion().subscribe(usuario => {
				if (usuario == undefined) {
					this.router.navigate(['Login']);
				}
			});
		}
		this.registroTaller = this.formBuilder.group({
			email: new FormControl('', [Validators.required, Validators.email]),
			nombre: new FormControl('', [Validators.required]),
			rut: new FormControl('', [Validators.required]),
			key: new FormControl('', [Validators.required]),
			tel: new FormControl('', [Validators.required]),
			latitud: new FormControl('-', [Validators.required]),
			longitud: new FormControl('-', [Validators.required]),
			pais: new FormControl('UY', [Validators.required]),
			region: new FormControl('', [Validators.required]),
			comuna: new FormControl('', [Validators.required]),
			direccion: new FormControl('', [Validators.required]),
			semana1: new FormControl('', [Validators.required]),
			semana12: new FormControl('', [Validators.required]),
			semana2: new FormControl(''),
			semana22: new FormControl(''),
			sabado1: new FormControl('', [Validators.required]),
			sabado12: new FormControl('', [Validators.required]),
			sabado2: new FormControl(''),
			sabado22: new FormControl(''),
			lugaresInmediato: new FormControl('', [Validators.required]),
			lugaresReservaLunes1: new FormControl('', [Validators.required]),
			reservaLunes1: new FormControl('', [Validators.required]),
			reservaLunes12: new FormControl('', [Validators.required]),
			lugaresReservaMartes1: new FormControl('', [Validators.required]),
			reservaMartes1: new FormControl('', [Validators.required]),
			reservaMartes12: new FormControl('', [Validators.required]),
			lugaresReservaMiercoles1: new FormControl('', [Validators.required]),
			reservaMiercoles1: new FormControl('', [Validators.required]),
			reservaMiercoles12: new FormControl('', [Validators.required]),
			lugaresReservaJueves1: new FormControl('', [Validators.required]),
			reservaJueves1: new FormControl('', [Validators.required]),
			reservaJueves12: new FormControl('', [Validators.required]),
			lugaresReservaViernes1: new FormControl('', [Validators.required]),
			reservaViernes1: new FormControl('', [Validators.required]),
			reservaViernes12: new FormControl('', [Validators.required]),
			lugaresReservaSabado1: new FormControl('', [Validators.required]),
			reservaSabado1: new FormControl('', [Validators.required]),
			reservaSabado12: new FormControl('', [Validators.required]),
			lugaresReservaLunes2: new FormControl(''),
			reservaLunes2: new FormControl(''),
			reservaLunes22: new FormControl(''),
			lugaresReservaMartes2: new FormControl(''),
			reservaMartes2: new FormControl(''),
			reservaMartes22: new FormControl(''),
			lugaresReservaMiercoles2: new FormControl(''),
			reservaMiercoles2: new FormControl(''),
			reservaMiercoles22: new FormControl(''),
			lugaresReservaJueves2: new FormControl(''),
			reservaJueves2: new FormControl(''),
			reservaJueves22: new FormControl(''),
			lugaresReservaViernes2: new FormControl(''),
			reservaViernes2: new FormControl(''),
			reservaViernes22: new FormControl(''),
			lugaresReservaSabado2: new FormControl(''),
			reservaSabado2: new FormControl(''),
			reservaSabado22: new FormControl(''),
			estado: new FormControl(''),
			activo: new FormControl(''),
			comision: new FormControl('', [Validators.required]),
			precio: new FormControl('', [Validators.required]),
		});
		this.servicios.getPaises().subscribe(paises => {
			this.paises = [];
			paises.forEach(paisNodo => {
				this.paises.push({nombre: paisNodo.payload.val()['nombre'], codigo: paisNodo.key});
			});
		});
		this.servicios.getRegiones().subscribe(regiones => {
			this.regiones = [];
			regiones.forEach(regionNodo => {
				var region = regionNodo.payload.val();
				this.regiones.push({nombre: region['nombre']});
			});
		});
		this.servicios.getServiciosTaller().subscribe(servicios => {
			this.serviciosTaller = [];
			servicios.forEach(servicioNodo => {
				var servicio = servicioNodo.payload.val();
				var item: SelectItem = {label: servicio['nombre'], value: servicio['nombre']};
				this.serviciosTaller.push(item);
			});
		});
		this.servicios.getMarcas().subscribe(resultado => {
			var todas: SelectItem = {label:"Todas", value: "Todas"};
			this.marcasTaller.push(todas);
			resultado.forEach(marcaNodo => {
				var marca = marcaNodo.payload.val();
				var item: SelectItem = {label: marca['marca'], value: marca['marca']};
				this.marcasTaller.push(item);
			});
		});
		var subscriptor = this.servicios.getTaller(this.codigoTaller).subscribe(resultado => {
			subscriptor.unsubscribe();
			var nodoTaller = resultado.payload.val();
			if (nodoTaller['servicios'] != undefined) {
				var servicios: Array<string> = nodoTaller['servicios'].split(',');
				servicios.forEach(servicio => {
					this.serviciosSeleccionados.push(servicio);
				});
			}
			if (nodoTaller['marcas'] != undefined) {
				var marcas: Array<string> = nodoTaller['marcas'].split(',');
				marcas.forEach(marca => {
					this.marcasSeleccionadas.push(marca);
				});
			}
			this.servicios.getComunas(nodoTaller['region']).subscribe(comunas => {
				this.comunas = [];
				comunas.forEach(comunaNodo => {
					var comuna = comunaNodo.payload.val();
					this.comunas.push(comuna.toString().toUpperCase());
				});
			});
			this.registroTaller.get('email').setValue(nodoTaller['email']);
			this.registroTaller.get('nombre').setValue(nodoTaller['nombre']);
			this.registroTaller.get('rut').setValue(nodoTaller['rut']);
			this.registroTaller.get('key').setValue(nodoTaller['codigo']);
			this.registroTaller.get('tel').setValue(nodoTaller['telefono']);
			this.registroTaller.get('latitud').setValue(nodoTaller['latitud']);
			this.registroTaller.get('longitud').setValue(nodoTaller['longitud']);
			this.registroTaller.get('pais').setValue(nodoTaller['codigoPais']);
			this.registroTaller.get('estado').setValue(nodoTaller['estado']);
			this.registroTaller.get('comision').setValue(nodoTaller['comision']);
			this.registroTaller.get('precio').setValue(nodoTaller['precios']['monto']);
			this.activo = nodoTaller['activo'];
			this.registroTaller.get('region').setValue(nodoTaller['region']);
			this.registroTaller.get('comuna').setValue(nodoTaller['comuna']);
			this.registroTaller.get('direccion').setValue(nodoTaller['direccion']);
			this.registroTaller.get('lugaresInmediato').setValue(nodoTaller['Inmediato']['lugares']);
			var horarios_semana = nodoTaller['horarios']['semana'].split("/");
			var semana1 = (horarios_semana[0].split("-"))[0];
			var semana12 = (horarios_semana[0].split("-"))[1];
			this.registroTaller.get('semana1').setValue(semana1);
			this.registroTaller.get('semana12').setValue(semana12);
			if (horarios_semana[1] != undefined) {
				var semana2 = (horarios_semana[1].split("-"))[0];
				var semana22 = (horarios_semana[1].split("-"))[1];
				this.registroTaller.get('semana2').setValue(semana2);
				this.registroTaller.get('semana22').setValue(semana22);
			}
			var horarios_sabado = nodoTaller['horarios']['sabado'].split("/");
			var sabado1 = (horarios_sabado[0].split("-"))[0];
			var sabado12 = (horarios_sabado[0].split("-"))[1];
			this.registroTaller.get('sabado1').setValue(sabado1);
			this.registroTaller.get('sabado12').setValue(sabado12);
			if (horarios_sabado[1] != undefined) {
				var sabado2 = (horarios_sabado[1].split("-"))[0];
				var sabado22 = (horarios_sabado[1].split("-"))[1];
				this.registroTaller.get('sabado2').setValue(sabado2);
				this.registroTaller.get('sabado22').setValue(sabado22);
			}
			if (nodoTaller['reserva']['lugares']['lunes'] != undefined) {
				var lugares_lunes = nodoTaller['reserva']['lugares']['lunes'].toString().split("/");
				this.registroTaller.get('lugaresReservaLunes1').setValue(lugares_lunes[0]);
				this.registroTaller.get('lugaresReservaLunes2').setValue(lugares_lunes[1]);
			}
			if (nodoTaller['reserva']['lugares']['martes'] != undefined) {
				var lugares_martes = nodoTaller['reserva']['lugares']['martes'].toString().split("/");
				this.registroTaller.get('lugaresReservaMartes1').setValue(lugares_martes[0]);
				this.registroTaller.get('lugaresReservaMartes2').setValue(lugares_martes[1]);
			}
			if (nodoTaller['reserva']['lugares']['miercoles'] != undefined) {
				var lugares_miercoles = nodoTaller['reserva']['lugares']['miercoles'].toString().split("/");
				this.registroTaller.get('lugaresReservaMiercoles1').setValue(lugares_miercoles[0]);
				this.registroTaller.get('lugaresReservaMiercoles2').setValue(lugares_miercoles[1]);
			}
			if (nodoTaller['reserva']['lugares']['jueves'] != undefined) {
				var lugares_jueves = nodoTaller['reserva']['lugares']['jueves'].toString().split("/");
				this.registroTaller.get('lugaresReservaJueves1').setValue(lugares_jueves[0]);
				this.registroTaller.get('lugaresReservaJueves2').setValue(lugares_jueves[1]);
			}
			if (nodoTaller['reserva']['lugares']['viernes'] != undefined) {
				var lugares_viernes = nodoTaller['reserva']['lugares']['viernes'].toString().split("/");
				this.registroTaller.get('lugaresReservaViernes1').setValue(lugares_viernes[0]);
				this.registroTaller.get('lugaresReservaViernes2').setValue(lugares_viernes[1]);
			}
			if (nodoTaller['reserva']['lugares']['sabado'] != undefined) {
				var lugares_sabado = nodoTaller['reserva']['lugares']['sabado'].toString().split("/");
				this.registroTaller.get('lugaresReservaSabado1').setValue(lugares_sabado[0]);
				this.registroTaller.get('lugaresReservaSabado2').setValue(lugares_sabado[1]);
			}
			if (nodoTaller['reserva']['horarios']['lunes'] != undefined) {
				var horarios_reserva_lunes = nodoTaller['reserva']['horarios']['lunes'].toString().split("/");
				var reservaLunes1 = (horarios_reserva_lunes[0].split("-"))[0];
				var reservaLunes12 = (horarios_reserva_lunes[0].split("-"))[1];
				this.registroTaller.get('reservaLunes1').setValue(reservaLunes1);
				this.registroTaller.get('reservaLunes12').setValue(reservaLunes12);
				if (horarios_reserva_lunes[1] != undefined) {
					var reservaLunes2 = (horarios_reserva_lunes[1].split("-"))[0];
					var reservaLunes22 = (horarios_reserva_lunes[1].split("-"))[1];
					this.registroTaller.get('reservaLunes2').setValue(reservaLunes2);
					this.registroTaller.get('reservaLunes22').setValue(reservaLunes22);
				}
			}
			if (nodoTaller['reserva']['horarios']['martes'] != undefined) {
				var horarios_reserva_martes = nodoTaller['reserva']['horarios']['martes'].toString().split("/");
				var reservaMartes1 = (horarios_reserva_martes[0].split("-"))[0];
				var reservaMartes12 = (horarios_reserva_martes[0].split("-"))[1];
				this.registroTaller.get('reservaMartes1').setValue(reservaMartes1);
				this.registroTaller.get('reservaMartes12').setValue(reservaMartes12);
				if (horarios_reserva_martes[1] != undefined) {
					var reservaMartes2 = (horarios_reserva_martes[1].split("-"))[0];
					var reservaMartes22 = (horarios_reserva_martes[1].split("-"))[1];
					this.registroTaller.get('reservaMartes2').setValue(reservaMartes2);
					this.registroTaller.get('reservaMartes22').setValue(reservaMartes22);
				}
			}
			if (nodoTaller['reserva']['horarios']['miercoles'] != undefined) {
				var horarios_reserva_miercoles = nodoTaller['reserva']['horarios']['miercoles'].toString().split("/");
				var reservaMiercoles1 = (horarios_reserva_miercoles[0].split("-"))[0];
				var reservaMiercoles12 = (horarios_reserva_miercoles[0].split("-"))[1];
				this.registroTaller.get('reservaMiercoles1').setValue(reservaMiercoles1);
				this.registroTaller.get('reservaMiercoles12').setValue(reservaMiercoles12);
				if (horarios_reserva_miercoles[1] != undefined) {
					var reservaMiercoles2 = (horarios_reserva_miercoles[1].split("-"))[0];
					var reservaMiercoles22 = (horarios_reserva_miercoles[1].split("-"))[1];
					this.registroTaller.get('reservaMiercoles2').setValue(reservaMiercoles2);
					this.registroTaller.get('reservaMiercoles22').setValue(reservaMiercoles22);
				}
			}
			if (nodoTaller['reserva']['horarios']['jueves'] != undefined) {
				var horarios_reserva_jueves = nodoTaller['reserva']['horarios']['jueves'].toString().split("/");
				var reservaJueves1 = (horarios_reserva_jueves[0].split("-"))[0];
				var reservaJueves12 = (horarios_reserva_jueves[0].split("-"))[1];
				this.registroTaller.get('reservaJueves1').setValue(reservaJueves1);
				this.registroTaller.get('reservaJueves12').setValue(reservaJueves12);
				if (horarios_reserva_jueves[1] != undefined) {
					var reservaJueves2 = (horarios_reserva_jueves[1].split("-"))[0];
					var reservaJueves22 = (horarios_reserva_jueves[1].split("-"))[1];
					this.registroTaller.get('reservaJueves2').setValue(reservaJueves2);
					this.registroTaller.get('reservaJueves22').setValue(reservaJueves22);
				}
			}
			if (nodoTaller['reserva']['horarios']['viernes'] != undefined) {
				var horarios_reserva_viernes = nodoTaller['reserva']['horarios']['viernes'].toString().split("/");
				var reservaViernes1 = (horarios_reserva_viernes[0].split("-"))[0];
				var reservaViernes12 = (horarios_reserva_viernes[0].split("-"))[1];
				this.registroTaller.get('reservaViernes1').setValue(reservaViernes1);
				this.registroTaller.get('reservaViernes12').setValue(reservaViernes12);
				if (horarios_reserva_viernes[1] != undefined) {
					var reservaViernes2 = (horarios_reserva_viernes[1].split("-"))[0];
					var reservaViernes22 = (horarios_reserva_viernes[1].split("-"))[1];
					this.registroTaller.get('reservaViernes2').setValue(reservaViernes2);
					this.registroTaller.get('reservaViernes22').setValue(reservaViernes22);
				}
			}
			if (nodoTaller['reserva']['horarios']['sabado'] != undefined) {
				var horarios_reserva_sabado = nodoTaller['reserva']['horarios']['sabado'].toString().split("/");
				var reservaSabado1 = (horarios_reserva_sabado[0].split("-"))[0];
				var reservaSabado12 = (horarios_reserva_sabado[0].split("-"))[1];
				this.registroTaller.get('reservaSabado1').setValue(reservaSabado1);
				this.registroTaller.get('reservaSabado12').setValue(reservaSabado12);
				if (horarios_reserva_sabado[1] != undefined) {
					var reservaSabado2 = (horarios_reserva_sabado[1].split("-"))[0];
					var reservaSabado22 = (horarios_reserva_sabado[1].split("-"))[1];
					this.registroTaller.get('reservaSabado2').setValue(reservaSabado2);
					this.registroTaller.get('reservaSabado22').setValue(reservaSabado22);
				}
			}
			this.servicios.getImage("Taller", nodoTaller['codigo']).subscribe(urlImagen => {
				console.log(urlImagen);
				this.imagen = urlImagen;
				this.tieneImagen = true;
			}, error => {
				if (error.code == 'storage/object-not-found') {
					//this.tieneImagen = false;
					//this.imagen = "";
					this.erroresTaller.push({severity:'info', detail: "El taller aún no tiene logo", summary:'Información'});
				}
			});
		});
	}

	public async modificarTaller(){
		this.erroresTaller = [];
		console.log(this.registroTaller.valid);
		if (!this.registroTaller.valid) {
			if (this.serviciosSeleccionados.length != 0) {
				if (this.marcasSeleccionadas.length != 0) {
					var nuevoTaller = new TallerModel();
					nuevoTaller.Inmediato = {lugares: this.registroTaller.get('lugaresInmediato').value};
					nuevoTaller.codigo = this.registroTaller.get('key').value;
					nuevoTaller.email = this.registroTaller.get('email').value;
					nuevoTaller.comision = this.registroTaller.get('comision').value;
					nuevoTaller.precios = {monto: this.registroTaller.get('precio').value};
					nuevoTaller.activo = this.activo;
					nuevoTaller.rut = this.registroTaller.get('rut').value;
					nuevoTaller.codigoPais = this.registroTaller.get('pais').value;
					nuevoTaller.comuna = this.registroTaller.get('comuna').value;
					nuevoTaller.direccion = this.registroTaller.get('direccion').value;
					nuevoTaller.eliminado = false;
					nuevoTaller.estado = this.registroTaller.get('estado').value;
					nuevoTaller.horarios = {semana: this.registroTaller.get('semana1').value + "-" + this.registroTaller.get('semana12').value, sabado: this.registroTaller.get('sabado1').value + "-" + this.registroTaller.get('sabado12').value};
					if (this.registroTaller.get('semana2').value != '') {
						nuevoTaller.horarios.semana += "/" + this.registroTaller.get('semana2').value + "-" + this.registroTaller.get('semana22').value;
					}
					if (this.registroTaller.get('sabado2').value != '') {
						nuevoTaller.horarios.sabado += "/" + this.registroTaller.get('sabado2').value + "-" + this.registroTaller.get('sabado22').value;
					}
					nuevoTaller.latitud = this.registroTaller.get('latitud').value;
					nuevoTaller.longitud = this.registroTaller.get('longitud').value;
					nuevoTaller.nombre = this.registroTaller.get('nombre').value;
					nuevoTaller.region = this.registroTaller.get('region').value;
					for (const servicio of this.serviciosSeleccionados) {
						if (nuevoTaller.servicios == undefined) {
							nuevoTaller.servicios = servicio.toString();
						} else {
							nuevoTaller.servicios += "," + servicio;
						}
					}
					for (const marca of this.marcasSeleccionadas) {
						if (nuevoTaller.marcas == undefined) {
							nuevoTaller.marcas = marca.toString();
						} else {
							nuevoTaller.marcas += "," + marca;
						}
					}
					nuevoTaller.telefono = this.registroTaller.get('tel').value;
					nuevoTaller.reserva = {
						horarios: {
							jueves: this.registroTaller.get('reservaJueves1').value + "-" + this.registroTaller.get('reservaJueves12').value,
							lunes: this.registroTaller.get('reservaLunes1').value + "-" + this.registroTaller.get('reservaLunes12').value,
							martes: this.registroTaller.get('reservaMartes1').value + "-" + this.registroTaller.get('reservaMartes12').value,
							miercoles: this.registroTaller.get('reservaMiercoles1').value + "-" + this.registroTaller.get('reservaMiercoles12').value,
							sabado: this.registroTaller.get('reservaSabado1').value + "-" + this.registroTaller.get('reservaSabado12').value,
							viernes: this.registroTaller.get('reservaViernes1').value + "-" + this.registroTaller.get('reservaViernes12').value
						},
						lugares: {
							jueves: this.registroTaller.get('lugaresReservaJueves1').value,
							lunes: this.registroTaller.get('lugaresReservaLunes1').value,
							martes: this.registroTaller.get('lugaresReservaMartes1').value,
							miercoles: this.registroTaller.get('lugaresReservaMiercoles1').value,
							sabado: this.registroTaller.get('lugaresReservaSabado1').value,
							viernes: this.registroTaller.get('lugaresReservaViernes1').value
						}
					};
					if (this.registroTaller.get('reservaLunes2').value != undefined && this.registroTaller.get('reservaLunes22').value != undefined && this.registroTaller.get('lugaresReservaLunes2').value != undefined && this.registroTaller.get('reservaLunes2').value != '' && this.registroTaller.get('reservaLunes22').value != '' && this.registroTaller.get('lugaresReservaLunes2').value != '') {
						nuevoTaller.reserva.horarios.lunes += "/" + this.registroTaller.get('reservaLunes2').value + "-" + this.registroTaller.get('reservaLunes22').value;
						nuevoTaller.reserva.lugares.lunes += "/" + this.registroTaller.get('lugaresReservaLunes2').value;
					}
					if (this.registroTaller.get('reservaMartes2').value != undefined && this.registroTaller.get('reservaMartes22').value != undefined && this.registroTaller.get('lugaresReservaMartes2').value != undefined && this.registroTaller.get('reservaMartes2').value != '' && this.registroTaller.get('reservaMartes22').value != '' && this.registroTaller.get('lugaresReservaMartes2').value != '') {
						nuevoTaller.reserva.horarios.martes += "/" + this.registroTaller.get('reservaMartes2').value + "-" + this.registroTaller.get('reservaMartes22').value;
						nuevoTaller.reserva.lugares.martes += "/" + this.registroTaller.get('lugaresReservaMartes2').value;
					}
					if (this.registroTaller.get('reservaMiercoles2').value != undefined && this.registroTaller.get('reservaMiercoles22').value != undefined && this.registroTaller.get('lugaresReservaMiercoles2').value != undefined && this.registroTaller.get('reservaMiercoles2').value != '' && this.registroTaller.get('reservaMiercoles22').value != '' && this.registroTaller.get('lugaresReservaMiercoles2').value != '') {
						nuevoTaller.reserva.horarios.miercoles += "/" + this.registroTaller.get('reservaMiercoles2').value + "-" + this.registroTaller.get('reservaMiercoles22').value;
						nuevoTaller.reserva.lugares.miercoles += "/" + this.registroTaller.get('lugaresReservaMiercoles2').value;
					}
					if (this.registroTaller.get('reservaJueves2').value != undefined && this.registroTaller.get('reservaJueves22').value != undefined && this.registroTaller.get('lugaresReservaJueves2').value != undefined && this.registroTaller.get('reservaJueves2').value != '' && this.registroTaller.get('reservaJueves22').value != '' && this.registroTaller.get('lugaresReservaJueves2').value != '') {
						nuevoTaller.reserva.horarios.jueves += "/" + this.registroTaller.get('reservaJueves2').value + "-" + this.registroTaller.get('reservaJueves22').value;
						nuevoTaller.reserva.lugares.jueves += "/" + this.registroTaller.get('lugaresReservaJueves2').value;
					}
					if (this.registroTaller.get('reservaViernes2').value != undefined && this.registroTaller.get('reservaViernes22').value != undefined && this.registroTaller.get('lugaresReservaViernes2').value != undefined && this.registroTaller.get('reservaViernes2').value != '' && this.registroTaller.get('reservaViernes22').value != '' && this.registroTaller.get('lugaresReservaViernes2').value != '') {
						nuevoTaller.reserva.horarios.viernes += "/" + this.registroTaller.get('reservaViernes2').value + "-" + this.registroTaller.get('reservaViernes22').value;
						nuevoTaller.reserva.lugares.viernes += "/" + this.registroTaller.get('lugaresReservaViernes2').value;
					}
					if (this.registroTaller.get('reservaSabado2').value != undefined && this.registroTaller.get('reservaSabado22').value != undefined && this.registroTaller.get('lugaresReservaSabado2').value != undefined && this.registroTaller.get('reservaSabado2').value != '' && this.registroTaller.get('reservaSabado22').value != '' && this.registroTaller.get('lugaresReservaSabado2').value != '') {
						nuevoTaller.reserva.horarios.sabado += "/" + this.registroTaller.get('reservaSabado2').value + "-" + this.registroTaller.get('reservaSabado22').value;
						nuevoTaller.reserva.lugares.sabado += "/" + this.registroTaller.get('lugaresReservaSabado2').value;
					}
					if (this.tieneImagen) {
						var nodos = await this.servicios.updateTaller(nuevoTaller).then(resultado => {
							this.erroresTaller.push({severity:'success', detail: "Taller " + nuevoTaller.codigo + " modificado con éxito", summary:'Taller modificado'});
						}).catch(error => {
						});
					} else {
						console.log(this.tieneImagen);
						var nodos = await this.servicios.updateTaller(nuevoTaller, this.imagen).then(resultado => {
							this.erroresTaller.push({severity:'success', detail: "Taller " + nuevoTaller.codigo + " modificado con éxito", summary:'Taller modificado'});
						}).catch(error => {
              console.log(error);
						});
					}
				} else {
					this.erroresTaller.push({severity:'error', detail:'¡Seleccione las marcas que atiende el Taller!', summary:'Error'});
				}
			} else {
				this.erroresTaller.push({severity:'error', detail:'¡Seleccione los servicios que atiende el Taller!', summary:'Error'});
			}
		} else {
			this.erroresTaller.push({severity:'error', detail:'¡Rellene todos los campos requeridos!', summary:'Error'});
		}
	}

	public cambioActivo(){
		this.erroresTaller = [];
		this.servicios.updateActivoTaller(this.registroTaller.get('key').value, this.activo).then(resultado => {
			if (this.activo) {
				this.erroresTaller.push({severity:'success', detail: "Activaste el Taller", summary:'Taller activado'});
			} else {
				this.erroresTaller.push({severity:'success', detail: "Desactivaste el Taller", summary:'Taller desactivado'});
			}
		}).catch(error => {
			this.erroresTaller.push({severity:'error', detail: "Error de servidor", summary:'Error interno'});
		});
	}

	public getComunas(){
		this.servicios.getComunas(this.registroTaller.get('region').value).subscribe(comunas => {
			this.comunas = [];
			if (comunas[0] != undefined) {
				this.registroTaller.get('comuna').setValue(comunas[0].payload.val().toString().toUpperCase());
			}
			comunas.forEach(comunaNodo => {
				var comuna = comunaNodo.payload.val();
				this.comunas.push(comuna.toString().toUpperCase());
			});
		});
	}

	public getError(controlName: string): string {
		let error = '';
		const control = this.registroTaller.get(controlName);
		if (control.touched && control.errors != null) {
			error = JSON.stringify(control.errors);
		}
		if (error.indexOf('requiredPattern')>-1) {
			error = "Campo incorrecto"
		}
		if (error.indexOf('minlength')>-1) {
			error = "Contraseña débil";
		}
		if (error.indexOf('"required":true')>-1) {
			error = "Campo requerido";
		}
		if (error.indexOf('"email":true')>-1) {
			error = "Formato de dirección email incorrecto";
		}
		return error;
	}

	setImagen(event){
		//console.log(event.target.files[0].name);
		this.imagenUrl = event.target.files[0];


		var reader = new FileReader();
		this.imagePath = this.imagenUrl;
		reader.readAsDataURL(this.imagePath);
		reader.onload = (_event) => {

		  this.imagen = reader.result;
    }

    this.tieneImagen = true;
    console.log(this.codigoTaller);
    console.log(this.imagenUrl);

		this.servicios.uploadImageTaller(this.codigoTaller, this.imagenUrl);
	}


}
