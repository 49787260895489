export const environment = {
	production: false,
	firebaseConfig: {
		apiKey: "AIzaSyBEDFGyEV7pIrlhQpGorHMIqg59cpAfj9g",
		authDomain: "assistencecar-d95bb.firebaseapp.com",
		databaseURL: "https://assistencecar-d95bb.firebaseio.com",
		projectId: "assistencecar-d95bb",
		storageBucket: "assistencecar-d95bb.appspot.com",
		messagingSenderId: "1097999239271",
		appId: "1:1097999239271:web:95251bfddbed4f7f"
	}
};
