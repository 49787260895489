import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../core/services/firebase.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';
import { Title } from "@angular/platform-browser";

import { User } from 'firebase/app';
import { UserModel } from '../models/user-model';
import { RolesUser } from '../enumerados/roles-user-enum';

import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [MessageService]
})
export class LoginComponent implements OnInit {

	public erroresLogin:  Message[] = [];
	public loading = false;

	email: string;
	pass: string;

	constructor(private servicios: FirebaseService, private messageService: MessageService, private router: Router, private title: Title) {
		title.setTitle("Iniciar Sesión");
	}

	ngOnDestroy(){
		this.title.setTitle("FixBeep");
	}

	ngOnInit() {
		var captura_mensaje = sessionStorage.getItem("mensaje_login_correcto");
		if (captura_mensaje != null && captura_mensaje != undefined && captura_mensaje != '') {
			this.erroresLogin.push({severity:'success', detail: captura_mensaje});
			sessionStorage.clear();
		}
		captura_mensaje = sessionStorage.getItem("mensaje_login_error");
		if (captura_mensaje != null && captura_mensaje != undefined && captura_mensaje != '') {
			this.erroresLogin.push({severity:'error', detail: captura_mensaje});
			sessionStorage.clear();
		}
		// if (this.servicios.user != null || this.servicios.user != undefined) {
		// 	this.router.navigate(['']);
		// }
		/*else {
			var suscripcionVerificacion = this.servicios.verificarSesion().subscribe(user => {
				if (user != null) {
					this.servicios.verificarCuenta(user).then(resultado => {
						var suscripcion = resultado.valueChanges().subscribe(usuario => {
							if (usuario.rol == RolesUser.SUPER_ADMIN || usuario.rol == RolesUser.USER_ADMIN || usuario.rol == RolesUser.USER_PROVEEDOR) {
								this.servicios.user = new UserModel();
								this.servicios.user = usuario;
								this.servicios.user.uid = user.uid;
								if (usuario.rol == RolesUser.SUPER_ADMIN || usuario.rol == RolesUser.USER_ADMIN) {
									this.router.navigate([""]);
								}
							}
							suscripcion.unsubscribe();
						});
					}).catch( result => {
						this.servicios.logOut();
					});
				}
				suscripcionVerificacion.unsubscribe();
			});
		}*/
	}

	login(email, pass){
		this.loading = true;
		this.erroresLogin = [];
		if (email != undefined && email != '' && pass != undefined && pass != '') {
			var user = new UserModel();
			user.email = email;
			user.password = pass;
			this.servicios.login(user).then(resultado => {
				this.loading = false;
				var suscripcion = this.servicios.getCuenta(resultado.user.uid).subscribe((usuario: UserModel) => {
					if (usuario != undefined && ( usuario.rol == RolesUser.SUPER_ADMIN || usuario.rol == RolesUser.USER_ADMIN || usuario.rol == RolesUser.USER_PROVEEDOR || usuario.rol == RolesUser.USER_OPERATOR || usuario.rol == RolesUser.USER_VENDEDOR)) {
						this.servicios.user = new UserModel();
						this.servicios.user = usuario;
						this.servicios.user.uid = resultado.user.uid;
						this.router.navigate([""]);
					} else {
						this.erroresLogin.push({severity:'error', detail:'¡No tiene permisos suficientes para ingresar a la administración!', summary:'Error'});
					}
					suscripcion.unsubscribe();
				});
			}).catch(error => {
				this.loading = false;
				if(error.code=='auth/user-not-found') {
					this.erroresLogin.push({severity:'error', detail:'¡Email o contraseña incorrectos!', summary:'Error'});
				}else{
					if(error.code=='auth/wrong-password') {
						this.erroresLogin.push({severity:'error', detail:'¡Email o contraseña incorrectos!', summary:'Error'});
					}else{
						if(error.code=='auth/account-exists-with-different-credential') {
							this.erroresLogin.push({severity:'error', detail:'¡Su cuenta ya esta vinculada a un proveedor!', summary:'Error'});
						}else{
							if(error.code=='auth/network-request-failed') {
								this.erroresLogin.push({severity:'error', detail:'¡Verifiqué su conexión a internet!', summary:'Error'});
							}else{
								if(error.code=='auth/too-many-requests') {
									this.erroresLogin.push({severity:'error', detail:'¡Demasiados intentos de inicio de sesión, intentelo de nuevo mas tarde!', summary:'Error'});
								}else{
									this.erroresLogin.push({severity:'error', detail:'Ha ocurrido un error inesperado. Por favor intente nuevamente.', summary:'Error'});
								}
							}
						}
					}
				}
			});
		} else {
			this.loading = false;
			this.erroresLogin.push({severity:'error', detail:'Rellene todos los campos', summary:'Error'});
		}
	}
}
